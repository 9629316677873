import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import {
  ConfirmationDialog,
  DataFilterbox,
  DataFilterboxItem,
  DateSuggest,
  PageNavigation,
  Textarea,
  Textbox,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  Amounts,
  FullMethodName_ListBusinessUnitProfiles,
  PageState,
  ViewId,
  convertBaseDigitOnceTo,
  convertBaseDigitToOnce,
  convertIDateToDate,
  convertLongToString,
  convertMonetaryAmount,
  convertStringToLong,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getProgramOptionFilterboxData,
  getWorkerExceptionMessage,
  includeInputValidateError,
  convertDateToIDate,
  BASE_DIGIT_THOUSAND,
  getPathnameByViewId,
} from '~/shared/utils';
import { CommaTypeFormatter } from '~/shared/components/parts/formatter';
import {
  validateFreeDate,
  validateHalfAlphanumericAndPerfectSize,
  validateOnlyNumberWithComma,
} from '~/shared/components/parts/validator';
import { Amountbox } from '~/shared/components/ui/Amountbox';
import './OcpBusinessUnitProfileInput.css';

const VIEW_ID: ViewId = 'OCP_BUSINESS_UNIT_PROFILE_INPUT';
export const OcpBusinessUnitProfileInput = () => {
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);

  const intl = useIntl();
  const navi = useNavigate();
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const backPageUrl = useMemo(
    () => getPathnameByViewId(sourcePageInfo.current.sourceViewId)?.path ?? '/',
    [sourcePageInfo]
  );

  type TabName = 'profile';
  const tabs: TabName[] = ['profile'];
  const [tabPanel, setTabPanel] = useState<TabName>('profile');
  const isCurrentTab = (target: TabName) => {
    return tabPanel === target;
  };

  const [region] = useMemo<[DataFilterboxItem[]]>(() => {
    const region = getProgramOptionFilterboxData('A0000042');
    return [region];
  }, []);

  // ラベル
  const labelSave = GetMessageWithIntl(intl, {
    id: 'save',
  });

  // メッセージ
  const viewMessageBack: MessageProps = {
    id: 'confirmationDialogMessageBack',
    prefixId: VIEW_ID,
  };
  const viewMessageSave: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };

  const [isLoading, setLoading] = useState(false);
  const myCompanyInfo = useRef<mtechnavi.api.company.ICompany>();

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  const [businessUnitProfileData, setBusinessUnitProfileData] =
    useState<mtechnavi.api.company.IBusinessUnitProfile>();

  // 確認ダイアログ（戻る）
  const [isBackConfirmOpen, setBackConfirmOpen] = useState(false);
  // 確認ダイアログ（保存）
  const [isSaveConfirmOpen, setSaveConfirmOpen] = useState(false);

  const [companyName, setCompanyName] = useState('');
  const [companyNameEn, setCompanyNameEn] = useState('');
  const [companyLegalPropertiesCode, setCompanyLegalPropertiesCode] =
    useState('');
  const [companyNameKana, setCompanyNameKana] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [
    eligibleInvoiceAuthorizationNumber,
    setEligibleInvoiceAuthorizationNumber,
  ] = useState('');
  const [representative, setRepresentative] = useState('');
  const [representativeKana, setRepresentativeKana] = useState('');
  const [position, setPosition] = useState('');
  const [birthDateDt, setBirthDateDt] = useState<Date | null>(null);
  const [country, setCountry] = useState<sharelib.INameOption>();
  const [postalCode, setPostalCode] = useState('');
  const [regionCode, setRegionCode] = useState<DataFilterboxItem[]>([]);
  const [addressLine, setAddressLine] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [employees, setEmployees] = useState('');
  const [averageAge, setAverageAge] = useState<Amounts>();
  const [capitalStock, setCapitalStock] = useState<Amounts>();
  const [mainShareholder, setMainShareholder] = useState('');
  const [mainBank, setMainBank] = useState('');
  const [mainSupplier, setMainSupplier] = useState('');
  const [mainCustomer, setMainCustomer] = useState('');
  const [mainBusiness, setMainBusiness] = useState('');

  // 入力チェック
  const formAreaRef = useRef(null);

  const isInputError = (): boolean => {
    // 適格請求書認定番号チェック
    let isEligibleInvoiceNumberError = false;
    let isInputValidatorError = false;
    const isStartWithT = eligibleInvoiceAuthorizationNumber.startsWith('T');
    const is13NumberCompanyNumber =
      eligibleInvoiceAuthorizationNumber.slice(1) ===
      companyLegalPropertiesCode;
    if (
      eligibleInvoiceAuthorizationNumber &&
      (!isStartWithT || !is13NumberCompanyNumber)
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000151' })]);
      isEligibleInvoiceNumberError = true;
    }
    // インプットエリアのエラーチェック
    isInputValidatorError = includeInputValidateError(document, intl, [
      {
        value: companyName ?? '',
        ref: formAreaRef,
      },
    ]);
    if (isEligibleInvoiceNumberError || isInputValidatorError) {
      return true;
    } else {
      return false;
    }
  };

  const getBusinessUnitProfileList = async () => {
    return (await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitProfiles,
      filter: {},
      requestBody: {
        companyIds: [myCompanyInfo.current?.companyId as string],
      },
      sort: [], // 1件のみ取得想定のため不要
    })) as mtechnavi.api.company.ListBusinessUnitProfilesResponse;
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      await saveBusinessUnitProfile();
      setSaveConfirmOpen(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    backToPreviewPage();
  };

  const saveBusinessUnitProfile = async () => {
    const profile: mtechnavi.api.company.ICompanyProfile = {
      displayNameLang: {
        ja: companyName,
        en: companyNameEn,
        'ja-kana': companyNameKana,
      },
      phoneNumber: phoneNumber,
      websiteUrl: websiteUrl,
      companyLegalProperties: {
        companyNumber: companyLegalPropertiesCode,
        eligibleInvoiceAuthorizationNumber: eligibleInvoiceAuthorizationNumber,
      } as mtechnavi.api.company.ICompanyLegalProperties,
      representative: {
        displayNameLang: { ja: representative, 'ja-kana': representativeKana },
        position: position,
        birthDate: convertDateToIDate(birthDateDt),
      } as mtechnavi.api.company.CompanyProfile.IRepresentative,
      address: {
        country: country,
        region: regionCode[0]
          ? window.App.services.ui.getNameOptionWithCode(
              'A0000042',
              regionCode[0].value ?? ''
            )
          : null,
        postalCode: postalCode,
        addressLines: [addressLine],
      } as mtechnavi.api.company.IAddress,
      capitalStock: convertBaseDigitToOnce(
        convertMonetaryAmount(capitalStock),
        BASE_DIGIT_THOUSAND
      ),
      employees: {
        size: employees ? convertStringToLong(employees) : null,
        averageAge: averageAge,
      } as mtechnavi.api.company.CompanyProfile.IEmployees,
      mainShareholder: mainShareholder,
      mainBank: mainBank,
      mainSupplier: mainSupplier,
      mainCustomer: mainCustomer,
      mainBusiness: mainBusiness,
    };

    console.info('profile', profile);

    const request: mtechnavi.api.company.IBusinessUnitProfile = {
      ...businessUnitProfileData,
      companyId: myCompanyInfo.current?.companyId,
      profile: profile,
    };

    return window.App.services.ui.worker.apiCall({
      actionName: 'saveOcpProfile',
      request,
    }) as Promise<mtechnavi.api.company.IBusinessUnitProfile>;
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const ids = [] as string[];
    sourcePageInfo.current.ids?.map((v) => ids.push(v));
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: sourcePageInfo.current.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  const toMarkerDataFilterboxItems = (
    markers: sharelib.INameOption[]
  ): DataFilterboxItem[] => {
    return markers.map(toDataFilterboxItem);
  };

  const toDataFilterboxItem = (v: sharelib.INameOption): DataFilterboxItem => {
    return {
      displayName: v.displayNameLang?.ja ?? '',
      value: v.code ?? '',
    };
  };

  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // プリセット取得
        const { presetItem } = await window.App.services.ui.getViewIdPreset(
          VIEW_ID
        );
        setPreset(presetItem);
      })();

      (async () => {
        // 自社情報を取得
        myCompanyInfo.current =
          await window.App.services.companyService.getCompany({});

        const businessUnitProfileList = (await getBusinessUnitProfileList())
          .items as mtechnavi.api.company.IBusinessUnitProfile[];
        if (!businessUnitProfileList) {
          setLoading(false);
          return;
        }

        const profileData = businessUnitProfileList[0];

        if (profileData) {
          const data = profileData.profile;
          console.info(data);
          setCompanyName(data?.displayNameLang?.ja ?? '');
          setCompanyNameEn(data?.displayNameLang?.en ?? '');
          setCompanyNameKana(data?.displayNameLang!['ja-kana'] ?? '');
          setPhoneNumber(data?.phoneNumber ?? '');
          setWebsiteUrl(data?.websiteUrl ?? '');
          setCompanyLegalPropertiesCode(
            data?.companyLegalProperties?.companyNumber ?? ''
          );
          setEligibleInvoiceAuthorizationNumber(
            data?.companyLegalProperties?.eligibleInvoiceAuthorizationNumber ??
              ''
          );
          setRepresentative(data?.representative?.displayNameLang?.ja ?? '');
          setRepresentativeKana(
            data?.representative?.displayNameLang!['ja-kana'] ?? ''
          );
          setPosition(data?.representative?.position ?? '');
          setBirthDateDt(convertIDateToDate(data?.representative?.birthDate));
          setCountry(data?.address?.country ?? undefined);
          if (data?.address?.region) {
            setRegionCode(
              toMarkerDataFilterboxItems([
                data?.address?.region,
              ] as sharelib.INameOption[])
            );
          }
          setPostalCode(data?.address?.postalCode ?? '');
          setAddressLine(data?.address?.addressLines?.join('') ?? '');
          setCapitalStock(
            convertBaseDigitOnceTo(data?.capitalStock, BASE_DIGIT_THOUSAND)
          );
          setEmployees(convertLongToString(data?.employees?.size));
          setAverageAge(data?.employees?.averageAge);
          setMainShareholder(data?.mainShareholder ?? '');
          setMainBank(data?.mainBank ?? '');
          setMainSupplier(data?.mainSupplier ?? '');
          setMainCustomer(data?.mainCustomer ?? '');
          setMainBusiness(data?.mainBusiness ?? '');
        }
        setBusinessUnitProfileData(
          profileData as mtechnavi.api.company.IBusinessUnitProfile
        );
        setLoading(false);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
      setLoading(false);
    }

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const renderProfileComponent = () => {
    return (
      <>
        {/* 1段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-40">
              <Textbox
                name="companyName"
                className="companyName"
                type="text"
                disabled={false}
                value={companyName}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.companyName"
                columns={preset.columns}
                validateOption={{ required: true }}
                onChangeState={setCompanyName}
              />
            </div>
            <div className="w-40">
              <Textbox
                name="companyNameEn"
                className="companyNameEn"
                type="text"
                disabled={false}
                value={companyNameEn}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.companyNameEn"
                columns={preset.columns}
                onChangeState={setCompanyNameEn}
              />
            </div>
            <div className="w-20">
              <Textbox
                name="companyLegalPropertiesCode"
                className="companyLegalPropertiesCode"
                type="text"
                disabled={false}
                value={companyLegalPropertiesCode}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.companyLegalPropertiesCode"
                columns={preset.columns}
                onChangeState={setCompanyLegalPropertiesCode}
                validateOption={{ maxLength: 13 }}
                validator={validateHalfAlphanumericAndPerfectSize(intl, 13)}
              />
            </div>
          </div>
        </div>
        {/* 2段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-40">
              <Textbox
                name="companyNameKana"
                className="companyNameKana"
                type="text"
                disabled={false}
                value={companyNameKana}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.companyNameKana"
                columns={preset.columns}
                onChangeState={setCompanyNameKana}
              />
            </div>
            <div className="w-40">
              <Textbox
                name="websiteUrl"
                className="websiteUrl"
                type="text"
                disabled={false}
                value={websiteUrl}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.websiteUrl"
                columns={preset.columns}
                onChangeState={setWebsiteUrl}
              />
            </div>
            <div className="w-20">
              <Textbox
                name="eligibleInvoiceAuthorizationNumber"
                className="eligibleInvoiceAuthorizationNumber"
                type="text"
                disabled={false}
                value={eligibleInvoiceAuthorizationNumber}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.eligibleInvoiceAuthorizationNumber"
                columns={preset.columns}
                onChangeState={setEligibleInvoiceAuthorizationNumber}
                validateOption={{ maxLength: 14 }}
                validator={validateHalfAlphanumericAndPerfectSize(intl, 14)}
              />
            </div>
          </div>
        </div>
        {/* 3段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              <Textbox
                name="representative"
                className="representative"
                type="text"
                disabled={false}
                value={representative}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.representative"
                columns={preset.columns}
                onChangeState={setRepresentative}
              />
            </div>
            <div className="w-20">
              <Textbox
                name="representativeKana"
                className="representativeKana"
                type="text"
                disabled={false}
                value={representativeKana}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.representativeKana"
                columns={preset.columns}
                onChangeState={setRepresentativeKana}
              />
            </div>
            <div className="w-20">
              <Textbox
                name="position"
                className="position"
                type="text"
                disabled={false}
                value={position}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.position"
                columns={preset.columns}
                onChangeState={setPosition}
              />
            </div>
            <div className="w-20">
              <DateSuggest
                name="birthDateDt"
                validator={validateFreeDate(intl)}
                value={birthDateDt}
                validateOption={{ required: false }}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.birthDateDt"
                onChangeState={setBirthDateDt}
                columns={preset.columns}
              ></DateSuggest>
            </div>
          </div>
        </div>
        {/* 4段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              <Textbox
                name="postalCode"
                className="postalCode"
                type="text"
                disabled={false}
                value={postalCode}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.postalCode"
                columns={preset.columns}
                onChangeState={setPostalCode}
              />
            </div>
            <div className="w-20">
              <DataFilterbox
                data={region}
                name="regionCode"
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.region"
                columns={preset.columns}
                searchOption={{ targets: 'displayName' }}
                validateOption={{ required: false }}
                onChangeState={setRegionCode}
                value={regionCode}
              />
            </div>
            <div className="w-40">
              <Textbox
                name="addressLine"
                className="addressLine"
                type="text"
                disabled={false}
                value={addressLine}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.addressLine"
                columns={preset.columns}
                onChangeState={setAddressLine}
              />
            </div>
            <div className="w-20">
              <Textbox
                name="phoneNumber"
                className="phoneNumber"
                type="text"
                disabled={false}
                value={phoneNumber}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.phoneNumber"
                columns={preset.columns}
                onChangeState={setPhoneNumber}
              />
            </div>
          </div>
        </div>
        {/* 5段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              <Textbox
                name="employees"
                className="employees right"
                type="text"
                disabled={false}
                value={employees}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.employees"
                columns={preset.columns}
                onChangeState={setEmployees}
                validator={validateOnlyNumberWithComma(intl)}
                formatter={CommaTypeFormatter}
              />
            </div>
            <div className="w-20">
              <Amountbox
                name="averageAge"
                className="averageAge"
                value={averageAge}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.averageAge"
                columns={preset.columns}
                onChangeState={setAverageAge}
                displayOption={{ digits: 1 }}
              />
            </div>
            <div className="w-20">
              <Amountbox
                name="capitalStock"
                className="capitalStock"
                value={capitalStock}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.capitalStock"
                columns={preset.columns}
                onChangeState={setCapitalStock}
                displayOption={{ isCommaFormat: true }}
              />
            </div>
          </div>
        </div>
        {/* 6段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textbox
                name="mainShareholder"
                className="mainShareholder"
                type="text"
                disabled={false}
                value={mainShareholder}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.mainShareholder"
                columns={preset.columns}
                onChangeState={setMainShareholder}
              />
            </div>
          </div>
        </div>
        {/* 7段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textbox
                name="mainBank"
                className="mainBank"
                type="text"
                disabled={false}
                value={mainBank}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.mainBank"
                columns={preset.columns}
                onChangeState={setMainBank}
              />
            </div>
          </div>
        </div>
        {/* 8段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textbox
                name="mainSupplier"
                className="mainSupplier"
                type="text"
                disabled={false}
                value={mainSupplier}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.mainSupplier"
                columns={preset.columns}
                onChangeState={setMainSupplier}
              />
            </div>
          </div>
        </div>
        {/* 9段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textbox
                name="mainCustomer"
                className="mainCustomer"
                type="text"
                disabled={false}
                value={mainCustomer}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.mainCustomer"
                columns={preset.columns}
                onChangeState={setMainCustomer}
              />
            </div>
          </div>
        </div>
        {/* 10段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-80">
              <Textarea
                name="mainBusiness"
                className="w-100 mh-middle"
                disabled={false}
                value={mainBusiness}
                labelId="OCP_BUSINESS_UNIT_PROFILE_INPUT.mainBusiness"
                columns={preset.columns}
                onChangeState={setMainBusiness}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="OcpBusinessUnitProfileInput">
          <div className="header">
            <PageNavigation
              backpagePath="/opc-businessunit-profile-confirmation"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute:
                    businessUnitProfileData?.updatedProperties?.updatedBy
                      ?.email ?? '',
                  attributeAt:
                    businessUnitProfileData?.updatedProperties?.updatedAt ??
                    undefined,
                  content:
                    businessUnitProfileData?.updatedProperties?.updatedBy
                      ?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => {
                setBackConfirmOpen(true);
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div className="input">
              <div className="basic-info-body">
                <div className="tabPanelContents">
                  {tabs.map((v, index) => {
                    const selectPane = v === tabPanel ? 'current' : '';
                    return (
                      <div
                        key={`tab-${index}`}
                        className={`tabPanel ${selectPane}`}
                        onClick={() => setTabPanel(v)}
                      >
                        {GetMessageWithIntl(intl, { prefixId: VIEW_ID, id: v })}
                      </div>
                    );
                  })}
                </div>
                {isCurrentTab('profile') && renderProfileComponent()}
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="saveButton"
                  buttonType="basic"
                  caption={labelSave}
                  onClick={() => {
                    if (isInputError()) {
                      return;
                    }
                    setSaveConfirmOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* 戻る 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isBackConfirmOpen}
        viewMessage={viewMessageBack}
        onDecision={() => {
          backToPreviewPage();
        }}
        onCancel={() => {
          setBackConfirmOpen(false);
        }}
      />
      {/* 保存 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSaveConfirmOpen}
        viewMessage={viewMessageSave}
        onDecision={() => {
          handleSave();
        }}
        onCancel={() => {
          setSaveConfirmOpen(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
};
