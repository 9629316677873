import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { PresetItem } from '~/shared/services';
import {
  Container,
  Toast,
  LoadingIcon,
  error,
  GetMessageWithIntl,
  success,
  GetMessage,
} from '~/shared/components';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  PageNavigation,
  NavigationIconMenu,
  LatestUpdateInfo,
} from '~/shared/components/ui/PageNavigation/PageNavigation';

import {
  PageState,
  getViewIdPreset,
  getWorkerExceptionMessage,
  ViewId,
  getMaxMainContentsHeight,
  formatIDateToString,
  FullMethodName_ListBusinessUnitBranchs,
  getExceptionMessage,
  FullMethodName_ListBusinessUnitFinancials,
  FullMethodName_ListBusinessUnitFacilitiess,
  FullMethodName_ListBusinessUnitCertificates,
  FullMethodName_ListBusinessUnitStrengths,
  getDateFormatWithTimezone,
  mergeAmountNumberWithRounding,
  joinAddress,
  convertLongToDate,
  convertBaseDigitOnceTo,
  BASE_DIGIT_THOUSAND,
  toCommaTypeString,
  FullMethodName_ListBusinessUnitSkills,
  convertSkillTreeToCategorizedList,
  convertLongToString,
  StringDateToNumber,
  convertUserReference,
} from '~/shared/utils';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { Textbox } from '~/shared/components/ui/Textbox';
import {
  BusinessUnitBranchDialog,
  BusinessUnitBranchFormOption,
  BusinessUnitStrengthDialog,
  BusinessUnitStrengthFormOption,
  SimpleIconListView,
  Textarea,
} from '~/shared/components/ui';
import { Amountbox } from '~/shared/components/ui/Amountbox';
import { LinkTextbox } from '~/shared/components/ui/LinkTextbox';
import { FilterRequest, SystemNotificationSetting } from '~/worker';
import './OcpBusinessUnitProfileConfirmation.css';
import {
  BusinessUnitFacilityDialog,
  BusinessUnitFacilityFormOption,
} from '~/shared/components/ui/Dialog/BusinessUnitFacilityDialog';
import {
  CertificateRegistrationDialog,
  CertificateRegistrationDialogInputOption,
} from '~/shared/components/ui/Dialog/CertificateRegistrationDialog';
import {
  CategorizedCheckList,
  CategorizedList,
} from '~/shared/components/ui/CategorizedCheckList';
import { useAuth } from '~/shared/contexts/AuthProvider';

import { SystemNotificationSettingDialog } from '~/shared/components/ui/Dialog';

const VIEW_ID: ViewId = 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION';

export function OcpBusinessUnitProfileConfirmation() {
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const navi = useNavigate();
  const intl = useIntl();
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];
  // メッセージ
  const successMessage = GetMessage({ id: 'I0000001' });

  const getTargetTabItem = (): TabName | null => {
    const urlPathName = window.location.pathname;
    const splitPath = urlPathName.split('/');
    if (splitPath.length > 2) {
      return splitPath[splitPath.length - 1] as TabName;
    }
    return null;
  };
  interface ViewFinancialType {
    businessUnitFinancialId: string; // Key
    period: string; // periodStartDt-periodEndDt YYYY/MM-YYYY/MM
    netSales: string; // netSales / 1000 切り捨て #,###
    operatingProfit: string; // operatingProfit / 1000 切り捨て #,###
    ordinaryIncome: string; // ordinaryIncome / 1000 切り捨て #,###
    notes: string; // nonDisclosure == true, 非開示 それ以外は空白
  }

  interface ViewBranchType {
    businessUnitBranchId: string; // Key
    displayName: string; // displayName
    address: string; // address joinAddress
    phoneNumber: string; // phoneNumber
    overview: string; // overview
  }

  interface ViewFacilityType {
    businessUnitFacilitiesId: string; // Key
    branchName: string; // 拠点名
    manufacture: string; //メーカー
    modelNumber: string; // 型式
    assetsAmount: string; // 台数
  }

  interface ViewStrengthType {
    businessUnitStrengthId: string; // Key
    category: string; // 分類
    content: string; //メーカー
    linkProperties: string; // 関連拠点/関連設備
  }

  interface ViewCertificateType {
    businessUnitCertificateId: string; // Key
    displayName: string; // 認証名
    obtained: string; // 取得状況
    obtainedDt: string; //取得年月日
    expirationDt: string; // 有効期限
    scope: string; // 適用範囲
  }

  type sortCategory = 'obtainedDt' | 'expirationDt' | 'certificationName';

  // ローディング制御
  const [isLoading, setLoading] = useState(false);
  const [isLoadingProfile, setLoadingProfile] = useState(false);
  const [isLoadingBranch, setLoadingBranch] = useState(false);
  const [isLoadingFinancial, setLoadingFinancial] = useState(false);
  const [isLoadingFacilities, setLoadingFacilities] = useState(false);
  const [isLoadingCertificate, setLoadingCertificate] = useState(false);
  const [isLoadingStrength, setLoadingStrength] = useState(false);
  const [isLoadingSkill, setLoadingSkill] = useState(false);

  // 自社企業情報
  const myCompanyInfo = useRef<mtechnavi.api.company.ICompany>();

  // 取得データ格納用 基本情報
  const [itemProfile, setItemProfile] =
    useState<mtechnavi.api.company.IBusinessUnitProfile>();
  // 取得データ格納用 自社企業（拠点）マスタ
  const businessUnitBranchsData = useRef<
    mtechnavi.api.company.IBusinessUnitBranch[]
  >([]);
  // 取得データ格納用 自社企業（決算）マスタ
  const businessUnitFinancialsData = useRef<
    mtechnavi.api.company.IBusinessUnitFinancial[]
  >([]);
  // 取得データ格納用 自社企業（設備）マスタ
  const businessUnitFacilitiessData = useRef<
    mtechnavi.api.company.IBusinessUnitFacilities[]
  >([]);
  // 取得データ格納用 自社企業（認証）マスタ
  const businessUnitCertificatesData = useRef<
    mtechnavi.api.company.IBusinessUnitCertificate[]
  >([]);
  // 取得データ格納用 自社企業（得意分野）マスタ
  const businessUnitStrengthsData = useRef<
    mtechnavi.api.company.IBusinessUnitStrength[]
  >([]);
  // 取得データ格納用 自社企業（技術情報）マスタ
  const businessUnitSkillsData = useRef<
    mtechnavi.api.company.IBusinessUnitSkill[]
  >([]);

  // 拠点タブ
  const [branchItems, setBranchItems] = useState<ViewBranchType[]>([]);
  const [branchFormItems, setBranchFormItems] =
    useState<BusinessUnitBranchFormOption>();
  // 決算タブ
  const [financialItems, setFinancialItems] = useState<ViewFinancialType[]>([]);
  // 設備タブ
  const [facilityItems, setFacilityItems] = useState<ViewFacilityType[]>([]);
  const [facilityFormItems, setFacilityFormItems] =
    useState<BusinessUnitFacilityFormOption>();
  // 得意分野タブ
  const [strengthItems, setStrengthItems] = useState<ViewStrengthType[]>([]);
  const [strengthFormItems, setStrengthFormItems] =
    useState<BusinessUnitStrengthFormOption>();
  // 認証タブ
  const [certificateItems, setCertificateItems] = useState<
    ViewCertificateType[]
  >([]);
  const [certificateFormItems, setCertificateFormItems] =
    useState<CertificateRegistrationDialogInputOption>();
  // 技術情報タブ
  const [skillTreeData, setSkillTreeData] = useState<CategorizedList>([]);
  const [businessUnitSkillData, setBusinessUnitSkillData] =
    useState<mtechnavi.api.company.IBusinessUnitSkill>();

  // DIALOG
  const [isBranchDialogOpen, setBranchDialogOpen] = useState(false);
  const [isFacilityDialogOpen, setFacilityDialogOpen] = useState(false);
  const [isStrengthDialogOpen, setStrengthDialogOpen] = useState(false);
  const [isCertificateDialogOpen, setCertificateDialogOpen] = useState(false);

  // システム通知設定ダイアログ
  const [isOpenSystemNotificationDialog, setOpenSystemNotificationDialog] =
    useState(false);
  const [systemNotificationUsers, setSystemNotificationUsers] = useState<
    sharelib.IUserReference[]
  >([]);
  const [isSystemNotificationEditable, setSystemNotificationEditable] =
    useState<boolean>(true);

  type TabName =
    | 'profile'
    | 'branch'
    | 'financial'
    | 'facilities'
    | 'certificate'
    | 'strength'
    | 'skill';
  const tabs: TabName[] = [
    'profile',
    'branch',
    'facilities',
    'skill',
    'strength',
    'certificate',
    'financial',
  ];
  const [tabPanel, setTabPanel] = useState<TabName>(() => {
    const targetTabItem = getTargetTabItem();
    return targetTabItem ? targetTabItem : 'profile';
  });
  const isCurrentTab = (target: TabName) => {
    return tabPanel === target;
  };

  useEffect(() => {
    setTabPanel(
      location.baseViewOption?.sourceTab
        ? (location.baseViewOption?.sourceTab as TabName)
        : getTargetTabItem() ?? 'profile'
    );
    // 初期表示のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.baseViewOption, intl]);

  //PageNavigationInfo
  const [latestUpdateInfo, setLatestUpdateInfo] = useState<LatestUpdateInfo>({
    isVisibleUpdateInfo: true,
  });

  // 最終更新者情報取得
  useEffect(() => {
    switch (tabPanel) {
      case 'profile':
        setLatestUpdateInfo({
          isVisibleUpdateInfo: true,
          attribute: itemProfile?.updatedProperties?.updatedBy?.email ?? '',
          attributeAt: itemProfile?.updatedProperties?.updatedAt ?? undefined,
          content: itemProfile?.updatedProperties?.updatedBy?.displayName ?? '',
        });
        break;
      case 'branch': {
        const newestBranch =
          businessUnitBranchsData.current.length > 0
            ? businessUnitBranchsData.current.sort(
                (val1, val2) =>
                  (convertLongToDate(
                    val2.updatedProperties?.updatedAt
                  )?.getDate() ?? 0) -
                  (convertLongToDate(
                    val1.updatedProperties?.updatedAt
                  )?.getDate() ?? 0)
              )[0]
            : null;
        setLatestUpdateInfo({
          isVisibleUpdateInfo: true,
          attribute: newestBranch?.updatedProperties?.updatedBy?.email ?? '',
          attributeAt: newestBranch?.updatedProperties?.updatedAt ?? undefined,
          content:
            newestBranch?.updatedProperties?.updatedBy?.displayName ?? '',
        });
        break;
      }
      case 'financial': {
        const newestFinancial =
          businessUnitFinancialsData.current.length > 0
            ? businessUnitFinancialsData.current.sort(
                (val1, val2) =>
                  (convertLongToDate(
                    val2.updatedProperties?.updatedAt
                  )?.getDate() ?? 0) -
                  (convertLongToDate(
                    val1.updatedProperties?.updatedAt
                  )?.getDate() ?? 0)
              )[0]
            : null;
        setLatestUpdateInfo({
          isVisibleUpdateInfo: true,
          attribute: newestFinancial?.updatedProperties?.updatedBy?.email ?? '',
          attributeAt:
            newestFinancial?.updatedProperties?.updatedAt ?? undefined,
          content:
            newestFinancial?.updatedProperties?.updatedBy?.displayName ?? '',
        });
        break;
      }

      case 'facilities': {
        const newestFacility =
          businessUnitFacilitiessData.current.length > 0
            ? businessUnitFacilitiessData.current.sort(
                (val1, val2) =>
                  (convertLongToDate(
                    val2.updatedProperties?.updatedAt
                  )?.getDate() ?? 0) -
                  (convertLongToDate(
                    val1.updatedProperties?.updatedAt
                  )?.getDate() ?? 0)
              )[0]
            : null;
        setLatestUpdateInfo({
          isVisibleUpdateInfo: true,
          attribute: newestFacility?.updatedProperties?.updatedBy?.email ?? '',
          attributeAt:
            newestFacility?.updatedProperties?.updatedAt ?? undefined,
          content:
            newestFacility?.updatedProperties?.updatedBy?.displayName ?? '',
        });
        break;
      }
      case 'certificate': {
        const newestCertificate =
          businessUnitCertificatesData.current.length > 0
            ? businessUnitCertificatesData.current.sort(
                (val1, val2) =>
                  (convertLongToDate(
                    val2.updatedProperties?.updatedAt
                  )?.getDate() ?? 0) -
                  (convertLongToDate(
                    val1.updatedProperties?.updatedAt
                  )?.getDate() ?? 0)
              )[0]
            : null;
        setLatestUpdateInfo({
          isVisibleUpdateInfo: true,
          attribute:
            newestCertificate?.updatedProperties?.updatedBy?.email ?? '',
          attributeAt:
            newestCertificate?.updatedProperties?.updatedAt ?? undefined,
          content:
            newestCertificate?.updatedProperties?.updatedBy?.displayName ?? '',
        });
        break;
      }
      case 'strength': {
        const newestStrength =
          businessUnitStrengthsData.current.length > 0
            ? businessUnitStrengthsData.current.sort(
                (val1, val2) =>
                  (convertLongToDate(
                    val2.updatedProperties?.updatedAt
                  )?.getDate() ?? 0) -
                  (convertLongToDate(
                    val1.updatedProperties?.updatedAt
                  )?.getDate() ?? 0)
              )[0]
            : null;
        setLatestUpdateInfo({
          isVisibleUpdateInfo: true,
          attribute: newestStrength?.updatedProperties?.updatedBy?.email ?? '',
          attributeAt:
            newestStrength?.updatedProperties?.updatedAt ?? undefined,
          content:
            newestStrength?.updatedProperties?.updatedBy?.displayName ?? '',
        });
        break;
      }
      case 'skill': {
        const newestSkill =
          businessUnitSkillsData.current.length > 0
            ? businessUnitSkillsData.current.sort(
                (val1, val2) =>
                  (convertLongToDate(
                    val2.updatedProperties?.updatedAt
                  )?.getDate() ?? 0) -
                  (convertLongToDate(
                    val1.updatedProperties?.updatedAt
                  )?.getDate() ?? 0)
              )[0]
            : null;
        setLatestUpdateInfo({
          isVisibleUpdateInfo: true,
          attribute: newestSkill?.updatedProperties?.updatedBy?.email ?? '',
          attributeAt: newestSkill?.updatedProperties?.updatedAt ?? undefined,
          content: newestSkill?.updatedProperties?.updatedBy?.displayName ?? '',
        });
        break;
      }
    }

    // タブ切り替え/関連データ変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tabPanel,
    location.baseViewOption,
    itemProfile,
    businessUnitBranchsData.current,
    businessUnitCertificatesData.current,
    businessUnitFinancialsData.current,
    businessUnitSkillsData.current,
    businessUnitStrengthsData.current,
    businessUnitFacilitiessData.current,
  ]);

  const getBusinessUnitProfile = async () => {
    const businessUnitProfileList: mtechnavi.api.company.IListBusinessUnitProfilesResponse =
      await window.App.services.businessUnitManagementService.listBusinessUnitProfiles(
        {
          companyIds: [myCompanyInfo.current?.companyId ?? ''],
        }
      );
    if (businessUnitProfileList.items?.length) {
      setItemProfile(
        businessUnitProfileList
          .items[0] as mtechnavi.api.company.IBusinessUnitProfile
      );
    }
  };

  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      setLoadingProfile(true);
      try {
        myCompanyInfo.current =
          await window.App.services.companyService.getCompany({});

        const { presetItem } = await getViewIdPreset(intl, VIEW_ID);
        setPreset(presetItem);
        await getBusinessUnitProfile();
        const skillTree =
          await window.App.services.businessUnitManagementService.getBusinessUnitSkillTree(
            {}
          );
        setSkillTreeData(convertSkillTreeToCategorizedList(skillTree));
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoadingProfile(false);
      }
    })();

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // メインデータ変更時
  useEffect(() => {
    // システム通知先のDisable制御
    setSystemNotificationEditable(false);

    if (!itemProfile) {
      setSystemNotificationEditable(false);
      return;
    }
    // 対象データ取得
    (async () => {
      await listAllData();
    })();

    // システム通知先データを取得
    setSystemNotificationEditable(true);
    setSystemNotificationUsers(itemProfile?.systemNotificationUsers ?? []);

    // item変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemProfile]);

  // Loading制御
  useEffect(() => {
    // ひとつでもLoadingならアイコン表示
    setLoading(
      isLoadingProfile ||
        isLoadingBranch ||
        isLoadingFinancial ||
        isLoadingFacilities ||
        isLoadingCertificate ||
        isLoadingStrength ||
        isLoadingSkill
    );

    // isLodingXX 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingProfile,
    isLoadingBranch,
    isLoadingFinancial,
    isLoadingFacilities,
    isLoadingCertificate,
    isLoadingStrength,
    isLoadingSkill,
  ]);

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const getPageNavigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];
    // 編集
    iconItems.push({
      name: 'edit',
      displayName: '編集',
      func: () => {
        switch (tabPanel) {
          case 'profile':
            return handleOcpBusinessUnitProfileInput();
          case 'branch':
            return handleOcpBusinessUnitBranchInput();
          case 'financial':
            return handleOcpBusinessUnitFinancialInput();
          case 'facilities':
            return handleOcpBusinessUnitFacilitiesInput();
          case 'certificate':
            return handleOcpBusinessUnitCertificateInput();
          case 'strength':
            return handleOcpBusinessUnitStrengthInput();
          case 'skill':
            return handleOcpBusinessUnitSkillInput();
          default:
        }
      },
    });
    if (isSystemNotificationEditable) {
      iconItems.push({
        name: 'recipients',
        displayName: '通知設定',
        func: () => {
          setOpenSystemNotificationDialog(true);
        },
      });
    }
    return iconItems;
  };

  const sortCertificateItems = (sortCategory: sortCategory, desc: boolean) => {
    if (sortCategory === 'expirationDt') {
      if (desc) {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val2.expirationDt) -
            StringDateToNumber(val1.expirationDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      } else {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val1.expirationDt) -
            StringDateToNumber(val2.expirationDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      }
      return;
    }
    if (sortCategory === 'obtainedDt') {
      if (desc) {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val2.obtainedDt) -
            StringDateToNumber(val1.obtainedDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      } else {
        const sortData = certificateItems;
        const sortedData = sortData.sort(
          (val1, val2) =>
            StringDateToNumber(val1.obtainedDt) -
            StringDateToNumber(val2.obtainedDt)
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      }
      return;
    }
    if (sortCategory === 'certificationName') {
      if (desc) {
        const sortData = certificateItems;
        const sortedData = sortData.sort((val1, val2) =>
          val2.displayName.localeCompare(val1.displayName, 'ja')
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      } else {
        const sortData = certificateItems;
        const sortedData = sortData.sort((val1, val2) =>
          val1.displayName.localeCompare(val2.displayName, 'ja')
        );
        setCertificateItems(
          sortedData.map(
            (v): ViewCertificateType => ({
              businessUnitCertificateId: v.businessUnitCertificateId,
              displayName: v.displayName,
              obtained: v.obtained,
              obtainedDt: v.obtainedDt,
              expirationDt: v.expirationDt,
              scope: v.scope,
            })
          )
        );
      }
    }
  };

  // [データ取得] 全データ取得
  const listAllData = async () => {
    // 拠点情報取得
    (async () => {
      setLoadingBranch(true);
      await listBusinessUnitBranchsData();
    })();

    // 決算情報取得
    (async () => {
      setLoadingFinancial(true);
      await listBusinessUnitFinancialsData();
    })();

    // 設備情報取得
    (async () => {
      setLoadingFacilities(true);
      await listBusinessUnitFacilitiessData();
    })();

    // 認証情報取得
    (async () => {
      setLoadingCertificate(true);
      await listBusinessUnitCertificatesData();
    })();

    // 得意分野情報取得
    (async () => {
      setLoadingStrength(true);
      await listBusinessUnitStrengthsData();
    })();

    // 技術情報情報取得
    (async () => {
      setLoadingSkill(true);
      await listBusinessUnitSkillsData();
    })();
  };

  // [データ取得] 自社企業（拠点）マスタ
  const listBusinessUnitBranchsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitBranchs,
        filter: {},
        requestBody: {
          companyIds: [myCompanyInfo.current?.companyId],
        },
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.company.IListBusinessUnitBranchsResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitBranch[] =
        resList.items ?? [];
      businessUnitBranchsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingBranch(false);
    }
  };

  // [データ取得]自社企業（決算）マスタ
  const listBusinessUnitFinancialsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitFinancials,
        filter: {},
        requestBody: {
          companyIds: [myCompanyInfo.current?.companyId],
        },
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.company.IListBusinessUnitFinancialsResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitFinancial[] =
        resList.items ?? [];
      businessUnitFinancialsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingFinancial(false);
    }
  };

  // [データ取得] 自社企業（設備）マスタ
  const listBusinessUnitFacilitiessData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitFacilitiess,
        filter: {},
        requestBody: {
          companyIds: [myCompanyInfo.current?.companyId],
        },
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.company.IListBusinessUnitFacilitiessResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitFacilities[] =
        resList.items ?? [];
      businessUnitFacilitiessData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingFacilities(false);
    }
  };

  // [データ取得] 自社企業（認証）マスタ
  const listBusinessUnitCertificatesData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitCertificates,
        filter: {},
        requestBody: {
          companyIds: [myCompanyInfo.current?.companyId],
        },
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.company.IListBusinessUnitCertificatesResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitCertificate[] =
        resList.items ?? [];
      businessUnitCertificatesData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingCertificate(false);
    }
  };

  // [データ取得] 自社企業（得意分野）マスタ
  const listBusinessUnitStrengthsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitStrengths,
        filter: {},
        requestBody: {
          companyIds: [myCompanyInfo.current?.companyId],
        },
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.company.IListBusinessUnitStrengthsResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitStrength[] =
        resList.items ?? [];
      businessUnitStrengthsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingStrength(false);
    }
  };

  // [データ取得] 自社企業（技術情報）マスタ
  const listBusinessUnitSkillsData = async () => {
    try {
      const listOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitSkills,
        filter: {},
        requestBody: {
          companyIds: [myCompanyInfo.current?.companyId],
        },
        sort: [], // ソートは画面項目セット時に行うため不要
      };
      const resList = (await window.App.services.ui.worker.filter(
        listOption
      )) as mtechnavi.api.company.IListBusinessUnitSkillsResponse;

      const listItem: mtechnavi.api.company.IBusinessUnitSkill[] =
        resList.items ?? [];
      businessUnitSkillsData.current = listItem;
    } catch (err) {
      error(getExceptionMessage(intl, err));
    } finally {
      setLoadingSkill(false);
    }
  };

  // 拠点情報変更時
  useEffect(() => {
    if (!businessUnitBranchsData.current) {
      setBranchItems([]);
      return;
    }
    const vals: ViewBranchType[] = [];
    businessUnitBranchsData.current.map((v) =>
      vals.push({
        businessUnitBranchId: v.businessUnitBranchId ?? '',
        displayName: v.displayName ?? '',
        address: joinAddress(v.address ?? {}),
        phoneNumber: v.phoneNumber ?? '',
        overview: v.overview ?? '',
      })
    );
    vals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setBranchItems(vals);

    // businessUnitBranchsData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitBranchsData.current]);

  // 設備情報変更時
  useEffect(() => {
    if (!businessUnitFacilitiessData.current) {
      setFacilityItems([]);
      return;
    }
    const vals: ViewFacilityType[] = [];
    businessUnitFacilitiessData.current.map((v) => {
      vals.push({
        businessUnitFacilitiesId: v.businessUnitFacilitiesId ?? '',
        branchName:
          businessUnitBranchsData.current.find(
            (branch) => branch.businessUnitBranchId === v.businessUnitBranchId
          )?.displayName ?? '',
        manufacture: v.manufacture ?? '',
        modelNumber: v.modelNumber ?? '',
        assetsAmount: v.assetsAmount ? v.assetsAmount.low.toString() : '',
      });
    });
    vals.sort((val1, val2) =>
      val1.branchName.localeCompare(val2.branchName, 'ja')
    );
    setFacilityItems(vals);

    // businessUnitFacilitiessData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitFacilitiessData.current, businessUnitBranchsData.current]);

  // 得意分野情報変更時
  useEffect(() => {
    if (!businessUnitStrengthsData.current) {
      setStrengthItems([]);
      return;
    }
    const vals: ViewStrengthType[] = [];
    businessUnitStrengthsData.current.map((v) => {
      const branchName =
        businessUnitBranchsData.current.find(
          (branch) =>
            branch.businessUnitBranchId ===
            v.linkProperties?.businessUnitBranchId
        )?.displayName ?? '';
      const modelNumber =
        businessUnitFacilitiessData.current.find(
          (facility) =>
            facility.businessUnitFacilitiesId ===
            v.linkProperties?.businessUnitFacilitiesId
        )?.modelNumber ?? '';
      vals.push({
        businessUnitStrengthId: v.businessUnitStrengthId ?? '',
        category: v.category?.displayNameLang?.ja ?? '',
        content: v.content ?? '',
        linkProperties:
          branchName || modelNumber ? [branchName, modelNumber].join('/') : '',
      });
    });
    vals.sort((val1, val2) => val1.category.localeCompare(val2.category, 'ja'));
    setStrengthItems(vals);

    // businessUnitStrengthsData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    businessUnitStrengthsData.current,
    businessUnitFacilitiessData.current,
    businessUnitBranchsData.current,
  ]);

  // 認証情報変更時
  useEffect(() => {
    if (!businessUnitCertificatesData.current) {
      setCertificateItems([]);
      return;
    }
    const vals: ViewCertificateType[] = [];
    businessUnitCertificatesData.current.map((v) => {
      vals.push({
        businessUnitCertificateId: v.businessUnitCertificateId ?? '',
        displayName: v.displayName
          ? v.displayName
          : v.certificate?.displayNameLang?.ja ?? '',
        obtained: v.obtained ? '○' : '-',
        obtainedDt: v.obtainedDt ? getDateFormatWithTimezone(v.obtainedDt) : '',
        expirationDt: v.expirationDt
          ? getDateFormatWithTimezone(v.expirationDt)
          : '',
        scope:
          (v.scopeProduct ? v.scopeProduct + ',' : '') +
          (v.scopeBranch ? v.scopeBranch : ''),
      });
    });
    // 初期ソート（有効期限、降順）
    vals.sort(
      (val1, val2) =>
        StringDateToNumber(val2.expirationDt) -
        StringDateToNumber(val1.expirationDt)
    );
    setCertificateItems(vals);

    // businessUnitCertificatesData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitCertificatesData.current]);

  // 決算情報変更時
  useEffect(() => {
    if (!businessUnitFinancialsData.current) {
      setFinancialItems([]);
      return;
    }
    const vals: ViewFinancialType[] = [];
    const sortData = businessUnitFinancialsData.current;
    // 開始年月日で降順
    sortData.sort(
      (val1, val2) =>
        new Date(getDateFormatWithTimezone(val2.periodStartDt)).getTime() -
        new Date(getDateFormatWithTimezone(val1.periodStartDt)).getTime()
    );
    sortData.map((v) =>
      vals.push({
        businessUnitFinancialId: v.businessUnitFinancialId ?? '',
        period: [
          getDateFormatWithTimezone(v.periodStartDt),
          getDateFormatWithTimezone(v.periodEndDt),
        ].join('-'),
        netSales: mergeAmountNumberWithRounding(
          convertBaseDigitOnceTo(v.netSales, BASE_DIGIT_THOUSAND),
          0,
          {
            rounding: 'RoundDown',
            digits: 0,
          }
        ),
        operatingProfit: mergeAmountNumberWithRounding(
          convertBaseDigitOnceTo(v.operatingProfit, BASE_DIGIT_THOUSAND),
          0,
          {
            rounding: 'RoundDown',
            digits: 0,
          }
        ),
        ordinaryIncome: mergeAmountNumberWithRounding(
          convertBaseDigitOnceTo(v.ordinaryIncome, BASE_DIGIT_THOUSAND),
          0,
          {
            rounding: 'RoundDown',
            digits: 0,
          }
        ),
        notes: v.nondisclosure
          ? GetMessageWithIntl(intl, {
              id: 'financial.notes.nonDisclosure',
              prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
            })
          : '',
      })
    );
    setFinancialItems(vals);

    // businessUnitFinancialsData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitFinancialsData.current]);

  // 技術情報変更時
  useEffect(() => {
    if (!businessUnitSkillsData.current) {
      setBusinessUnitSkillData({});
      return;
    }
    setBusinessUnitSkillData(businessUnitSkillsData.current.at(0));
  }, [businessUnitSkillsData.current]);

  // [実行処理]システム通知設定
  const handleSystemNotificationSetting = async (result: string) => {
    setLoading(true);
    try {
      const userReference = await convertUserReference(myEmail);
      const req: SystemNotificationSetting = {
        recordId: itemProfile?.businessUnitProfileId ?? '',
        appendUser:
          result === 'setAsNotificationDestination' ? userReference : null,
        removeUser:
          result !== 'setAsNotificationDestination' ? userReference : null,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateOcpProfileSystemNotificationSetting',
        request: req,
      });
      success([successMessage]);
      await getBusinessUnitProfile();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoading(false);
    }
  };

  // DIAlOG
  // [実行処理]拠点情報確認ダイアログ
  const handleBranchDialog = (v: ViewBranchType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitBranch =
      businessUnitBranchsData.current.find(
        (w) => w.businessUnitBranchId === v.businessUnitBranchId
      ) ?? {};
    if (formItem) {
      setBranchFormItems({
        displayName: formItem.displayName ?? '',
        address: formItem.address ?? {},
        phoneNumber: formItem.phoneNumber ?? '',
        faxNumber: formItem.faxNumber ?? '',
        overview: formItem.overview ?? '',
      });
    }
    setBranchDialogOpen(true);
  };

  // [実行処理]設備情報確認ダイアログ
  const handleFacilitiesDialog = (v: ViewFacilityType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitFacilities =
      businessUnitFacilitiessData.current.find(
        (w) => w.businessUnitFacilitiesId === v.businessUnitFacilitiesId
      ) ?? {};
    if (formItem) {
      setFacilityFormItems({
        buFacilities: formItem as mtechnavi.api.company.IBusinessUnitFacilities,
        companyId: formItem.companyId ?? '',
      });
    }
    setFacilityDialogOpen(true);
  };

  // [実行処理]認証情報確認ダイアログ
  const handleCertificatesDialog = (v: ViewCertificateType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitCertificate =
      businessUnitCertificatesData.current.find(
        (w) => w.businessUnitCertificateId === v.businessUnitCertificateId
      ) ?? {};
    if (formItem) {
      setCertificateFormItems({
        displayModeType: 'display',
        businessUnitCertificate: formItem,
      });
    }
    setCertificateDialogOpen(true);
  };

  // 自社企業登録(基本情報)
  const handleOcpBusinessUnitProfileInput = () => {
    const state: PageState = {
      ids: [itemProfile?.businessUnitProfileId ?? ''],
      sourceViewId: VIEW_ID,
      actionType: 'edit',
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: {
        sourceViewId: location.baseViewOption?.sourceViewId,
        sourceTab: tabPanel,
      },
    };
    navi('/opc-businessunit-profile-input', { state });
  };
  // 自社企業登録(拠点)
  const handleOcpBusinessUnitBranchInput = () => {
    const state: PageState = {
      // ids: [itemBranch?.businessUnitBranchId ?? ''],
      sourceViewId: VIEW_ID,
      actionType: 'edit',
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: {
        sourceViewId: location.baseViewOption?.sourceViewId,
        sourceTab: tabPanel,
      },
    };
    navi('/opc-businessunit-branch-input', { state });
  };

  // [実行処理]得意分野情報確認ダイアログ
  const handleStrengthDialog = (v: ViewStrengthType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitStrength =
      businessUnitStrengthsData.current.find(
        (w) => w.businessUnitStrengthId === v.businessUnitStrengthId
      ) ?? {};
    if (formItem) {
      setStrengthFormItems({
        companyId: formItem.companyId ?? '',
        category: formItem.category ?? {},
        content: formItem.content ?? '',
        linkProperties: formItem.linkProperties ?? {},
      });
    }
    setStrengthDialogOpen(true);
  };

  // 自社企業登録(決算)
  const handleOcpBusinessUnitFinancialInput = () => {
    const state: PageState = {
      // ids: [itemFinancial?.businessUnitFinancialId ?? ''],
      sourceViewId: VIEW_ID,
      actionType: 'edit',
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: {
        sourceViewId: location.baseViewOption?.sourceViewId,
        sourceTab: tabPanel,
      },
    };
    navi('/opc-businessunit-financial-input', { state });
  };
  // 自社企業登録(設備)
  const handleOcpBusinessUnitFacilitiesInput = () => {
    const state: PageState = {
      // ids: [itemFacilities?.businessUnitFacilitiesId ?? ''],
      sourceViewId: VIEW_ID,
      actionType: 'edit',
      naviFilters: location.naviFilters,
      beforeStateIds: location?.ids?.length
        ? location.ids
        : [myCompanyInfo.current?.companyId ?? ''],
      baseViewOption: {
        sourceViewId: location.baseViewOption?.sourceViewId,
        sourceTab: tabPanel,
      },
    };
    navi('/opc-businessunit-facilities-input', { state });
  };
  // 自社企業登録(認証)
  const handleOcpBusinessUnitCertificateInput = () => {
    const state: PageState = {
      // ids: [itemCertificate?.businessUnitCertificateId ?? ''],
      sourceViewId: VIEW_ID,
      actionType: 'edit',
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: {
        sourceViewId: location.baseViewOption?.sourceViewId,
        sourceTab: tabPanel,
      },
    };
    navi('/opc-businessunit-certificate-input', { state });
  };
  // 自社企業登録(得意分野)
  const handleOcpBusinessUnitStrengthInput = () => {
    const state: PageState = {
      // ids: [itemStrength?.businessUnitStrengthId ?? ''],
      sourceViewId: VIEW_ID,
      actionType: 'edit',
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: {
        sourceViewId: location.baseViewOption?.sourceViewId,
        sourceTab: tabPanel,
      },
    };
    navi('/opc-businessunit-strength-input', { state });
  };
  // 自社企業登録(技術情報)
  const handleOcpBusinessUnitSkillInput = () => {
    const state: PageState = {
      // ids: [itemSkill?.businessUnitSkillId ?? ''],
      sourceViewId: VIEW_ID,
      actionType: 'edit',
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: {
        sourceViewId: location.baseViewOption?.sourceViewId,
        sourceTab: tabPanel,
      },
    };
    navi('/opc-businessunit-skill-input', { state });
  };

  const renderProfileComponent = () => {
    return (
      <div className="info">
        <div className="ocpBusinessUnitProfileConfirmation">
          {/* 1段目 */}
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-40">
                {/* 企業名 */}
                <Textbox
                  name="companyName"
                  className="companyName"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.displayNameLang?.ja}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.companyName"
                  columns={preset.columns}
                />
              </div>
              <div className="w-40">
                {/* 英語名 */}
                <Textbox
                  name="companyNameEn"
                  className="companyNameEn"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.displayNameLang?.en}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.companyNameEn"
                  columns={preset.columns}
                />
              </div>
              <div className="w-20">
                {/* 法人番号 */}
                <Textbox
                  name="companyLegalPropertiesCode"
                  className="companyLegalPropertiesCode"
                  type="text"
                  disabled={true}
                  value={
                    itemProfile?.profile?.companyLegalProperties?.companyNumber
                  }
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.companyLegalPropertiesCode"
                  columns={preset.columns}
                />
              </div>
            </div>
          </div>
          {/* 2段目 */}
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-40">
                {/* ふりがな */}
                <Textbox
                  name="companyNameKana"
                  className="companyNameKana"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.displayNameLang?.['ja-kana']}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.companyNameKana"
                  columns={preset.columns}
                />
              </div>
              <div className="w-40">
                {/* ホームページURL */}
                <LinkTextbox
                  name="websiteUrl"
                  className="websiteUrl"
                  value={itemProfile?.profile?.websiteUrl}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.websiteUrl"
                  columns={preset.columns}
                  isExternalLink={true}
                />
              </div>
              <div className="w-20">
                {/* 適格請求書番号 */}
                <Textbox
                  name="eligibleInvoiceAuthorizationNumber"
                  className="eligibleInvoiceAuthorizationNumber"
                  type="text"
                  disabled={true}
                  value={
                    itemProfile?.profile?.companyLegalProperties
                      ?.eligibleInvoiceAuthorizationNumber
                  }
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.eligibleInvoiceAuthorizationNumber"
                  columns={preset.columns}
                />
              </div>
            </div>
          </div>
          {/* 3段目 */}
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-20">
                {/* 代表者名 */}
                <Textbox
                  name="representative"
                  className="representative"
                  type="text"
                  disabled={true}
                  value={
                    itemProfile?.profile?.representative?.displayNameLang?.ja
                  }
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.representative"
                  columns={preset.columns}
                />
              </div>
              <div className="w-20">
                {/* 代表者名（ふりがな） */}
                <Textbox
                  name="representativeKana"
                  className="representativeKana"
                  type="text"
                  disabled={true}
                  value={
                    itemProfile?.profile?.representative?.displayNameLang?.[
                      'ja-kana'
                    ]
                  }
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.representativeKana"
                  columns={preset.columns}
                />
              </div>
              <div className="w-20">
                {/* 代表者（役職） */}
                <Textbox
                  name="position"
                  className="position"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.representative?.position}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.position"
                  columns={preset.columns}
                />
              </div>
              <div className="w-20">
                {/* 代表者（生年月日） */}
                <Textbox
                  name="birthDateDt"
                  type="text"
                  value={formatIDateToString(
                    itemProfile?.profile?.representative?.birthDate,
                    'YYYY/MM/DD'
                  )}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.birthDateDt"
                  columns={preset.columns}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          {/* 4段目 */}
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-20">
                {/* 本社（郵便番号） */}
                <Textbox
                  name="postalCode"
                  className="postalCode"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.address?.postalCode}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.postalCode"
                  columns={preset.columns}
                />
              </div>
              <div className="w-20">
                {/* 本社（都道府県） */}
                <Textbox
                  name="region"
                  type="text"
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.region"
                  columns={preset.columns}
                  disabled={true}
                  value={
                    itemProfile?.profile?.address?.region?.displayNameLang?.ja
                  }
                />
              </div>
              <div className="w-40">
                {/* 本社（住所） */}
                <Textbox
                  name="addressLine"
                  className="addressLine"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.address?.addressLines?.join()}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.addressLine"
                  columns={preset.columns}
                />
              </div>
              <div className="w-20">
                {/* 本社（電話番号） */}
                <Textbox
                  name="phoneNumber"
                  className="phoneNumber"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.phoneNumber}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.phoneNumber"
                  columns={preset.columns}
                />
              </div>
            </div>
          </div>
          {/* 5段目 */}
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-20">
                {/* 従業員（人数） */}
                <Textbox
                  name="employees"
                  className="employees right"
                  type="text"
                  disabled={true}
                  value={
                    itemProfile?.profile?.employees?.size
                      ? convertLongToString(
                          itemProfile?.profile?.employees?.size
                        )
                      : ''
                  }
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.employees"
                  columns={preset.columns}
                  formatter={toCommaTypeString}
                />
              </div>
              <div className="w-20">
                {/* 従業員（平均年齢） */}
                <Amountbox
                  name="averageAge"
                  className="averageAge"
                  value={itemProfile?.profile?.employees?.averageAge}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.averageAge"
                  columns={preset.columns}
                  displayOption={{ isCommaFormat: true }}
                  disabled={true}
                />
              </div>
              <div className="w-20">
                {/* 資本金（千円） */}
                <Amountbox
                  name="capitalStock"
                  className="capitalStock"
                  value={convertBaseDigitOnceTo(
                    itemProfile?.profile?.capitalStock,
                    BASE_DIGIT_THOUSAND
                  )}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.capitalStock"
                  columns={preset.columns}
                  displayOption={{ isCommaFormat: true }}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          {/* 6段目 */}
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                {/* 主要株主 */}
                <Textbox
                  name="mainShareholder"
                  className="mainShareholder"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.mainShareholder}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.mainShareholder"
                  columns={preset.columns}
                />
              </div>
            </div>
          </div>
          {/* 7段目 */}
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                {/* 主要取引銀行 */}
                <Textbox
                  name="mainBank"
                  className="mainBank"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.mainBank}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.mainBank"
                  columns={preset.columns}
                />
              </div>
            </div>
          </div>
          {/* 8段目 */}
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                {/* 主要仕入先 */}
                <Textbox
                  name="mainSupplier"
                  className="mainSupplier"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.mainSupplier}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.mainSupplier"
                  columns={preset.columns}
                />
              </div>
            </div>
          </div>
          {/*9段目 */}
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                {/* 主要納入先 */}
                <Textbox
                  name="mainCustomer"
                  className="mainCustomer"
                  type="text"
                  disabled={true}
                  value={itemProfile?.profile?.mainCustomer}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.mainCustomer"
                  columns={preset.columns}
                />
              </div>
            </div>
          </div>
          {/* 10段目 */}
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-80">
                {/* 事業内容 */}
                <Textarea
                  name="mainBusiness"
                  className="w-100 mh-middle"
                  disabled={true}
                  value={itemProfile?.profile?.mainBusiness ?? ''}
                  labelId="OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION.mainBusiness"
                  columns={preset.columns}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 拠点タブ
  const renderBranchComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={branchItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitBranchId',
                  columns: [
                    {
                      header: {
                        id: 'branch.displayName',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'displayName',
                      width: '16%',
                    },
                    {
                      header: {
                        id: 'branch.address',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'address',
                    },
                    {
                      header: {
                        id: 'branch.phoneNumber',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'phoneNumber',
                      width: '11rem',
                    },
                    {
                      header: {
                        id: 'branch.overview',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'overview',
                      width: '22%',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewBranchType) => {
                        handleBranchDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 決算タブ
  const renderFinancialComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={financialItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitFinancialId',
                  columns: [
                    {
                      header: {
                        id: 'financial.fiscalYear',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'period',
                      width: '10rem',
                    },
                    {
                      header: {
                        id: 'financial.netSales',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'netSales',
                      align: 'right',
                      width: '12rem',
                    },
                    {
                      header: {
                        id: 'financial.operatingProfit',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'operatingProfit',
                      align: 'right',
                      width: '12rem',
                    },
                    {
                      header: {
                        id: 'financial.ordinaryIncome',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'ordinaryIncome',
                      align: 'right',
                      width: '12rem',
                    },
                    {
                      header: {
                        id: 'financial.notes',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'notes',
                      align: 'right',
                      width: '8rem',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{ iconMenu: [] }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 設備タブ
  const renderFacilitiesComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={facilityItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitFacilitiesId',
                  columns: [
                    {
                      header: {
                        id: 'facility.branchName',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'branchName',
                    },
                    {
                      header: {
                        id: 'facility.manufacture',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'manufacture',
                    },
                    {
                      header: {
                        id: 'facility.modelNumber',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'modelNumber',
                    },
                    {
                      header: {
                        id: 'facility.assetsAmount',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'assetsAmount',
                      align: 'right',
                      width: '6rem',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewFacilityType) => {
                        handleFacilitiesDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 認証タブ
  const renderCertificateComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="sort-link-area">
                <span className="sort-label">
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.label',
                    prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                  })}
                </span>
                <div
                  className="sort-link expiredDt"
                  onClick={() => sortCertificateItems('expirationDt', true)}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.expiredDtDesc',
                    prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                  })}
                </div>
                <div
                  className="sort-link obtainedDt"
                  onClick={() => sortCertificateItems('obtainedDt', true)}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.obtainedDtDesc',
                    prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                  })}
                </div>
                <div
                  className="sort-link certificationName"
                  onClick={() =>
                    sortCertificateItems('certificationName', false)
                  }
                >
                  {GetMessageWithIntl(intl, {
                    id: 'certificate.sort.certificationNameAsc',
                    prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="certificate-list-area">
                <SimpleIconListView
                  data={certificateItems}
                  viewOptions={{
                    previewRowCount: 10,
                    keyColumn: 'businessUnitCertificateId',
                    columns: [
                      {
                        header: {
                          id: 'certificate.displayName',
                          prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                        },
                        propertyName: 'displayName',
                        width: '20%',
                      },
                      {
                        header: {
                          id: 'certificate.obtained',
                          prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                        },
                        propertyName: 'obtained',
                        width: '6rem',
                      },
                      {
                        header: {
                          id: 'certificate.obtainedDt',
                          prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                        },
                        propertyName: 'obtainedDt',
                        width: '8rem',
                      },
                      {
                        header: {
                          id: 'certificate.expirationDt',
                          prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                        },
                        propertyName: 'expirationDt',
                        width: '8rem',
                      },
                      {
                        header: {
                          id: 'certificate.scope',
                          prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                        },
                        propertyName: 'scope',
                      },
                    ],
                    omitFooter: true,
                  }}
                  actionOptions={{}}
                  iconMenuOptions={{
                    iconMenu: [
                      {
                        name: 'description',
                        displayName: '確認',
                        func: (v: ViewCertificateType) => {
                          handleCertificatesDialog(v);
                        },
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 得意分野タブ
  const renderStrengthComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={strengthItems}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitStrengthId',
                  columns: [
                    {
                      header: {
                        id: 'strength.category',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'category',
                      width: '12rem',
                    },
                    {
                      header: {
                        id: 'strength.content',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'content',
                    },
                    {
                      header: {
                        id: 'strength.linkProperties',
                        prefixId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
                      },
                      propertyName: 'linkProperties',
                      width: '30%',
                    },
                  ],
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{
                  iconMenu: [
                    {
                      name: 'description',
                      displayName: '確認',
                      func: (v: ViewStrengthType) => {
                        handleStrengthDialog(v);
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 技術情報タブ
  const renderSkillComponent = () => {
    return (
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <CategorizedCheckList
                listData={skillTreeData}
                checkedIds={
                  businessUnitSkillData?.businessUnitSkillItemIds || []
                }
                disabled={true}
                checkedOnly={true}
                inputOption={{
                  isVisibleOpenAll: true,
                  isVisibleCloseAll: true,
                }}
              ></CategorizedCheckList>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="otherSkill"
                className="w-75 mh-middle"
                value={businessUnitSkillData?.otherSkill || ''}
                labelId="OCP_BUSINESS_UNIT_SKILL_INPUT.otherSkill"
                columns={['otherSkill']}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="OcpBusinessUnitProfileConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath="" //メニューから直接起動のため戻り先なし
              iconItems={getPageNavigationIconItems()}
              pageInfo={{
                isVisibleMoveNavi: false,
                // pageNumber: page.pageNumber,
                isDisableBackNavi: true,
                isUnvisibleBackNavi: true,
              }}
              infoOption={{
                lastUpdateInfo: latestUpdateInfo,
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="info">
              <div className="basic-info-body">
                <div className="tabPanelContents">
                  {tabs.map((v, index) => {
                    const selectPane = v === tabPanel ? 'current' : '';
                    return (
                      <div
                        key={`tab-${index}`}
                        className={`tabPanel ${selectPane}`}
                        onClick={() => setTabPanel(v)}
                      >
                        {GetMessageWithIntl(intl, { prefixId: VIEW_ID, id: v })}
                      </div>
                    );
                  })}
                </div>
                {isCurrentTab('profile') && renderProfileComponent()}
                {isCurrentTab('branch') && renderBranchComponent()}
                {isCurrentTab('financial') && renderFinancialComponent()}
                {isCurrentTab('facilities') && renderFacilitiesComponent()}
                {isCurrentTab('certificate') && renderCertificateComponent()}
                {isCurrentTab('strength') && renderStrengthComponent()}
                {isCurrentTab('skill') && renderSkillComponent()}
              </div>
            </div>
          </div>
        </div>
        {/* システム通知設定ダイアログ */}
        <SystemNotificationSettingDialog
          isOpen={isOpenSystemNotificationDialog}
          inputData={systemNotificationUsers}
          onDecision={handleSystemNotificationSetting}
          onCancel={() => {
            setOpenSystemNotificationDialog(false);
          }}
        />
        {/* 拠点情報確認ダイアログ */}
        <BusinessUnitBranchDialog
          isOpen={isBranchDialogOpen}
          inputMode={false}
          inputOption={branchFormItems}
          onCancel={() => {
            setBranchDialogOpen(false);
          }}
          onDesicion={() => {}}
        />
        <BusinessUnitFacilityDialog
          isOpen={isFacilityDialogOpen}
          inputMode={false}
          inputOption={facilityFormItems ?? { companyId: '' }}
          onCancel={() => {
            setFacilityDialogOpen(false);
          }}
          onDecision={() => {}}
        />
        <BusinessUnitStrengthDialog
          isOpen={isStrengthDialogOpen}
          inputMode={false}
          inputOption={strengthFormItems}
          onCancel={() => {
            setStrengthDialogOpen(false);
          }}
          onDecision={() => {}}
        />
        <CertificateRegistrationDialog
          isOpen={isCertificateDialogOpen}
          inputOption={certificateFormItems}
          onCancel={() => {
            setCertificateDialogOpen(false);
          }}
          onDecision={() => {}}
          messageOption={{}}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
