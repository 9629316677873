import { useEffect, useRef, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  ApprovalRejectionDialog,
  ApprovalRejectionInputOption,
  DialogType,
  InitialFilter,
  ListView,
  MenuActionItem,
  Preset,
  Property,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListEstimateRequestUnitSummarys,
  PageState,
  ViewId,
  convertOrganizationStructureReferenceToFilterboxItem,
  convertUserReferenceToFilterboxItem,
  getExceptionMessage,
  getPresetAndSchema,
  getWorkerExceptionMessage,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';
import { getOneByEstimateSelection } from '~/tenant/estimate/utils';

const VIEW_ID: ViewId = 'EST_ESTIMATE_SELECTION_APPROVAL_LIST';

export const EstEstimateSelectionApprovalList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const { state } = useLocation();
  const location = (state ?? {}) as PageState;
  const [isLoading, setLoading] = useState(false);
  const [isReload, setReload] = useState(false);

  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [presetItems, setPresetItems] = useState<PresetItem[]>();
  const listItemRef = useRef<
    mtechnavi.api.estimation.IEstimateRequestUnitSummary[]
  >([]);
  const listAllItemRef = useRef<
    mtechnavi.api.estimation.IEstimateRequestUnitSummary[]
  >([]);
  // 初期フィルタ設定
  const [initialFilter, setInitialFilter] = useState<InitialFilter>({
    info: [],
  });

  // 承認・却下ダイアログ制御用
  const [isShowApprovalOrRejection, setShowApprovalOrRejection] =
    useState(false);
  const [approvalRejectionInputOption, setApprovalRejectionInputOption] =
    useState<ApprovalRejectionInputOption>({
      approveOrganizationStaff: { value: '', displayName: '' },
      approveOrganizationUnit: { value: '', displayName: '' },
      requestComment: '',
      dialogType: 'approval',
    });
  const targetEstimateSelection =
    useRef<mtechnavi.api.estimation.IEstimateSelection>();

  // メッセージ
  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000073' });

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: [
        // 確認(複数選択)
        { name: 'description', func: handleConfirmation },
      ],
      maxMenuColumn: 1,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: [
        // 確認
        { name: 'description', func: handleConfirmation },
      ],
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: [
        // 承認
        { name: 'approval', func: handleShowApprovalDialog },
        // 却下
        { name: 'decline', func: handleShowDeclineDialog },
      ],
    });
    return menuActionItems;
  };

  /** 一覧の確認アイコンボタン処理 */
  const handleConfirmation = (ids?: string[]) => {
    const targetIds = listItemRef.current
      .filter((v) => ids?.includes(v.estimateRequestUnitSummaryId ?? ''))
      .map((w) => w.estimateRequestId ?? '');
    const state: PageState = {
      ids: targetIds,
      sourceViewId: VIEW_ID,
      naviFilters: location?.naviFilters,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navigate('/estimate/est-estimate-request-confirmation', { state });
  };

  /** 承認 */
  const handleShowApprovalDialog = async (ids?: string[]) => {
    if (!ids || ids.length !== 1) {
      error([excessTargetMessage]);
      return;
    }
    const selection = await getOneByEstimateSelection(ids[0]);
    const inputOption = await convertApprovalRejectionInputOption(
      'approval',
      selection
    );
    targetEstimateSelection.current = selection;
    setApprovalRejectionInputOption(inputOption);
    setShowApprovalOrRejection(true);
  };
  const handleApprove = async (comment: string) => {
    setReload(false);
    setLoading(true);
    try {
      const estimateSelectionId =
        targetEstimateSelection.current?.estimateSelectionId;
      await window.App.services.ui.worker.apiCall({
        actionName: 'approveAndShareToEstimateSelection',
        request: { comment, estimateSelectionId },
      });
      targetEstimateSelection.current = undefined;
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    setShowApprovalOrRejection(false);
  };

  /** 却下 */
  const handleShowDeclineDialog = async (ids?: string[]) => {
    if (!ids || ids.length !== 1) {
      error([excessTargetMessage]);
      return;
    }
    const selection = await getOneByEstimateSelection(ids[0]);
    const inputOption = await convertApprovalRejectionInputOption(
      'rejection',
      selection
    );
    targetEstimateSelection.current = selection;
    setApprovalRejectionInputOption(inputOption);
    setShowApprovalOrRejection(true);
  };
  const handleDecline = async (comment: string) => {
    setReload(false);
    setLoading(true);
    try {
      const estimateSelectionId =
        targetEstimateSelection.current?.estimateSelectionId;
      await window.App.services.ui.worker.apiCall({
        actionName: 'rejectEstimateSelection',
        request: { comment, estimateSelectionId },
      });
      targetEstimateSelection.current = undefined;
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    setShowApprovalOrRejection(false);
  };

  /** 承認・却下ダイアログの入力オプションを作成する */
  const convertApprovalRejectionInputOption = async (
    dialogType: DialogType,
    selection?: mtechnavi.api.estimation.IEstimateSelection
  ) => {
    return {
      dialogType,
      requestComment: selection?.approvalRequest?.requestedComment ?? '',
      approveOrganizationUnit:
        convertOrganizationStructureReferenceToFilterboxItem(
          selection?.approvalRequest?.approvalPlanOrganization
        ) ?? { value: '', displayName: '' },
      approveOrganizationStaff: convertUserReferenceToFilterboxItem(
        selection?.approvalRequest?.approvalPlanStaff
      ) ?? { value: '', displayName: '' },
    };
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListEstimateRequestUnitSummarys,
          ]);

        let schema = schemas[0];
        schema = getAltDisplaySchema(
          schema,
          ['status.displayNameLang', 'feedbackCategory.displayNameLang'],
          intl.locale
        );
        schema = getBooleanDataFormetterSchema(schema, ['adoption']);

        setPresetItems(childrenPresetItem);
        setSchema(schema);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, [intl]);

  // 初期フィルタの動的な設定値をセットする
  useEffect(() => {
    (async () => {
      const filter: InitialFilter = {
        info: [],
      };
      // 以降、非同期処理のあとに処理をする
      await null;
      // 承認担当者: ログインユーザーに紐づく担当者
      filter.info.push({
        targetKey: 'feedbackApprovalPlanStaff.email',
        targetValue: myEmail,
      });
      setInitialFilter(filter);
    })();
  }, [VIEW_ID, myEmail]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="EstEstimateRequestDetailList">
          <ListView
            isReload={isReload}
            fullMethodName={FullMethodName_ListEstimateRequestUnitSummarys}
            pageInfo={{
              preset,
              schema,
              menuItem: setMenuActionItem(),
              menuTarget: 'estimateRequestUnitSummaryId',
              presetItems,
              headerTitle: { viewId: VIEW_ID },
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            filterItemOption={{
              isRequestBodyFilter: true,
              initialFilterItems: initialFilter,
            }}
            stateOption={{
              onOriginalItemState: (items: unknown[]) =>
                (listItemRef.current =
                  items as mtechnavi.api.estimation.IEstimateRequestUnitSummary[]),
              onAllItemState: (items: unknown[]) =>
                (listAllItemRef.current =
                  items as mtechnavi.api.estimation.IEstimateRequestUnitSummary[]),
            }}
          />
        </div>
        <Toast />
      </Container>
      {/* 承認・却下ダイアログ */}
      <ApprovalRejectionDialog
        isOpen={isShowApprovalOrRejection}
        messageOption={{
          headerLabelId: {
            id: approvalRejectionInputOption.dialogType,
            prefixId: 'DIALOG_TITLE',
          },
          messageLabelId: {
            id: approvalRejectionInputOption.dialogType,
            prefixId: 'DIALOG_DESCRIPTION',
          },
        }}
        inputOption={approvalRejectionInputOption}
        onDecision={
          approvalRejectionInputOption.dialogType === 'approval'
            ? handleApprove
            : handleDecline
        }
        onCancel={() => {
          setShowApprovalOrRejection(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
};
