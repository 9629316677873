import { ViewId } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { IconButton } from '~/shared/components/ui/Button';
import { useState } from 'react';
import { PartialLoading } from '~/shared/components/ui';
import { GetMessageWithIntl } from '~/shared/components';
import { useIntl } from 'react-intl';
import { BlueprintSegmentNames } from '~/tenant/blueprint/utils';
import { useBlueprintSearch } from '../../BlueprintSearchProvider';

export type BlueprintSearchResultItemSize = 'small' | 'large';
export interface BlueprintSearchResultItemProps {
  viewId: ViewId;
  data?: mtechnavi.api.blueprint.ISearchBlueprintContent;
  size?: BlueprintSearchResultItemSize;
}
/**
 * 図面検索結果要素
 */
export const BlueprintSearchResultItem = ({
  viewId,
  data,
  size,
}: BlueprintSearchResultItemProps) => {
  const intl = useIntl();
  const [isLoaded, setLoaded] = useState(false);
  const [isErrorImage, setErrorImage] = useState(false);

  const { condition } = useBlueprintSearch();

  const renderSegmentStatus = (
    segmentName: BlueprintSegmentNames,
    value?: number | null
  ) => {
    // 検索条件で指定されている面でなければ表示しない
    if (!condition?.shape?.classNames?.includes(segmentName)) {
      return <></>;
    }
    return (
      <li>
        {GetMessageWithIntl(intl, {
          viewId,
          id: 'attributeFormat',
          value: {
            $1: GetMessageWithIntl(intl, { viewId, id: `attr.${segmentName}` }),
            $2: value ?? '',
          },
        })}
      </li>
    );
  };

  return (
    <div className={`BlueprintSearchResultItem ${size ?? ''}`}>
      <div className="header">
        <a
          target="_blank"
          rel="noreferrer"
          href={`/blueprint/blueprint-confirmation/${data?.blueprintId}/${data?.revision}?from=blueprint-search`}
        >
          <IconButton
            name="blueprintDetail"
            iconType="description"
            buttonType="basic"
            onClick={() => {}}
          />
        </a>
        <span>{`${data?.blueprintNumber ?? ''}(${data?.revision ?? ''})`}</span>
      </div>
      <div className="detail">
        <div className="thumbnail">
          <img
            className={`${isLoaded ? 'loaded' : ''} ${
              isErrorImage ? 'error' : ''
            }`}
            {...(data?.thumbnailAssetId && !isErrorImage
              ? {
                  src: `/mtechnavi.api.assetinventory.AssetProxy/${data?.thumbnailAssetId}`,
                }
              : {})}
            onLoad={() => setLoaded(true)}
            onError={() => {
              setLoaded(true);
              setErrorImage(true);
            }}
          />
          <PartialLoading isShow={!isLoaded && !!data?.thumbnailAssetId} />
        </div>
        <ul className="match-result">
          {renderSegmentStatus(
            BlueprintSegmentNames.Front,
            data?.distanceFront
          )}
          {renderSegmentStatus(
            BlueprintSegmentNames.Right,
            data?.distanceRight
          )}
          {renderSegmentStatus(
            BlueprintSegmentNames.Bottom,
            data?.distanceBottom
          )}
          {(data?.attributes ?? []).map((item, i) => (
            <li key={i}>
              {GetMessageWithIntl(intl, {
                viewId,
                id: 'attributeFormat',
                value: { $1: item.name, $2: item.value ?? '' },
              })}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
