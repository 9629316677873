import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  error,
  GetMessageWithIntl,
  success,
  Toast,
} from '~/shared/components';
import {
  AsyncTaskAcceptDialog,
  BlueprintFile,
  BlueprintFileUploadDialog,
  BlueprintFileUploadDialogOutputOption,
  getDayFormetterDisplaySchema,
  getJoinedUserNameEmailSchema,
  ImportDialog,
  ListView,
  MenuActionItem,
  Preset,
  Property,
} from '~/shared/components/ui';
import { RadioSelectDialog } from '~/shared/components/ui/Dialog/RadioSelectDialog';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { useConfirmation } from '~/shared/hooks';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  getExceptionMessage,
  getLocalStorageCheckboxData,
  getPresetAndSchema,
  getWorkerExceptionMessage,
  PageState,
  saveLocalStorageCheckboxData,
  ViewId,
} from '~/shared/utils';
import { FullMethodName_ListBlueprints } from '~/worker';
import {
  BLUEPRINT_FILE_SIZE,
  BLUEPRINT_FILE_TYPES,
  uploadBlueprintFiles,
} from '../utils';

type DialogPromise<T> = (value: T | PromiseLike<T>) => void;

const viewId: ViewId = 'BLP_BLUEPRINT_LIST';
export const BlueprintBlueprintList = () => {
  const intl = useIntl();
  const navi = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const location = (useLocation().state as PageState) ?? [];
  const { showLoading, hideLoading } = useLoading();
  const { confirmation, confirmationElement } = useConfirmation();

  const [presetItems, setPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [isReload, setReload] = useState(false);

  const [isOpenImportTypeSelectDialog, setOpenImportTypeSelectDialog] =
    useState(false);
  const [isOpenBlueprintImportDialog, setOpenBlueprintImportDialog] =
    useState(false);
  const [isOpenCsvImportDialog, setOpenCsvImportDialog] = useState(false);
  const [isUpdateModeConfirmDialog, setUpdateModeConfirmDialog] =
    useState(false);
  const updateModeConfirmResolveRef = useRef<DialogPromise<string | null>>();

  const [isOpenAsyncTask, setOpenAsyncTask] = useState(false);
  const [asyncTaskName, setAsyncTaskName] = useState<
    'blueprintFileImport' | 'csvFileImport'
  >();

  const originalItemsRef = useRef<mtechnavi.api.blueprint.IBlueprint[]>();
  const allItemsRef = useRef<mtechnavi.api.blueprint.IBlueprint[]>();

  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  const deleteMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };

  /** 登録ボタン */
  const handleAdd = (ids?: string[]) => {
    const state: PageState = {
      ids: ids ?? [],
      sourceViewId: viewId,
      actionType: 'add',
      baseViewOption: { sourceViewId: viewId },
    };
    navi('/blueprint/blueprint-input', { state });
  };

  /** 確認ボタン */
  const handleConfirmation = (ids?: string[]) => {
    const state: PageState = {
      ids: ids ?? [],
      sourceViewId: viewId,
      naviFilters: location?.naviFilters,
      baseViewOption: { sourceViewId: viewId },
    };
    navi('/blueprint/blueprint-confirmation', { state });
  };

  /** 取込ボタン */
  const handleImport = () => {
    setOpenImportTypeSelectDialog(true);
  };
  const handleImportTypeSelected = async (type: string) => {
    if (!type) {
      error([GetMessageWithIntl(intl, { id: 'E0000023' })]);
      return;
    }
    if (type === 'blueprint') {
      setOpenBlueprintImportDialog(true);
    }
    if (type === 'csv') {
      setOpenCsvImportDialog(true);
    }
  };

  /** CSVインポート完了時 */
  const handleCsvImportSuccess = async () => {
    showLoading();
    setReload(false);
    try {
      // エンドポイントが BQ のため、少しウェイトを掛けてから非同期処理ダイアログを表示
      await new Promise((resolve) => setTimeout(resolve, 2500));

      setAsyncTaskName('csvFileImport');
      setOpenAsyncTask(true);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  /** 図面ファイルのインポート */
  const handleBlueprintUpload = async (
    result: BlueprintFileUploadDialogOutputOption
  ) => {
    if (!result.files || result.files.length === 0) {
      return;
    }

    let updateMode: string | null = null;
    if (result.isRegisteredItem) {
      updateMode = await confirmEditMode();
      if (!updateMode) {
        return;
      }
    }

    // インポート処理
    setReload(false);
    try {
      showLoading();

      const mode = getBlueprintUpdateMode(updateMode ?? 'Revision');
      await importRequest(result.files, mode);

      // エンドポイントが BQ のため、少しウェイトを掛けてから非同期処理ダイアログを表示
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setAsyncTaskName('blueprintFileImport');
      setOpenAsyncTask(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  /** 登録済みファイルの差し替えモード確認 */
  const confirmEditMode = async () => {
    setUpdateModeConfirmDialog(true);
    return new Promise<string | null>(
      (resolve) => (updateModeConfirmResolveRef.current = resolve)
    );
  };

  /** 図面取込更新リクエスト */
  const importRequest = async (
    targetList: BlueprintFile[],
    mode?: mtechnavi.api.blueprint.ImportBlueprintsWithBlueprintFilesMode | null
  ) => {
    const attachments = await uploadBlueprintFiles(
      targetList.map((item) => item.file)
    );
    return window.App.services.ui.worker.apiCall({
      actionName: 'importBlueprints',
      request: {
        attachments,
        mode,
      },
    });
  };

  const handleCloseAsyncTask = () => {
    setOpenImportTypeSelectDialog(false);
    setOpenCsvImportDialog(false);
    setOpenBlueprintImportDialog(false);
    setOpenAsyncTask(false);
    setReload(true);
  };

  /** 削除ボタン */
  const handleDelete = async (ids?: string[]) => {
    if (ids && ids.length > 1) {
      error([GetMessageWithIntl(intl, { id: 'E0000073' })]);
      return;
    }
    if (!(await confirmation(deleteMessage))) {
      return;
    }
    const blueprint = (originalItemsRef.current ?? []).find((item) =>
      ids?.includes(item.blueprintId ?? '')
    );
    showLoading();
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBlueprint',
        request: { blueprint },
      });
      // エンドポイントが BQ のため、少しウェイトを掛けてから完了表示と再取得を行う
      await new Promise((resolve) => setTimeout(resolve, 2000));

      success([toastSuccess]);
      setReload(true);
      clearCheckBox();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  const clearCheckBox = () => {
    saveLocalStorageCheckboxData(viewId, [], myEmail);
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: [
        { name: 'noteadd', func: handleAdd },
        { name: 'description', func: handleConfirmation },
        { name: 'upload', func: handleImport },
        { name: 'delete', func: handleDelete },
      ],
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: [{ name: 'description', func: handleConfirmation }],
    });
    return menuActionItems;
  };

  useEffect(() => {
    (async () => {
      try {
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(viewId, [FullMethodName_ListBlueprints]);

        let schema = schemas.at(0) ?? [];

        schema = getJoinedUserNameEmailSchema(
          schema,
          'updatedProperties.updatedBy.displayName',
          'updatedProperties.updatedBy.email'
        );

        schema = getDayFormetterDisplaySchema(
          schema,
          ['updatedProperties.updatedAt'],
          { dayOpts: { formatType: 'YYYY/MM/DD HH:mm' } }
        );

        setPresetItem(childrenPresetItem);
        setSchema(schema);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  return (
    <Container viewId={viewId}>
      <div className="BlueprintList">
        <ListView
          fullMethodName={FullMethodName_ListBlueprints}
          pageInfo={{
            preset,
            schema,
            menuItem: setMenuActionItem(),
            menuTarget: 'blueprintId',
            presetItems,
            headerTitle: { viewId },
            listSkipType: {
              isTotal: true,
              isOutput: true,
              isListActionMenu: true,
            },
          }}
          isReload={isReload}
          stateOption={{
            onOriginalItemState: (items) => {
              originalItemsRef.current =
                items as mtechnavi.api.blueprint.IBlueprint[];
            },
            onAllItemState: (allItems) => {
              allItemsRef.current =
                allItems as mtechnavi.api.blueprint.IBlueprint[];
            },
          }}
        />
      </div>
      {confirmationElement}
      <>
        {/* 取込ファイル選択ダイアログ */}
        <RadioSelectDialog
          key={isOpenImportTypeSelectDialog ? 0 : 1}
          isOpen={isOpenImportTypeSelectDialog}
          inputOption={{
            groupName: 'importType',
            radioButtonInfo: [
              {
                value: 'blueprint',
                displayName: GetMessageWithIntl(intl, {
                  viewId,
                  id: 'import.blueprint',
                }),
              },
              {
                value: 'csv',
                displayName: GetMessageWithIntl(intl, {
                  viewId,
                  id: 'import.csv',
                }),
              },
            ],
          }}
          messageOption={{
            headerMessageOption: { viewId, id: 'import.title' },
            descriptionMessageOption: { viewId, id: 'import.message' },
          }}
          onDecision={handleImportTypeSelected}
          onCancel={() => setOpenImportTypeSelectDialog(false)}
        />
      </>
      <>
        {/* インポートダイアログ */}
        <ImportDialog
          key={isOpenCsvImportDialog ? 0 : 1}
          isOpen={isOpenCsvImportDialog}
          headerLabelId={{ prefixId: 'DIALOG_TITLE', id: 'FILE_IMPORT' }}
          allIds={(allItemsRef.current ?? []).map(
            (item) => item.blueprintId ?? ''
          )}
          ids={getLocalStorageCheckboxData(viewId ?? '', myEmail)}
          preset={[]}
          isFileTypeSelectBox={true}
          isAsyncImport={true}
          sleepTime={0}
          userMasterCategoryName="A0000046"
          handleExport={{ name: 'blueprint', headerColumns: [] }}
          handleImport={{ name: 'blueprint', headerColumns: [] }}
          onChangeState={() => setOpenCsvImportDialog(false)}
          onHandleSuccess={handleCsvImportSuccess}
          onChangeLoadingState={(isLoading) =>
            isLoading ? showLoading() : hideLoading()
          }
        />
      </>
      <>
        {/* 図面取込ダイアログ */}
        <BlueprintFileUploadDialog
          key={isOpenBlueprintImportDialog ? 0 : 1}
          isOpen={isOpenBlueprintImportDialog}
          displayOption={{ isDnd: true }}
          messageOption={{
            dialogTitle: {
              prefixId: 'DIALOG_TITLE',
              id: 'blueprintFileUpload',
            },
            buttonType: { id: 'import' },
            simpleListViewHeader: {
              prefixId: 'blueprintFileUploadDialog',
              id: 'blueprintNumber',
            },
          }}
          uploaderOption={{
            validateOption: {
              allowedFileExtensions: BLUEPRINT_FILE_TYPES,
              maxFileSizeInMebis: BLUEPRINT_FILE_SIZE,
              maxFileCount: 10,
            },
          }}
          onDecision={handleBlueprintUpload}
          onCancel={() => setOpenBlueprintImportDialog(false)}
        />
      </>
      <>
        {/* 処理確認ダイアログ */}
        <RadioSelectDialog
          key={isUpdateModeConfirmDialog ? 0 : 1}
          isOpen={isUpdateModeConfirmDialog}
          inputOption={{
            groupName: 'updateMode',
            initialValue: 'Revision',
            radioButtonInfo: [
              {
                value: 'Revision',
                displayName: GetMessageWithIntl(intl, {
                  viewId,
                  id: 'override.revision',
                }),
              },
              {
                value: 'Update',
                displayName: GetMessageWithIntl(intl, {
                  viewId,
                  id: 'override.update',
                }),
              },
            ],
          }}
          messageOption={{
            headerMessageOption: { viewId, id: 'override.title' },
            descriptionMessageOption: { viewId, id: 'override.message' },
          }}
          onDecision={(type) => {
            updateModeConfirmResolveRef.current &&
              updateModeConfirmResolveRef.current(type);
            setUpdateModeConfirmDialog(false);
          }}
          onCancel={() => {
            updateModeConfirmResolveRef.current &&
              updateModeConfirmResolveRef.current(null);
            setUpdateModeConfirmDialog(false);
          }}
        />
      </>
      <>
        {/* 非同期処理受付ダイアログ */}
        <AsyncTaskAcceptDialog
          isOpen={isOpenAsyncTask}
          targetActionName={
            asyncTaskName ? { prefixId: 'BLUEPRINT', id: asyncTaskName } : {}
          }
          onClose={handleCloseAsyncTask}
        />
      </>
      <Toast />
    </Container>
  );
};

const getBlueprintUpdateMode = (
  mode: string | null
): mtechnavi.api.blueprint.ImportBlueprintsWithBlueprintFilesMode | null => {
  switch (mode) {
    case 'Revision':
      return mtechnavi.api.blueprint.ImportBlueprintsWithBlueprintFilesMode
        .Revision;
    case 'Update':
      return mtechnavi.api.blueprint.ImportBlueprintsWithBlueprintFilesMode
        .Update;
    default:
      return null;
  }
};
