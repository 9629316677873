import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterExpression } from '~/worker';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  Property,
  Preset,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
  MenuActionItem,
  ViewMenu,
  ListView,
  ConfirmationDialog,
  OutputDialog,
  OutputDialogOutput,
  CommentDialog,
  InitialFilter,
  ApprovalRejectionInputOption,
  ApprovalRejectionDialogMessageOption,
  ApprovalRejectionDialog,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  PageState,
  ViewId,
  getExceptionMessage,
  getPresetAndSchema,
  saveLocalStorageCheckboxData,
  getWorkerExceptionMessage,
  FullMethodName_ListWorkTasks,
  autoDownloadFileWithDate,
  actionType,
  convertUserReferenceToFilterboxItem,
  convertOrganizationStructureReferenceToFilterboxItem,
} from '~/shared/utils';

type Worktask = mtechnavi.api.worktask.WorkTask;

export function WtWorkTaskList() {
  const myEmail = useAuth().user?.email ?? '';
  const myUserId = useAuth().user?.userId ?? '';
  const intl = useIntl();
  const navi = useNavigate();
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [VIEW_ID, setViewId] = useState<ViewId>('');
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [isReload, setReload] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isOutputOpen, setOutputOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isShowDiscard, setShowDiscard] = useState(false);
  const [discardComment, setDiscardComment] = useState('');
  const [userOrganizationIds, setUserOrganizationIds] = useState<string[]>([]);
  const workTaskList = useRef<Worktask[]>([]);
  const workTaskAllList = useRef<Worktask[]>([]);
  const selectedIds = useRef<string[]>([]);
  const [initialFilter, setInitialFilter] = useState<InitialFilter>({
    info: [],
  });

  const deleteMessage = {
    id: 'C0000001',
    value: { $1: '「削除」' },
  };
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  const unselectedMessage = GetMessageWithIntl(intl, { id: 'E0000023' });
  const statusMessage = GetMessageWithIntl(intl, { id: 'E0000020' });
  const notFoundOutputMessage = GetMessageWithIntl(intl, { id: 'I0000004' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000073' });
  const managementCaseMessage = GetMessageWithIntl(intl, { id: 'E0000090' });

  const [decline, setDecline] = useState(false);
  const [isShowApprovalRejectionDialog, setShowApprovalRejectionDialog] =
    useState(false);
  const [approvalRejectionInputOption, setApprovalRejectionInputOption] =
    useState<ApprovalRejectionInputOption>({
      approveOrganizationStaff: { value: '', displayName: '' },
      approveOrganizationUnit: { value: '', displayName: '' },
      requestComment: '',
      dialogType: 'approval',
    });
  const [
    approvalRejectionDialogMessageOption,
    setApprovalRejectionDialogMessageOption,
  ] = useState<ApprovalRejectionDialogMessageOption>({
    headerLabelId: { id: 'approval', prefixId: 'DIALOG_TITLE' },
    messageLabelId: { id: 'approval', prefixId: 'DIALOG_DESCRIPTION' },
  });

  const { pathname } = useLocation();

  const getViewId = (pathname: string): ViewId => {
    switch (pathname) {
      case '/work-task/wk-worktask-list':
        return 'WT_WORKTASK_LIST';
      case '/work-task/wk-worktask-approval-list':
        return 'WT_WORKTASK_APPROVAL_LIST';
      default:
        return '';
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const viewId = getViewId(pathname);
        const filter: InitialFilter = {
          info: [],
        };

        // 代表所属組織
        const userOrganization =
          await window.App.services.ui.getUserRepresentativeOrganization(
            myEmail
          );

        const filterExpression: FilterExpression = {
          'user.userId': { $eq: myUserId },
        };
        const loginUser = await window.App.services.ui.listUserAttributes(
          filterExpression
        );

        // ログイン者の所属組織リスト
        const userOrganizations =
          await window.App.services.ui.getUserOrganization(myUserId);

        setUserOrganizationIds(
          userOrganizations.map((v) => v.organizationId ?? '')
        );

        if (viewId === 'WT_WORKTASK_LIST') {
          userOrganization?.organizationId &&
            filter.info.push({
              targetKey:
                'managementOrganization.parentOrganization.organizationId',
              targetValue: userOrganization.organizationId ?? '',
            });
        } else {
          for (const { user } of loginUser) {
            user?.displayName &&
              filter.info.push({
                targetKey: 'approvalRequest.approvalPlanStaff.email',
                targetValue: user.email ?? '',
              });
          }
        }
        setInitialFilter(filter);

        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(viewId, [FullMethodName_ListWorkTasks]);

        const jaColumn: string[] = ['status.displayNameLang'];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const dateColumn = [
          'worktaskDueDateDt',
          'worktaskTicketDueDateDt',
          'worktaskTicketScheduledDateDt',
        ];
        const dateSch = getDayFormetterDisplaySchema(formatterSch, dateColumn, {
          dayOpts: {
            isAccuracy: true,
            formatType: 'YYYY/MM/DD',
          },
        });

        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(dateSch);
        setPreset(preset);
        setViewId(viewId);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
    // 下記変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, intl]);

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [VIEW_ID, myEmail]);

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 追加
    menuItems.push({
      name: 'noteadd',
      func: () => handleMenuInput('add'),
    });
    // 複写
    menuItems.push({
      name: 'copy',
      func: (v?: string[]) => {
        if (v && v.length > 1) {
          error([GetMessageWithIntl(intl, { id: 'E0000073' })]);
          return;
        }
        handleMenuInput('copy', v);
      },
    });
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    // ダウンロード
    menuItems.push({
      name: 'export',
      func: (v?: string[]) => {
        selectedIds.current = v as string[];
        setOutputOpen(true);
      },
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        selectedIds.current = v as string[];
        checkWorkTask();
      },
    });

    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 承認
    menuItems.push({
      name: 'approval',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        selectedIds.current = v as string[];
        const target = workTaskList.current.filter((item) =>
          selectedIds.current.includes(item.workTaskId)
        )[0];
        if (!target) {
          error([unselectedMessage]);
          return;
        }
        setDecline(false);
        setApprovalRejectionInputOption({
          approveOrganizationStaff: convertUserReferenceToFilterboxItem(
            target.approvalRequest?.approvalPlanStaff
          ) ?? { value: '', displayName: '' },
          approveOrganizationUnit:
            convertOrganizationStructureReferenceToFilterboxItem(
              target.approvalRequest?.approvalPlanOrganization
            ) ?? { value: '', displayName: '' },
          requestComment: target.approvalRequest?.requestedComment ?? '',
          dialogType: 'approval',
        });
        setApprovalRejectionDialogMessageOption({
          headerLabelId: { id: 'approval', prefixId: 'DIALOG_TITLE' },
          messageLabelId: { id: 'approval', prefixId: 'DIALOG_DESCRIPTION' },
        });
        setShowApprovalRejectionDialog(true);
      },
    });
    // 却下
    menuItems.push({
      name: 'decline',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        selectedIds.current = v as string[];
        const target = workTaskList.current.filter((item) =>
          selectedIds.current.includes(item.workTaskId)
        )[0];
        if (!target) {
          error([unselectedMessage]);
          return;
        }
        setDecline(true);
        setApprovalRejectionInputOption({
          approveOrganizationStaff: convertUserReferenceToFilterboxItem(
            target.approvalRequest?.approvalPlanStaff
          ) ?? { value: '', displayName: '' },
          approveOrganizationUnit:
            convertOrganizationStructureReferenceToFilterboxItem(
              target.approvalRequest?.approvalPlanOrganization
            ) ?? { value: '', displayName: '' },
          requestComment: target.approvalRequest?.requestedComment ?? '',
          dialogType: 'rejection',
        });
        setApprovalRejectionDialogMessageOption({
          headerLabelId: { id: 'rejection', prefixId: 'DIALOG_TITLE' },
          messageLabelId: { id: 'rejection', prefixId: 'DIALOG_DESCRIPTION' },
        });
        setShowApprovalRejectionDialog(true);
      },
    });
    return menuItems;
  };

  const handleMenuInput = (actionType: actionType, prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      actionType: actionType,
    };
    navi('/work-task/wt-worktask-input', { state });
  };

  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/work-task/wt-worktask-confirmation', {
      state,
    });
  };

  const handleDownload = async (result: OutputDialogOutput) => {
    setLoading(true);
    let ids: string[] = [];

    if (result.outputVolume === 'selectedOnly') {
      if (selectedIds.current.length < 1) {
        error([unselectedMessage]);
        setOutputOpen(false);
        setLoading(false);
        return;
      }
      ids = ids.concat(selectedIds.current);
    } else {
      workTaskAllList.current?.map((v) => {
        ids.push(v.workTaskId);
      });
    }

    try {
      const fileFormat = await window.App.services.ui.getFileFormat(
        'workTask',
        intl
      );
      const res = await window.App.services.workTaskService.exportWorkTasks({
        ids: ids,
        fileFormat: fileFormat,
      });
      if (res) {
        if (!res?.assetId) {
          success([notFoundOutputMessage]);
        } else {
          clearCheckBox();
          success([toastSuccess]);
          autoDownloadFileWithDate(
            GetMessageWithIntl(intl, {
              viewId: VIEW_ID,
              id: 'exportWorkTask',
            }),
            'csv',
            res?.assetId ?? ''
          );
        }
        setReload(true);
      }
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
      setOutputOpen(false);
    }
  };

  const checkWorkTask = () => {
    if (selectedIds.current.length > 1) {
      error([excessTargetMessage]);
      return;
    }

    const selectItems = workTaskList.current.filter((item) =>
      selectedIds.current.includes(item.workTaskId)
    );

    const selectItem = selectItems[0];
    if (
      !userOrganizationIds.some(
        (v) => v === selectItem.managementOrganization?.organizationId
      )
    ) {
      error([managementCaseMessage]);
      return;
    }

    switch (selectItem.status?.systemName) {
      case 'B00':
      case 'B02':
        setDeleteOpen(true);
        break;
      case 'B04':
      case 'B05':
        setShowDiscard(true);
        break;
      default:
        error([statusMessage]);
        return;
    }
  };

  const handleDelete = async () => {
    const targetDeleteItems = workTaskList.current.filter((item) =>
      selectedIds.current.includes(item.workTaskId || '')
    );
    const deleteItems = targetDeleteItems.map((item) => {
      return item ?? {};
    });
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteWorkTask',
        request: deleteItems[0],
      });
      clearCheckBox();
      success([toastSuccess]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoading(false);
      setDeleteOpen(false);
    }
  };

  const handleDiscard = async () => {
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'discardWorkTask',
        request: {
          workTaskId: selectedIds.current[0],
          comment: discardComment,
        },
      });
      clearCheckBox();
      success([toastSuccess]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
      setShowDiscard(false);
    }
  };

  const handleApproveRejection = async (result: string) => {
    const target = workTaskList.current.filter((item) =>
      selectedIds.current.includes(item.workTaskId)
    )[0];
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'execWorkTaskApproveResult',
        request: {
          workTaskId: target.workTaskId,
          comment: result,
          decline: decline,
        },
      });

      if (!decline) {
        await window.App.services.ui.worker.apiCall({
          actionName: 'activateWorkTask',
          request: {
            workTaskId: target.workTaskId,
          },
        });
      }

      clearCheckBox();
      success([toastSuccess]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setShowApprovalRejectionDialog(false);
      setLoading(false);
    }
  };

  const onOriginalItem = (items: unknown) => {
    workTaskList.current = items as unknown as Worktask[];
  };

  const onAllItem = (items: unknown) => {
    workTaskAllList.current = items as unknown as Worktask[];
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="WorkTaskList">
          <ListView
            pageInfo={{
              schema: schema,
              preset: preset,
              menuItem: setMenuActionItem(),
              menuTarget: 'workTaskId',
              headerTitle: { viewId: VIEW_ID, prefixId: 'header' },
              presetItems: childrenPresetItem,
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            isReload={isReload}
            fullMethodName={FullMethodName_ListWorkTasks}
            stateOption={{
              onOriginalItemState: onOriginalItem,
              onAllItemState: onAllItem,
            }}
            filterItemOption={{
              isRequestBodyFilter: true,
              initialFilterItems: initialFilter,
            }}
          />
          <ConfirmationDialog
            isOpen={isDeleteOpen}
            viewMessage={deleteMessage}
            messageLabelId={{ prefixId: 'DIALOG_MESSAGE', viewId: VIEW_ID }}
            onDecision={() => {
              handleDelete();
            }}
            onCancel={() => setDeleteOpen(false)}
          />
          <CommentDialog
            isOpen={isShowDiscard}
            inputOption={{ comment: discardComment }}
            inputStateOption={{ onChangeComment: setDiscardComment }}
            messageOption={{
              headerLabelId: {
                id: 'survey_discard',
                prefixId: 'DIALOG_TITLE',
              },
              messageLabelId: {
                id: 'survey_discard',
                prefixId: 'DIALOG_DESCRIPTION',
              },
              decisionLabelId: { id: 'decision' },
            }}
            onDecision={handleDiscard}
            onCancel={() => {
              setShowDiscard(false);
            }}
          />
          <OutputDialog
            type="workTask"
            isOpen={isOutputOpen}
            onDecision={handleDownload}
            onCancel={() => {
              setOutputOpen(false);
            }}
          />
          <ApprovalRejectionDialog
            isOpen={isShowApprovalRejectionDialog}
            inputOption={approvalRejectionInputOption}
            onDecision={(v) => {
              handleApproveRejection(v);
            }}
            onCancel={() => setShowApprovalRejectionDialog(false)}
            messageOption={approvalRejectionDialogMessageOption}
          />
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
