import { useState, useEffect, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { ViewId, getWorkerExceptionMessage } from '~/shared/utils';
import {
  ContactRequestDialog,
  ContactRequestDialogInputOption,
  ContactRequestResult,
} from '~/shared/components/ui/Dialog/ContactRequestDialog';
import {
  ContactRegistrationDialog,
  ContactRegistrationDialogInputOption,
  ContactRegistrationResult,
} from '~/shared/components/ui/Dialog/ContactRegistrationDialog';
import {
  ContactAttributeSettingDialog,
  ContactAttributeSettingDialogInputOption,
  ContactAttributeSettingResult,
} from '~/shared/components/ui/Dialog/ContactAttributeSettingDialog';
import {
  SimpleIconListView,
  IconMenuItem,
} from '~/shared/components/ui/SimpleListView';
import { CaptionButton } from '~/shared/components/ui/Button';
import { success, error, GetMessageWithIntl } from '~/shared/components';
import { isActiveCompany } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/utils/conditions';
import {
  CommentDialog,
  MainCOntactSettingDialogResult,
  MainContactSettingDialog,
} from '~/shared/components/ui';
import { useConfirmation } from '~/shared/hooks';

interface ContactTabItemProps {
  businessUnitContactContents?: mtechnavi.api.company.IBusinessUnitContactContent[];
  businessUnitManagementContent?: mtechnavi.api.company.IBusinessUnitManagementContent;
  myCompanyInfo?: mtechnavi.api.company.ICompany;
  isNiterraBusiness: boolean;
  onChangeLoadingState: (arg: boolean) => void;
  handleReload: () => void;
}

interface ViewContactType {
  contactId: string; // Key businessUnitContactHeaderId/businessUnitContactId
  mainContact: string; // businessUnitContact.mainContact id it's true, display '◯'
  code: string; // businessUnitContactAttribute.code
  displayName: string; // businessUnitContactHeader.displayName / businessUnitContact.displayName 連絡先名
  notificationUsers: string; // businessUnitContact.notificationUsers[].displayName {1},{2}... 相手連絡先、自社担当
  status: string; // status.displayName
  insideName: string; // businessUnitContactAttribute.displayName 社内管理名
}
const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const contactReceiveColumns = [
  {
    header: {
      id: 'contact.receive.mainContact',
      prefixId: VIEW_ID,
    },
    propertyName: 'mainContact',
    width: '6rem',
  },
  {
    header: {
      id: 'contact.receive.code',
      prefixId: VIEW_ID,
    },
    propertyName: 'code',
    width: '10rem',
  },
  {
    header: {
      id: 'contact.receive.displayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'displayName',
    width: '12rem',
  },
  {
    header: {
      id: 'contact.receive.notificationUsers',
      prefixId: VIEW_ID,
    },
    propertyName: 'notificationUsers',
    width: '15rem',
  },
  {
    header: {
      id: 'contact.receive.status',
      prefixId: VIEW_ID,
    },
    propertyName: 'status',
    width: '10rem',
  },
  {
    header: {
      id: 'contact.receive.insideName',
      prefixId: VIEW_ID,
    },
    propertyName: 'insideName',
    width: '12rem',
  },
];

const contactSendColumns = [
  {
    header: {
      id: 'contact.send.mainContact',
      prefixId: VIEW_ID,
    },
    propertyName: 'mainContact',
    width: '6rem',
  },
  {
    header: {
      id: 'contact.send.code',
      prefixId: VIEW_ID,
    },
    propertyName: 'code',
    width: '10rem',
  },
  {
    header: {
      id: 'contact.send.displayName',
      prefixId: VIEW_ID,
    },
    propertyName: 'displayName',
    width: '12rem',
  },
  {
    header: {
      id: 'contact.send.notificationUsers',
      prefixId: VIEW_ID,
    },
    propertyName: 'notificationUsers',
    width: '15rem',
  },
  {
    header: {
      id: 'contact.send.status',
      prefixId: VIEW_ID,
    },
    propertyName: 'status',
    width: '10rem',
  },
];

export const ContactTabItem = ({
  businessUnitContactContents,
  businessUnitManagementContent,
  myCompanyInfo,
  isNiterraBusiness,
  onChangeLoadingState,
  handleReload,
}: ContactTabItemProps) => {
  const intl = useIntl();
  // 表示項目制御
  // 連絡先タブ
  // 相手先の連絡先
  const [contactReceiveItems, setContactReceiveItems] = useState<
    ViewContactType[]
  >([]);
  const [contactRequestFormItems, setContactRequestFormItems] =
    useState<ContactRequestDialogInputOption>();
  const [contactConfirmationFormItems, setContactConfirmationFormItems] =
    useState<ContactRegistrationDialogInputOption>();
  const [
    contactAttributeReceiveFormItems,
    setContactAttributeReceiveFormItems,
  ] = useState<ContactAttributeSettingDialogInputOption>();
  // 自社の連絡先
  const [contactSendItems, setContactSendItems] = useState<ViewContactType[]>(
    []
  );
  const [contactRegistrationFormItems, setContactRegistrationFormItems] =
    useState<ContactRegistrationDialogInputOption>();

  // 確認ダイアログ
  const { confirmation, confirmationElement } = useConfirmation();
  // 連絡先入力依頼ダイアログ
  const [isContactRequestDialogOpen, setContactRequestDialogOpen] =
    useState(false);
  // 連絡先確認ダイアログ
  const [isContactConfirmationDialogOpen, setContactConfirmationDialogOpen] =
    useState(false);
  // 自社情報設定（相手連絡先）ダイアログ
  const [
    isContactAttributeSettingReceiveDialogOpen,
    setContactAttributeSettingReceiveDialogOpen,
  ] = useState(false);

  // 自社連絡先登録ダイアログ
  const [isContactRegistrationDialogOpen, setContactRegistrationDialogOpen] =
    useState(false);

  // 代表連絡先設定ダイアログ
  const [isMainContactSettingDialogOpen, setMainCOntactSettingDialogOpen] =
    useState(false);
  const listBusinessUnitContacts = useRef<
    mtechnavi.api.company.IBusinessUnitContact[]
  >([]);
  const currentMainContact = useRef<string>('');

  // 項目表示制御
  const isVisibleEditContactHeader = isActiveCompany(
    businessUnitManagementContent?.notificationStatus?.systemName
  );

  // メッセージ
  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });

  const deleteMessage = {
    id: 'C0000001',
    value: { $1: GetMessageWithIntl(intl, { id: 'delete' }) },
  };
  // 削除メッセージ
  const deleteMessageWithComment = {
    id: 'C0000004',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };
  // 代表連絡先設定確認ダイアログメッセージ
  const saveMessage = {
    id: 'C0000004',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };
  // 削除確認コメント付きダイアログ
  const [
    isDeleteConfirmDialogWithCommentOpen,
    setDeleteConfirmDialogWithCommentOpen,
  ] = useState(false);
  const [contactDeleteComment, setContactDeleteComment] = useState('');
  const selectedId = useRef('');
  const mainContactSettingDialogResult = useRef<MainCOntactSettingDialogResult>(
    {
      mainContact: {
        value: '',
        displayName: '',
      },
    }
  );

  // 連絡先（相手先の連絡先アイコンメニュー）
  const getContactReceiveIconMenuItem = (): IconMenuItem[] => {
    const iconItems: IconMenuItem[] = [];
    if (!isNiterraBusiness && isVisibleEditContactHeader) {
      iconItems.push({
        name: 'mail',
        displayName: '依頼',
        func: (v: ViewContactType) => {
          handleContactRequestDialog(v);
        },
      });
    }
    iconItems.push({
      name: 'description',
      displayName: '確認',
      func: (v: ViewContactType) => {
        handleContactConfirmationDialog(v);
      },
    });
    iconItems.push({
      name: 'settings',
      displayName: '設定',
      func: (v: ViewContactType) => {
        handleContactAttributeSettingReceiveDialog(v);
      },
    });

    return iconItems;
  };

  // 連絡先（自社の連絡先アイコンメニュー）
  const getContactSendIconMenuItem = (): IconMenuItem[] => {
    const iconItems: IconMenuItem[] = [];
    iconItems.push({
      name: 'edit',
      displayName: '編集',
      func: (v: ViewContactType) => {
        handleContactRegistrationDialog(v);
      },
    });
    iconItems.push({
      name: 'delete',
      displayName: '削除',
      func: async (v: ViewContactType) => {
        if (isNotDeletableContact(v.contactId)) {
          return;
        }
        selectedId.current = v.contactId;
        if (businessUnitManagementContent?.tenantStatus?.systemName === 'B03') {
          setDeleteConfirmDialogWithCommentOpen(true);
        } else {
          if (!(await confirmation(deleteMessage))) {
            return;
          } else {
            handleContactDelete();
          }
        }
      },
    });

    return iconItems;
  };

  // 削除可能な連絡先かエラーチェック
  const isNotDeletableContact = (id: string) => {
    const targetContact = listBusinessUnitContacts.current.find(
      (v) => v.businessUnitContactId === id
    );
    if (targetContact?.mainContact) {
      error([GetMessageWithIntl(intl, { id: 'E0000152' })]);
      return true;
    }
    if (
      businessUnitContactContents?.find(
        (v) =>
          v.businessUnitContact?.businessUnitContactId ===
          targetContact?.businessUnitContactId
      )?.businessUnitContactHeader
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000152' })]);
      return true;
    }
    return false;
  };

  // [実行処理]連絡先入力依頼ダイアログ
  const handleContactRequestDialog = (v?: ViewContactType) => {
    setContactRequestFormItems(undefined);
    if (v) {
      const formItem: mtechnavi.api.company.IBusinessUnitContactContent =
        businessUnitContactContents?.find(
          (w) =>
            w.businessUnitContactHeader?.businessUnitContactHeaderId ===
              v?.contactId ||
            w.businessUnitContact?.businessUnitContactId === v?.contactId
        ) ?? {};
      if (formItem) {
        setContactRequestFormItems({
          id: v.contactId ?? '',
          displayName:
            formItem.businessUnitContactHeader?.displayName ??
            formItem.businessUnitContact?.displayName ??
            '',
        });
      }
    }
    setContactRequestDialogOpen(true);
  };

  // [実行処理]保存/送信 連絡先入力依頼
  const handleSaveContactRequest = async (v: ContactRequestResult) => {
    onParentLoadingState(true);
    try {
      let target: mtechnavi.api.company.IBusinessUnitContactContent = {};
      if (v?.id) {
        target =
          businessUnitContactContents?.find(
            (w) =>
              w.businessUnitContactHeader?.businessUnitContactHeaderId ===
                v?.id || w.businessUnitContact?.businessUnitContactId === v?.id
          ) ?? {};
      }

      const request: mtechnavi.api.company.IBusinessUnitContactHeader = {
        businessUnitContactHeaderId:
          target.businessUnitContactHeader?.businessUnitContactHeaderId,
        businessUnitManagementId:
          businessUnitManagementContent?.businessUnitManagement
            ?.businessUnitManagementId ?? '',
        companyId: myCompanyInfo?.companyId ?? '',
        displayName: v.displayName ?? '',
        documentRecipientAddress: v.requestTargetCheck.some(
          (w) => w === 'documentRecipientAddress'
        ),
        notificationUser: v.requestTargetCheck.some(
          (w) => w === 'notificationUser'
        ),
        requestProcess: { processedComment: v.comment },
        updatedAt: target.businessUnitContactHeader?.updatedAt,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execBusinessUnitContactHeader',
        request: {
          businessUnitContactHeader: request,
          businessUnitContactId: v?.id ?? '',
        },
      });

      setContactRequestDialogOpen(false);
      success([successMessage]);

      //リロード(連絡先情報のみ)
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]連絡先確認ダイアログ
  const handleContactConfirmationDialog = (v: ViewContactType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitContactContent =
      businessUnitContactContents?.find(
        (w) =>
          w.businessUnitContactHeader?.businessUnitContactHeaderId ===
            v?.contactId ||
          w.businessUnitContact?.businessUnitContactId === v?.contactId
      ) ?? {};
    if (formItem) {
      setContactConfirmationFormItems({
        displayModeType: 'display',
        isActiveTenant:
          businessUnitManagementContent?.tenantStatus?.systemName === 'B03' &&
          businessUnitManagementContent?.notificationStatus?.systemName ===
            'B00'
            ? true
            : false,
        companyId: myCompanyInfo?.companyId ?? '',
        requestDocumentRecipientAddress:
          formItem.businessUnitContactHeader?.documentRecipientAddress ?? false,
        requestNotificationUser:
          formItem.businessUnitContactHeader?.notificationUser ?? false,
        id: formItem.businessUnitContact?.businessUnitContactId ?? '',
        displayName:
          formItem.businessUnitContact?.displayName ??
          formItem.businessUnitContactHeader?.displayName ??
          '',
        businessUnitBranchProperties:
          formItem.businessUnitContact?.businessUnitBranchProperties ?? {},
        alias: formItem.businessUnitContact?.alias ?? '',
        notificationUsers:
          formItem.businessUnitContact?.notificationUsers ?? [],
        comment: '',
        businessUnitContactAttribute:
          formItem.businessUnitContactAttribute ?? {},
      });
    } else {
      setContactConfirmationFormItems({
        displayModeType: 'display',
        isActiveTenant:
          businessUnitManagementContent?.tenantStatus?.systemName === 'B03' &&
          businessUnitManagementContent?.notificationStatus?.systemName ===
            'B00'
            ? true
            : false,
        companyId: myCompanyInfo?.companyId ?? '',
      });
    }
    setContactConfirmationDialogOpen(true);
  };

  // [実行処理]自社情報設定（相手連絡先）ダイアログ
  const handleContactAttributeSettingReceiveDialog = (v: ViewContactType) => {
    const formItem: mtechnavi.api.company.IBusinessUnitContactContent =
      businessUnitContactContents?.find(
        (w) =>
          w.businessUnitContactHeader?.businessUnitContactHeaderId ===
            v?.contactId ||
          w.businessUnitContact?.businessUnitContactId === v?.contactId
      ) ?? {};
    if (formItem) {
      setContactAttributeReceiveFormItems({
        id: v?.contactId ?? '',
        code: formItem.businessUnitContactAttribute?.code ?? '',
        displayName: formItem.businessUnitContactAttribute?.displayName ?? '',
        remarks: formItem.businessUnitContactAttribute?.remarks ?? '',
      });
    } else {
      setContactAttributeReceiveFormItems(undefined);
    }
    setContactAttributeSettingReceiveDialogOpen(true);
  };

  // [共通処理]保存 自社情報設定
  const saveContactAttribute = async (v: ContactAttributeSettingResult) => {
    let target: mtechnavi.api.company.IBusinessUnitContactContent = {};
    if (v?.id) {
      target =
        businessUnitContactContents?.find(
          (w) =>
            w.businessUnitContactHeader?.businessUnitContactHeaderId ===
              v?.id || w.businessUnitContact?.businessUnitContactId === v?.id
        ) ?? {};
    }

    let typeName = '';
    let recordId = '';
    if (target.businessUnitContactHeader?.businessUnitContactHeaderId) {
      typeName = 'mtechnavi.api.company.BusinessUnitContactHeader';
      recordId = target.businessUnitContactHeader?.businessUnitContactHeaderId;
    } else {
      if (target.businessUnitContact?.businessUnitContactId) {
        typeName = 'mtechnavi.api.company.BusinessUnitContact';
        recordId = target.businessUnitContact?.businessUnitContactId;
      }
    }

    const request: mtechnavi.api.company.IBusinessUnitContactAttribute = {
      businessUnitContactAttributeId:
        target.businessUnitContactAttribute?.businessUnitContactAttributeId,
      businessUnitManagementId:
        businessUnitManagementContent?.businessUnitManagement
          ?.businessUnitManagementId,
      typeName: typeName,
      recordId: recordId,
      code: v.code,
      displayName: v.displayName,
      remarks: v.remarks,
      updatedAt: target.businessUnitContactAttribute?.updatedAt,
    };

    await window.App.services.ui.worker.apiCall({
      actionName: 'saveBusinessUnitContactAttribute',
      request: request,
    });
  };

  // [実行処理]保存 自社情報設定（相手連絡先）
  const handleSaveContactAttributeReceive = async (
    v: ContactAttributeSettingResult
  ) => {
    onParentLoadingState(true);
    try {
      // [共通処理]保存 自社情報設定
      await saveContactAttribute(v);
      setContactAttributeSettingReceiveDialogOpen(false);
      success([successMessage]);

      //リロード(連絡先情報のみ)
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]連絡先登録ダイアログ
  const handleContactRegistrationDialog = (v?: ViewContactType) => {
    setContactRegistrationFormItems({
      displayModeType: 'input',
      isActiveTenant:
        businessUnitManagementContent?.tenantStatus?.systemName === 'B03'
          ? true
          : false,
      companyId: myCompanyInfo?.companyId ?? '',
    });
    if (v) {
      const formItem: mtechnavi.api.company.IBusinessUnitContactContent =
        businessUnitContactContents?.find(
          (w) =>
            w.businessUnitContactHeader?.businessUnitContactHeaderId ===
              v?.contactId ||
            w.businessUnitContact?.businessUnitContactId === v?.contactId
        ) ?? {};
      if (formItem) {
        setContactRegistrationFormItems({
          displayModeType: 'edit',
          isActiveTenant:
            businessUnitManagementContent?.tenantStatus?.systemName === 'B03'
              ? true
              : false,
          companyId: myCompanyInfo?.companyId ?? '',
          requestDocumentRecipientAddress:
            formItem.businessUnitContactHeader?.documentRecipientAddress ??
            false,
          requestNotificationUser:
            formItem.businessUnitContactHeader?.notificationUser ?? false,
          id: v?.contactId ?? '',
          displayName:
            formItem.businessUnitContact?.displayName ??
            formItem.businessUnitContactHeader?.displayName ??
            '',
          businessUnitBranchProperties:
            formItem.businessUnitContact?.businessUnitBranchProperties ?? {},
          alias: formItem.businessUnitContact?.alias ?? '',
          notificationUsers:
            formItem.businessUnitContact?.notificationUsers ?? [],
          comment: '',
          businessUnitContactAttribute:
            formItem.businessUnitContactAttribute ?? {},
        });
      }
    }
    setContactRegistrationDialogOpen(true);
  };

  // [実行処理]保存/送信 連絡先
  const handleSaveContact = async (v: ContactRegistrationResult) => {
    onParentLoadingState(true);
    try {
      let target: mtechnavi.api.company.IBusinessUnitContactContent = {};
      if (v?.id) {
        target =
          businessUnitContactContents?.find(
            (w) =>
              w.businessUnitContactHeader?.businessUnitContactHeaderId ===
                v?.id || w.businessUnitContact?.businessUnitContactId === v?.id
          ) ?? {};
      }
      const request: mtechnavi.api.company.IBusinessUnitContact = {
        businessUnitContactId:
          target.businessUnitContact?.businessUnitContactId,
        businessUnitManagementId:
          businessUnitManagementContent?.businessUnitManagement
            ?.businessUnitManagementId ?? '',
        companyId: myCompanyInfo?.companyId ?? '',
        displayName: v.displayName ?? '',
        businessUnitBranchProperties: v.businessUnitBranchProperties,
        alias: v.alias ?? '',
        notificationUsers: v.notificationUsers,
        sendProcess: { processedComment: v.comment },
        updatedAt: target.businessUnitContact?.updatedAt,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execBusinessUnitContact',
        request: {
          tenantStatus: businessUnitManagementContent?.tenantStatus ?? {},
          activeStatus: businessUnitManagementContent?.notificationStatus ?? {},
          businessUnitContact: request,
          businessUnitContactHeaderId: v?.id ?? '',
        },
      });

      setContactRegistrationDialogOpen(false);
      success([successMessage]);

      //リロード(連絡先情報のみ)
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]保存 自社情報設定（自社連絡先）
  const handleSaveContactAttributeSend = async (
    v: ContactAttributeSettingResult
  ) => {
    onParentLoadingState(true);
    try {
      // [共通処理]保存 自社情報設定
      await saveContactAttribute(v);
      success([successMessage]);

      //リロード(連絡先情報のみ)
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]削除 自社の連絡先を削除
  const handleContactDelete = async () => {
    onParentLoadingState(true);
    const request = listBusinessUnitContacts.current.find(
      (v) => v.businessUnitContactId === selectedId.current
    );
    if (!request) {
      return;
    }
    const sendProcess: mtechnavi.api.company.IProcess = {
      ...request.sendProcess,
      processedComment: contactDeleteComment,
    };
    if (contactDeleteComment) {
      request.sendProcess = sendProcess;
    }

    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBusinessUnitContact',
        request: {
          tenantStatus: businessUnitManagementContent?.tenantStatus ?? {},
          businessUnitContact: request,
        },
      });
      success([successMessage]);
      setDeleteConfirmDialogWithCommentOpen(false);
      setContactDeleteComment('');
      //リロード(連絡先情報のみ)
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]（自社）代表連絡先の保存
  const handleSaveMainContact = async () => {
    onParentLoadingState(true);
    const targetContact = listBusinessUnitContacts.current.find(
      (v) =>
        v.businessUnitContactId ===
        mainContactSettingDialogResult.current.mainContact.value
    );
    if (!targetContact?.businessUnitContactId) {
      return;
    }
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'activateMainContactInBusinessUnitContact',
        request: {
          tenantStatus: businessUnitManagementContent?.tenantStatus ?? {},
          businessUnitContactId: targetContact.businessUnitContactId,
        },
      });
      success([successMessage]);
      setMainCOntactSettingDialogOpen(false);
      //リロード(連絡先情報のみ)
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      onParentLoadingState(false);
    }
  };

  // 連絡先情報変更時
  useEffect(() => {
    if (!businessUnitContactContents) {
      setContactReceiveItems([]);
      setContactSendItems([]);
      return;
    }
    const receiveMainContactVals: ViewContactType[] = [];
    const sendMainContactVals: ViewContactType[] = [];
    const receiveVals: ViewContactType[] = [];
    const sendVals: ViewContactType[] = [];
    businessUnitContactContents.map((v) => {
      const notificationUserDisplayNames: string[] = [];
      if ((v.businessUnitContact?.notificationUsers ?? []).length > 0) {
        v.businessUnitContact?.notificationUsers?.map((w) =>
          notificationUserDisplayNames.push(w.user?.displayName ?? '')
        );
      }
      const val: ViewContactType = {
        contactId:
          v.businessUnitContactHeader?.businessUnitContactHeaderId ??
          v.businessUnitContact?.businessUnitContactId ??
          '',
        mainContact: v.businessUnitContact?.mainContact ? '◯' : '',
        code: v.businessUnitContactAttribute?.code ?? '',
        displayName:
          v.businessUnitContact?.displayName ??
          v.businessUnitContactHeader?.displayName ??
          '',
        notificationUsers: notificationUserDisplayNames.join(','),
        status: v.status?.displayNameLang?.ja ?? '',
        insideName: v.businessUnitContactAttribute?.displayName ?? '',
      };

      if (
        v.businessUnitContactHeader?.businessUnitManagementId ||
        (v.businessUnitContact &&
          !v.businessUnitContact?.businessUnitManagementId)
      ) {
        if (v.businessUnitContact?.mainContact === true) {
          receiveMainContactVals.push(val);
        } else {
          receiveVals.push(val);
        }
      } else {
        if (v.businessUnitContact?.mainContact === true) {
          sendMainContactVals.push(val);
        } else {
          sendVals.push(val);
        }
      }
    });

    // 代表連絡先は常に先頭に表示する
    receiveMainContactVals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    receiveVals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setContactReceiveItems([...receiveMainContactVals, ...receiveVals]);
    sendMainContactVals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    sendVals.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setContactSendItems([...sendMainContactVals, ...sendVals]);

    // 代表連絡先設定ダイアログ情報セット
    const businessUnitContacts: mtechnavi.api.company.IBusinessUnitContact[] =
      [];
    businessUnitContactContents.map((v) => {
      if (
        v.businessUnitContact &&
        v.businessUnitContact?.businessUnitManagementId
      )
        businessUnitContacts.push(v.businessUnitContact);
    });
    listBusinessUnitContacts.current = businessUnitContacts;
    currentMainContact.current =
      businessUnitContacts.find((contact) => contact.mainContact)
        ?.businessUnitContactId ?? '';

    // businessUnitContactContentsData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitContactContents]);

  const onParentLoadingState = useCallback(
    (arg: boolean) => {
      onChangeLoadingState(arg);
    },
    [onChangeLoadingState]
  );

  return (
    <>
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {GetMessageWithIntl(intl, {
                id: 'contact.receive',
                prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
              })}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-80">
              <div className="contact-list-area">
                <SimpleIconListView
                  data={contactReceiveItems}
                  viewOptions={{
                    previewRowCount: 4,
                    keyColumn: 'contactId',
                    columns: contactReceiveColumns,
                    omitFooter: true,
                  }}
                  actionOptions={{}}
                  iconMenuOptions={{
                    iconMenu: getContactReceiveIconMenuItem(),
                  }}
                />
              </div>
            </div>
            <div className="w-20">
              {!isNiterraBusiness && isVisibleEditContactHeader && (
                <CaptionButton
                  buttonType="basic"
                  caption="新規連絡先"
                  name="addContactBtn"
                  onClick={() => {
                    handleContactRequestDialog(undefined);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {GetMessageWithIntl(intl, {
                id: 'contact.send',
                prefixId: VIEW_ID,
              })}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-80">
              <div className="contact-list-area">
                <SimpleIconListView
                  data={contactSendItems}
                  viewOptions={{
                    previewRowCount: 4,
                    keyColumn: 'contactId',
                    columns: contactSendColumns,
                    omitFooter: true,
                  }}
                  actionOptions={{}}
                  iconMenuOptions={{ iconMenu: getContactSendIconMenuItem() }}
                />
              </div>
            </div>
            <div className="w-20">
              {!isNiterraBusiness && (
                <CaptionButton
                  buttonType="basic"
                  caption="新規連絡先"
                  name="addContactBtn"
                  onClick={() => {
                    handleContactRegistrationDialog(undefined);
                  }}
                />
              )}
              <CaptionButton
                buttonType="basic"
                caption="代表連絡先設定"
                className="setMainContactBtn"
                name="setMainContactBtn"
                onClick={() => {
                  setMainCOntactSettingDialogOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 連絡先入力依頼ダイアログ */}
      <ContactRequestDialog
        isOpen={isContactRequestDialogOpen}
        inputOption={contactRequestFormItems}
        messageOption={{}}
        onCancel={() => {
          setContactRequestDialogOpen(false);
        }}
        onDecision={handleSaveContactRequest}
      />
      {/* 連絡先確認ダイアログ */}
      <ContactRegistrationDialog
        isOpen={isContactConfirmationDialogOpen}
        inputOption={contactConfirmationFormItems}
        saveContactAttribute={async () => {}}
        onCancel={() => {
          setContactConfirmationDialogOpen(false);
        }}
        onDecision={() => {}}
      />
      {/* 自社情報設定（相手連絡先）ダイアログ */}
      <ContactAttributeSettingDialog
        isOpen={isContactAttributeSettingReceiveDialogOpen}
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'contactAttributeSetting.other',
          },
          codeLabelId: {
            prefixId: 'ContactAttributeSettingDialog',
            id: 'code',
          },
        }}
        inputOption={contactAttributeReceiveFormItems}
        onCancel={() => {
          setContactAttributeSettingReceiveDialogOpen(false);
        }}
        onDecision={handleSaveContactAttributeReceive}
      />
      {/* 連絡先登録ダイアログ */}
      <ContactRegistrationDialog
        isOpen={isContactRegistrationDialogOpen}
        inputOption={contactRegistrationFormItems}
        saveContactAttribute={handleSaveContactAttributeSend}
        onCancel={() => {
          setContactRegistrationDialogOpen(false);
        }}
        onDecision={handleSaveContact}
      />
      {/* 代表連絡先設定ダイアログ */}
      <MainContactSettingDialog
        isOpen={isMainContactSettingDialogOpen}
        inputOption={{
          contactList: listBusinessUnitContacts.current,
          initialContactId: currentMainContact.current,
        }}
        onDecision={async (v) => {
          mainContactSettingDialogResult.current = v;
          if (
            businessUnitManagementContent?.tenantStatus?.systemName === 'B03'
          ) {
            if (!(await confirmation(saveMessage))) {
              return;
            } else {
              handleSaveMainContact();
            }
          } else {
            handleSaveMainContact();
          }
        }}
        onCancel={() => setMainCOntactSettingDialogOpen(false)}
      />
      {/* 自社連絡先削除確認 ダイアログ */}
      <CommentDialog
        isOpen={isDeleteConfirmDialogWithCommentOpen}
        inputOption={{
          modeType: 'send',
          butonType: 'high',
          comment: contactDeleteComment,
        }}
        inputStateOption={{ onChangeComment: setContactDeleteComment }}
        messageOption={{
          headerLabelId: {
            id: 'delete',
            prefixId: 'DIALOG_TITLE',
          },
          messageLabelId: deleteMessageWithComment,
          captionLabelId: {
            id: 'comment',
            prefixId: 'SendDialog',
          },
          decisionLabelId: { id: 'send' },
        }}
        onDecision={() => {
          handleContactDelete();
        }}
        onCancel={() => {
          setDeleteConfirmDialogWithCommentOpen(false);
          setContactDeleteComment('');
        }}
      />
      {confirmationElement}
    </>
  );
};
