import { forwardRef, LegacyRef, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { getMessageId, GetMessageWithIntl } from '~/shared/components';
import { Textbox } from '~/shared/components/ui';
import { CaptionButton, IconButton } from '~/shared/components/ui/Button';
import { SegmentConfirmationDialog } from '~/shared/components/ui/Dialog/SegmentConfirmationDialog';
import { ViewId } from '~/shared/utils';
import {
  BlueprintSearchFilter,
  BlueprintSearchCondition,
  BlueprintSearchSort,
  conditionsToLabels,
  BLUEPRINT_SEARCH_LIMIT,
} from '../util';
import { useNavigate } from 'react-router-dom';
import { BlueprintSearchResultItemSize } from './BlueprintSearchResultItem';

interface BlueprintSearchResultHeaderProps {
  viewId: ViewId;
  searchViewId: ViewId;
  resultCount?: number | null;
  condition?: BlueprintSearchCondition | null;
  size?: BlueprintSearchResultItemSize;
  /** 高さが変わる際に呼び出す */
  onResize?: () => void;
  onApplyFilter?: (filter?: BlueprintSearchFilter) => void;
  onApplySort?: (sort?: BlueprintSearchSort) => void;
  onChangeListSize?: (size: BlueprintSearchResultItemSize) => void;
}
export const BlueprintSearchResultHeader = forwardRef(
  (
    {
      viewId,
      searchViewId,
      resultCount,
      condition,
      size,
      onResize,
      onApplyFilter,
      onApplySort,
      onChangeListSize,
    }: BlueprintSearchResultHeaderProps,
    ref: LegacyRef<HTMLElement>
  ) => {
    const intl = useIntl();
    const navi = useNavigate();

    const [isOpenCondition, setOpenCondition] = useState(false);
    const [isShowSegmentConfirmation, setShowSegmentConfirmation] =
      useState(false);
    const [keyword, setKeyword] = useState('');

    const assetId = condition?.file?.temporaryImage?.assetId ?? '';

    const conditionLabels = conditionsToLabels(
      intl,
      searchViewId,
      condition ?? {}
    ).join(',');

    const adjustmentOrderCategories = useMemo(() => {
      const orders: BlueprintSearchSort = [];
      if (condition?.shape?.classNames?.includes('front')) {
        orders.push({ distanceFront: 'desc' });
      }
      if (condition?.shape?.classNames?.includes('right')) {
        orders.push({ distanceRight: 'desc' });
      }
      if (condition?.shape?.classNames?.includes('bottom')) {
        orders.push({ distanceBottom: 'desc' });
      }
      orders.push({ distanceAverage: 'desc' });
      return orders;
    }, [condition]);

    const handleBackPage = () => {
      navi('/blueprint/blueprint-search');
    };
    const handleSegmentConfirmation = () => {
      setShowSegmentConfirmation(true);
    };
    const handleChangeSort = (sort?: BlueprintSearchSort) => {
      onApplySort && onApplySort(sort);
    };
    const handleSearchKeyword = () => {
      onApplyFilter && onApplyFilter({ keyword });
    };
    const handleChangeListSize = (size: BlueprintSearchResultItemSize) => {
      onChangeListSize && onChangeListSize(size);
    };

    return (
      <>
        <section className="BlueprintSearchResultHeader" ref={ref}>
          <div className="condition">
            <IconButton
              className="back-page"
              name="back"
              iconType="back"
              onClick={handleBackPage}
            />
            <div className="condition-detail">
              <div
                className={`conditions ${isOpenCondition ? 'open' : 'close'}`}
              >
                {conditionLabels}
              </div>
              <IconButton
                className="toggle-detail"
                name="toggle-detail"
                iconType={isOpenCondition ? 'up' : 'down'}
                onClick={() => {
                  setOpenCondition((isOpen) => !isOpen);
                  onResize && onResize();
                }}
              />
            </div>
            <CaptionButton
              name="segmentConfirmation"
              buttonType="basic"
              caption={GetMessageWithIntl(intl, {
                viewId,
                id: 'segmentConfirmation',
              })}
              onClick={handleSegmentConfirmation}
              disabled={!assetId}
            />
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {(condition?.shape?.classNames ?? []).length > 0 && (
                <div className="w-60">
                  <span className="bold">
                    {GetMessageWithIntl(intl, {
                      viewId,
                      id: 'adjustmentOrder',
                    })}
                  </span>
                  <div className="adjustment-order-categories">
                    {adjustmentOrderCategories.map((category) => {
                      const [[label]] = Object.entries(category);
                      return (
                        <a
                          key={label}
                          onClick={() => handleChangeSort([category])}
                        >
                          {GetMessageWithIntl(intl, { viewId, id: label })}
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="w-30 search-keyword">
                <Textbox
                  name="keyword"
                  type="text"
                  columns={['keyword']}
                  labelId={getMessageId({ viewId, id: 'keyword' })}
                  onChangeState={setKeyword}
                />
                <IconButton
                  iconType="search"
                  buttonType="basic"
                  name="search"
                  onClick={handleSearchKeyword}
                />
              </div>
            </div>
          </div>
          <div className="result">
            <span className="bold">
              {resultCount !== null && (
                <>
                  {GetMessageWithIntl(intl, {
                    viewId,
                    id:
                      resultCount === BLUEPRINT_SEARCH_LIMIT
                        ? 'resultFloodFormat'
                        : 'resultFormat',
                    value: { $1: resultCount },
                  })}
                </>
              )}
            </span>
            <div className="grid-size">
              <IconButton
                buttonType={size === 'small' ? 'basic' : 'cancel'}
                name="listSmall"
                iconType="small_grid"
                onClick={() => handleChangeListSize('small')}
              />
              <IconButton
                buttonType={size === 'large' ? 'basic' : 'cancel'}
                name="listLarge"
                iconType="large_grid"
                onClick={() => handleChangeListSize('large')}
              />
            </div>
          </div>
        </section>
        <SegmentConfirmationDialog
          isOpen={isShowSegmentConfirmation}
          inputOption={{
            assetId,
            mode: 'confirm',
            segment: {
              imageProperty: {
                imageAssetId: condition?.file?.temporaryImage?.assetId,
                imageHeight: condition?.file?.temporaryImage?.height,
                imageWidth: condition?.file?.temporaryImage?.width,
                thumbnailAssetId: condition?.file?.temporaryImage?.signedUrl,
              },
              segmentPropertys: (condition?.file?.segments ?? []).map(
                (item) => ({ ...item.segmentProperty })
              ),
            },
          }}
          messageOption={{
            headerLabel: {
              prefixId: 'DIALOG_TITLE',
              id: 'SegmentConfirmationDialog',
            },
          }}
          onCancel={() => setShowSegmentConfirmation(false)}
          onDecision={() => setShowSegmentConfirmation(false)}
        />
      </>
    );
  }
);
