import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PageState,
  FullMethodName_ListEstimateManagementSummarys,
  FullMethodName_ListEstimateManagements,
  ViewId,
  getExceptionMessage,
  getWorkerExceptionMessage,
  getPresetAndSchema,
  movePage,
  saveLocalStorageCheckboxData,
  saveInitialLocalStorageData,
  convertLongToNumber,
  convertUserReferenceToFilterboxItem,
  convertOrganizationStructureReferenceToFilterboxItem,
} from '~/shared/utils';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  ApprovalRejectionDialog,
  ApprovalRejectionDialogMessageOption,
  ApprovalRejectionInputOption,
  CommentDialog,
  ConfirmationDialog,
  ListView,
  ViewMenu,
  InitialFilter,
  InitialFilterItem,
  MenuActionItem,
  Preset,
  Property,
  getAltDisplaySchema,
  getDayFormetterDisplaySchema,
  getLongNullZeroSchema,
} from '~/shared/components/ui';
import { AggregateStage } from '~/worker';
import { PresetItem } from '~/shared/services';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import Long from 'long';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { FilterRequest } from '~/worker';
import {
  FullMethodName_ListOrganizations,
  FullMethodName_ListUserAttributes,
} from '~/worker';

type EstimateManagementSummary =
  mtechnavi.api.estimation.EstimateManagementSummary;
const approvalListLookup: AggregateStage[] = [
  {
    // 未承認一覧のフィルタ用に、担当者マスタを紐づける
    $lookup: {
      from: FullMethodName_ListUserAttributes,
      localField: 'approvalPlanStaffId',
      foreignField: 'user.userId',
      as: 'approvalPlanStaff',
      option: {
        useQuery: true,
      },
    },
  },
  {
    // 未承認一覧のフィルタ用に、管理場所マスタを紐づける
    $lookup: {
      from: FullMethodName_ListOrganizations,
      localField: 'approvalPlanOrganizationUnitId',
      foreignField: 'organizationId',
      as: 'approvalPlanOrganizationUnit',
      option: {
        useQuery: true,
      },
    },
  },
];

export const EstEstimateRequestList = () => {
  const listItemRef = useRef<EstimateManagementSummary[]>([]);
  const listAllItemRef = useRef<EstimateManagementSummary[]>([]);
  const [isReload, setReload] = useState(false);
  const { pathname } = useLocation();
  // useMemoを使用しているためwarningが発生するが期待通りのためlintから除外させる
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  const VIEW_ID: ViewId = useMemo(() => {
    switch (pathname) {
      case '/estimate/est-estimate-request-list':
        return 'EST_ESTIMATE_REQUEST_LIST';
      case '/estimate/est-estimate-request-approval-list':
        return 'EST_ESTIMATE_REQUEST_APPROVAL_LIST';
      default:
        return '';
    }
  }, [pathname]);
  const PathToReadMore = useMemo(() => {
    switch (pathname) {
      case '/estimate/est-estimate-request-list':
        return '/estimate/est-estimate-request-detail-list';
      default:
        return '';
    }
  }, [pathname]);

  const [isLoading, setLoading] = useState(false);
  // 確認ダイアログ(削除)
  const [isShowDelete, setShowDelete] = useState(false);
  // 破棄ダイアログ
  const [isShowDiscard, setShowDiscard] = useState(false);
  const [discardComment, setDiscardComment] = useState('');
  // 完了ダイアログ
  const [isShowComplete, setShowComplete] = useState(false);
  const [completeComment, setCompleteComment] = useState('');
  // 操作対象ID
  const [targetId, setTargetId] = useState<string | null>(null);

  const intl = useIntl();
  const navigate = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const [presetViewId, setPresetViewId] = useState<ViewId>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [presetItems, setPresetItems] = useState<PresetItem[]>();

  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const unselectedMessage = GetMessageWithIntl(intl, { id: 'E0000023' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000073' });
  const invalidStatusMessage = GetMessageWithIntl(intl, { id: 'E0000020' });
  const invalidDiscardMessage = GetMessageWithIntl(intl, { id: 'E0000131' });

  // 承認却下ダイアログ
  const [isShowApprovalRejectionDialog, setShowApprovalRejectionDialog] =
    useState(false);
  // 承認却下ダイアログオプション
  const [approvalRejectionInputOption, setApprovalRejectionInputOption] =
    useState<ApprovalRejectionInputOption>({
      approveOrganizationStaff: { value: '', displayName: '' },
      approveOrganizationUnit: { value: '', displayName: '' },
      requestComment: '',
      dialogType: 'approval',
    });
  const [
    approvalRejectionDialogMessageOption,
    setApprovalRejectionDialogMessageOption,
  ] = useState<ApprovalRejectionDialogMessageOption>({
    headerLabelId: { id: 'approval', prefixId: 'DIALOG_TITLE' },
    messageLabelId: { id: 'approval', prefixId: 'DIALOG_DESCRIPTION' },
  });
  // 承認却下判定
  const [decline, setDecline] = useState(false);
  // 初期フィルタ設定
  const [initialFilter, setInitialFilter] = useState<InitialFilter>({
    info: [],
  });

  const naviFilterItems = useMemo(() => {
    if (!sourcePageInfo?.naviFilters) {
      return;
    }
    const naviFilter = sourcePageInfo.naviFilters.reduce(
      (filter, param) => {
        const initialFilterItems: InitialFilterItem[] = param.value.map((v) => {
          return {
            targetKey: param.key,
            targetValue: v,
          };
        });
        filter.info.push(...initialFilterItems);
        return filter;
      },
      { type: 'or', info: [] } as InitialFilter
    );
    if (naviFilter.info.length === 0) {
      return;
    }
    return naviFilter;
    // useMemoを使用しているためwarningが発生するが期待通りのためlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcePageInfo]);

  const getTargetIds = (v: string[]): PageState => {
    const targetIds: string[] = [];
    const targets = listItemRef.current.filter((item) =>
      v.includes(item.estimateManagementSummaryId)
    );
    targets.forEach((item) => {
      targetIds.push(item.estimateManagementSummaryId);
    });
    const state: PageState = {
      ids: targetIds,
      sourceViewId: VIEW_ID,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    return state;
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 作成
    menuItems.push({
      name: 'noteadd',
      func: () => {
        const state: PageState = {
          sourceViewId: VIEW_ID,
          actionType: 'add',
          baseViewOption: { sourceViewId: VIEW_ID },
        };
        navigate('/estimate/est-estimate-request-input', { state });
      },
    });
    // 複写
    menuItems.push({
      name: 'copy',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }
        const state = {
          ...getTargetIds(v),
          actionType: 'copy',
        };
        navigate('/estimate/est-estimate-request-input', { state });
      },
    });
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        const state = getTargetIds(v);
        navigate('/estimate/est-estimate-request-confirmation', {
          state,
        });
      },
    });
    // 破棄 (削除 / 破棄)
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }

        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }
        // 一時保存・公開却下なら削除
        if (
          ['B00', 'B02'].some(
            (systemName) => target.status?.systemName === systemName
          )
        ) {
          setTargetId(v[0]);
          setShowDelete(true);
          return;
        }
        // 未完了なら破棄
        if (
          ['B04'].some((systemName) => target.status?.systemName === systemName)
        ) {
          if (
            (convertLongToNumber(target.countEstimateResult) ?? 0) +
              (convertLongToNumber(target.countEstimateResultDecline) ?? 0) >
            0
          ) {
            error([invalidDiscardMessage]);
            return;
          }
          setTargetId(v[0]);
          setDiscardComment('');
          setShowDiscard(true);
          return;
        }
        // それ以外はエラー
        error([invalidStatusMessage]);
      },
    });
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        const state = getTargetIds(v);
        navigate('/estimate/est-estimate-request-confirmation', {
          state,
        });
      },
    });
    // 詳細一覧
    menuItems.push({
      name: 'read_more',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }

        // 依頼管理詳細一覧の保持しているキャッシュ情報をクリアする
        saveInitialLocalStorageData(
          'EST_ESTIMATE_REQUEST_DETAIL_LIST',
          myEmail
        );

        const state: PageState = {
          sourceViewId: VIEW_ID,
          ids: v ?? [],
          naviFilters: [
            {
              key: 'estimateRequestAutoName',
              value: [
                Long.fromValue(target.estimateRequestAutoName).toString(),
              ],
            },
            {
              key: 'statuss',
              value: getEstimateRequestUnitStatusCodes(target?.status ?? {}),
            },
          ],
        };
        movePage(state, navigate, PathToReadMore);
      },
    });
    return menuItems;
  };

  const getEstimateRequestUnitStatusCodes = (
    estimateRequestManagementStatus: sharelib.INameOption
  ): string[] => {
    if (!estimateRequestManagementStatus.systemName) return [];

    switch (estimateRequestManagementStatus.systemName) {
      case 'B00':
        return ['0'];
      case 'B01':
        return ['1'];
      case 'B02':
        return ['2'];
      case 'B03':
        return ['3'];
      case 'B04':
        return ['4', '5', '6', '7', '8', '9', '10', '11'];
      case 'B05':
        return ['12'];
      case 'B06':
        return ['13'];
      default:
        return [];
    }
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 完了
    menuItems.push({
      name: 'complete',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }

        // 未完了
        if (
          !['B04'].some(
            (systemName) => target.status?.systemName === systemName
          )
        ) {
          error([invalidStatusMessage]);
          return;
        }

        setTargetId(v[0]);
        setShowComplete(true);
      },
    });

    menuItems.push({
      name: 'approval',
      func: async (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }
        setTargetId(v[0]);
        setDecline(false);

        const em = await getEstimateManagement(
          target.estimateManagementSummaryId
        );
        setApprovalRejectionInputOption({
          approveOrganizationStaff: convertUserReferenceToFilterboxItem(
            em.approvalRequest?.approvalPlanStaff
          ) ?? { value: '', displayName: '' },
          approveOrganizationUnit:
            convertOrganizationStructureReferenceToFilterboxItem(
              em.approvalRequest?.approvalPlanOrganization
            ) ?? { value: '', displayName: '' },
          requestComment: em.approvalRequest?.requestedComment ?? '',
          dialogType: 'approval',
          isHighAffect: true,
        });
        setApprovalRejectionDialogMessageOption({
          headerLabelId: { id: 'approval', prefixId: 'DIALOG_TITLE' },
          messageLabelId: { id: 'approval', prefixId: 'DIALOG_DESCRIPTION' },
        });
        setShowApprovalRejectionDialog(true);
      },
    });
    menuItems.push({
      name: 'decline',
      func: async (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }
        setTargetId(v[0]);
        setDecline(true);
        const em = await getEstimateManagement(
          target.estimateManagementSummaryId
        );
        setApprovalRejectionInputOption({
          approveOrganizationStaff: convertUserReferenceToFilterboxItem(
            em.approvalRequest?.approvalPlanStaff
          ) ?? { value: '', displayName: '' },
          approveOrganizationUnit:
            convertOrganizationStructureReferenceToFilterboxItem(
              em.approvalRequest?.approvalPlanOrganization
            ) ?? { value: '', displayName: '' },
          requestComment: em.approvalRequest?.requestedComment ?? '',
          dialogType: 'rejection',
        });
        setApprovalRejectionDialogMessageOption({
          headerLabelId: { id: 'rejection', prefixId: 'DIALOG_TITLE' },
          messageLabelId: { id: 'rejection', prefixId: 'DIALOG_DESCRIPTION' },
        });
        setShowApprovalRejectionDialog(true);
      },
    });
    return menuItems;
  };

  const getRecordData = (
    id: string | null
  ): EstimateManagementSummary | undefined => {
    return listItemRef?.current.find(
      (item) => item.estimateManagementSummaryId === id
    );
  };

  const getEstimateManagement = async (estimateManagementId: string) => {
    try {
      if (!estimateManagementId) return {};
      // 見積管理データを取得
      const estimateManagementOption: FilterRequest = {
        action: 'reload',
        fullMethodName: FullMethodName_ListEstimateManagements,
        filter: {},
        requestBody: {
          estimateManagementIds: [estimateManagementId],
        },
        sort: [],
      };
      const estimateManagementResponse =
        await window.App.services.ui.worker.filter(estimateManagementOption);
      const itemEstimateManagement: mtechnavi.api.estimation.IEstimateManagement =
        estimateManagementResponse.items.length > 0
          ? (estimateManagementResponse
              .items[0] as mtechnavi.api.estimation.IEstimateManagement)
          : {};
      return itemEstimateManagement;
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [VIEW_ID, myEmail]);

  // 完了
  const handleComplete = async () => {
    const target = getRecordData(targetId);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'completeEstimateManagement',
        request: {
          estimateManagementIds: [target.estimateManagementSummaryId],
          comment: completeComment,
        },
      });
      setShowComplete(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 削除
  const handleDelete = async () => {
    const target = getRecordData(targetId);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await Promise.all([
        // 見積管理関連データの削除
        window.App.services.ui.worker.apiCall({
          actionName: 'deleteEstimateManagementWithRelatedItems',
          request:
            // estimateManagementSummaryId は estimateManagementId と同一のため
            [target.estimateManagementSummaryId],
        }),
      ]);

      setShowDelete(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 破棄
  const handleDiscard = async () => {
    const target = getRecordData(targetId);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'discardEstimateManagement',
        request: {
          estimateManagementIds: [target.estimateManagementSummaryId],
          comment: discardComment,
        },
      });
      setShowDiscard(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleApproveRejection = async (result: string) => {
    const target = getRecordData(targetId);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'execEstimateManagementApproveResult',
        request: {
          estimateManagementId: target.estimateManagementSummaryId,
          comment: result,
          decline: decline,
        },
      });
      // 承認の場合、公開処理を行う
      if (!decline) {
        await window.App.services.ui.worker.apiCall({
          actionName: 'sharetoEstimateRequestWithRelatedItems',
          request: {
            estimateManagementId: target.estimateManagementSummaryId,
          },
        });
      }
      setShowApprovalRejectionDialog(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        // preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListEstimateManagementSummarys,
          ]);

        // ja表示のみする処理
        const jaColumn: string[] = ['status.displayNameLang'];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const accuracyDateColumn = ['estimateDeadlineDt'];
        if (VIEW_ID === 'EST_ESTIMATE_REQUEST_LIST') {
          accuracyDateColumn.push('desiredReceiveDt');
        }
        const accuracyDateSch = getDayFormetterDisplaySchema(
          formatterSch,
          accuracyDateColumn,
          {
            dayOpts: {
              formatType: 'YYYY/MM/DD',
              isAccuracy: true,
            },
          }
        );

        const longZeroColumn = [];
        if (VIEW_ID === 'EST_ESTIMATE_REQUEST_LIST') {
          longZeroColumn.push('countEstimateRequest');
          longZeroColumn.push('countEstimateResult');
          longZeroColumn.push('countEstimateResultDecline');
          longZeroColumn.push('countEstimateSelection');
        }
        const longZeroSch = getLongNullZeroSchema(
          accuracyDateSch,
          longZeroColumn
        );

        // 取得した情報をセット
        setPresetItems(childrenPresetItem);
        setSchema(longZeroSch);
        setPreset(preset);
        setPresetViewId(VIEW_ID);

        // 見積依頼登録から遷移した時の場合、データを事前取得しておく（1回のリロードでは表示されないケースがあるため）
        if (
          sourcePageInfo &&
          sourcePageInfo.sourceViewId === 'EST_ESTIMATE_REQUEST_INPUT'
        ) {
          // // 自分のデータを取得する
          const option: FilterRequest = {
            action: 'reload',
            fullMethodName: FullMethodName_ListEstimateManagementSummarys,
            filter: {},
            sort: [],
          };
          option.requestBody = {};
          const tmpData = (await window.App.services.ui.worker.filter(
            option
          )) as mtechnavi.api.estimation.ListEstimateManagementSummarysResponse;
          console.info(
            'estimateManagementSummarys size by list',
            tmpData.items.length
          );
        }
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();

    // 初回とviewID変更時のみ起動させたい処理のためlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VIEW_ID, intl]);

  // 初期フィルタの動的な設定値をセットする
  useEffect(() => {
    (async () => {
      const filter: InitialFilter = {
        info: [],
      };

      // 以降、非同期処理のあとに処理をする
      await null;

      if (VIEW_ID !== 'EST_ESTIMATE_REQUEST_APPROVAL_LIST') {
        filter.info.push({ targetKey: 'issuer.email', targetValue: myEmail });
        setInitialFilter(filter);
        return;
      }
      // 承認担当者: ログインユーザーに紐づく担当者
      filter.info.push({
        targetKey: 'approvalPlanStaff.email',
        targetValue: myEmail,
      });
      setInitialFilter(filter);
    })();
  }, [VIEW_ID, myEmail]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="EstimateManagementSummaryList">
          <ListView
            isReload={isReload}
            fullMethodName={FullMethodName_ListEstimateManagementSummarys}
            pageInfo={{
              preset,
              schema,
              menuItem: setMenuActionItem(),
              menuTarget: 'estimateManagementSummaryId',
              presetItems,
              headerTitle: { viewId: VIEW_ID },
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            filterItemOption={{
              aggregateStages: [
                ...(VIEW_ID === 'EST_ESTIMATE_REQUEST_APPROVAL_LIST'
                  ? approvalListLookup
                  : []),
              ],
              naviFilterItems,
              isRequestBodyFilter: true,
              initialFilterItems: initialFilter,
            }}
            stateOption={{
              onOriginalItemState: (items: unknown[]) =>
                (listItemRef.current = items as EstimateManagementSummary[]),
              onAllItemState: (items: unknown[]) =>
                (listAllItemRef.current = items as EstimateManagementSummary[]),
            }}
          />
          <ConfirmationDialog
            isOpen={isShowDelete}
            viewMessage={{
              id: 'C0000001',
              value: {
                $1: GetMessageWithIntl(intl, {
                  id: 'delete',
                }),
              },
            }}
            onDecision={handleDelete}
            onCancel={() => setShowDelete(false)}
          />
          <CommentDialog
            isOpen={isShowDiscard}
            inputOption={{
              comment: discardComment,
              butonType: 'high',
              modeType: 'send',
            }}
            inputStateOption={{ onChangeComment: setDiscardComment }}
            messageOption={{
              headerLabelId: {
                id: 'estimate_discard',
                prefixId: 'DIALOG_TITLE',
              },
              messageLabelId: {
                id: 'estimate_discard',
                prefixId: 'DIALOG_DESCRIPTION',
              },
              decisionLabelId: {
                id: 'send',
              },
            }}
            onDecision={handleDiscard}
            onCancel={() => {
              setShowDiscard(false);
            }}
          />
          <CommentDialog
            isOpen={isShowComplete}
            inputOption={{
              comment: completeComment,
              butonType: 'high',
              modeType: 'send',
            }}
            inputStateOption={{ onChangeComment: setCompleteComment }}
            messageOption={{
              headerLabelId: {
                id: 'estimate_complete',
                prefixId: 'DIALOG_TITLE',
              },
              messageLabelId: {
                id: 'estimate_complete',
                prefixId: 'DIALOG_DESCRIPTION',
              },
              decisionLabelId: {
                id: 'send',
              },
            }}
            onDecision={handleComplete}
            onCancel={() => {
              setShowComplete(false);
            }}
          />
          <ApprovalRejectionDialog
            isOpen={isShowApprovalRejectionDialog}
            inputOption={approvalRejectionInputOption}
            onDecision={(v) => {
              handleApproveRejection(v);
            }}
            onCancel={() => setShowApprovalRejectionDialog(false)}
            messageOption={approvalRejectionDialogMessageOption}
          />
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
};
