import { ViewId, getWorkerExceptionMessage } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SimpleListView } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { Textbox } from '~/shared/components/ui/Textbox';
import { success, error, GetMessageWithIntl } from '~/shared/components';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
import { isLinkedTenant } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/utils/conditions';
import { useConfirmation } from '~/shared/hooks';

interface ManagementTabItemProps {
  businessUnitManagementContent?: mtechnavi.api.company.IBusinessUnitManagementContent;
  onChangeLoadingState: (arg: boolean) => void;
  handleReload: () => void;
}

interface ViewManagementType {
  id: number; // key (map index)
  itemKey: string; // 項目名
  itemValue: string; // 項目値
  code: string; // 識別コード
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const managementColumns = [
  {
    header: {
      id: 'management.itemKey',
      prefixId: VIEW_ID,
    },
    propertyName: 'itemKey',
    width: '10rem',
  },
  {
    header: {
      id: 'management.itemValue',
      prefixId: VIEW_ID,
    },
    propertyName: 'itemValue',
    width: '10rem',
  },
  {
    header: {
      id: 'management.code',
      prefixId: VIEW_ID,
    },
    propertyName: 'code',
    width: '10rem',
  },
];

export const ManagementTabItem = ({
  businessUnitManagementContent,
  onChangeLoadingState,
  handleReload,
}: ManagementTabItemProps) => {
  const intl = useIntl();
  const [managementItems, setManagementItems] = useState<ViewManagementType[]>(
    []
  );
  const [statusButtonCaption, setStatusButtonCaption] = useState('');
  const [notificationButtonCaption, setNotificationButtonCaption] =
    useState('');

  // 取引停止 確認ダイアログ
  const [isStopBusinessConfirmOpen, setStopBusinessConfirmOpen] =
    useState(false);
  // 取引再開 確認ダイアログ
  const [isStartBusinessConfirmOpen, setStartBusinessConfirmOpen] =
    useState(false);
  // 確認ダイアログ
  const { confirmation, confirmationElement } = useConfirmation();

  // メッセージ
  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const stopBusinessMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'stopBusiness' }),
    },
  };
  const startBusinessMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'restartBusiness' }),
    },
  };
  const stopMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'stop' }),
    },
  };
  const restartMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'restart' }),
    },
  };

  // 項目表示制御
  const isDisabledNotificationButton = !isLinkedTenant(
    businessUnitManagementContent?.tenantStatus?.systemName
  );

  // メインデータ変更時
  useEffect(() => {
    if (!businessUnitManagementContent) {
      return;
    }

    // 管理タブのボタン制御
    switch (
      businessUnitManagementContent.businessUnitManagement?.status?.systemName
    ) {
      case 'B00':
        setStatusButtonCaption(
          GetMessageWithIntl(intl, { id: 'stopBusiness' })
        );
        break;
      case 'B01':
        setStatusButtonCaption(
          GetMessageWithIntl(intl, { id: 'restartBusiness' })
        );
        break;
      default:
        setStatusButtonCaption(
          GetMessageWithIntl(intl, { id: 'restartBusiness' })
        );
    }

    switch (businessUnitManagementContent.notificationStatus?.systemName) {
      case 'B00':
        setNotificationButtonCaption(GetMessageWithIntl(intl, { id: 'stop' }));
        break;
      case 'B01':
        setNotificationButtonCaption(
          GetMessageWithIntl(intl, { id: 'restart' })
        );
        break;
      default:
        setNotificationButtonCaption(
          GetMessageWithIntl(intl, { id: 'restart' })
        );
    }

    // 管理タブの情報セット
    if (
      !businessUnitManagementContent?.businessUnitManagement?.itemValues?.length
    ) {
      setManagementItems([]);
    } else {
      const vals: ViewManagementType[] = [];
      businessUnitManagementContent?.businessUnitManagement?.itemValues.map(
        (v, index) => {
          vals.push({
            id: index,
            itemKey: v.itemKey ?? '',
            itemValue: v.itemValue ?? '',
            code: v.code ?? '',
          });
        }
      );
      vals.sort((val1, val2) => val1.code.localeCompare(val2.code, 'ja'));
      setManagementItems(vals);
    }

    // businessUnitManagementContent 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitManagementContent, intl]);

  // [実行処理]取引停止
  const handleStopBusiness = async () => {
    onParentLoadingState(true);
    try {
      const request: mtechnavi.api.company.IStopBusinessRequest = {
        businessUnitManagementId:
          businessUnitManagementContent?.businessUnitManagement
            ?.businessUnitManagementId,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execStopBusiness',
        request: request,
      });

      setStopBusinessConfirmOpen(false);
      success([successMessage]);

      //リロード
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]取引再開
  const handleStartBusiness = async () => {
    onParentLoadingState(true);
    try {
      const request: mtechnavi.api.company.IStartBusinessRequest = {
        businessUnitManagementId:
          businessUnitManagementContent?.businessUnitManagement
            ?.businessUnitManagementId,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execStartBusiness',
        request: request,
      });

      setStartBusinessConfirmOpen(false);
      success([successMessage]);

      //リロード
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]開始 通知/更新通知
  const handleActivateBusinessUnit = async () => {
    if (!(await confirmation(restartMessage))) {
      return;
    }
    onParentLoadingState(true);
    try {
      const request: mtechnavi.api.company.IBusinessUnitManagement = {
        ...businessUnitManagementContent?.businessUnitManagement,
        notificationDisabled: false,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execUpdateBusinessUnitManagement',
        request: request,
      });
      success([successMessage]);

      //リロード
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  // [実行処理]停止 通知/更新通知
  const handleInactivateBusinessUnit = async () => {
    if (!(await confirmation(stopMessage))) {
      return;
    }
    onParentLoadingState(true);
    try {
      const request: mtechnavi.api.company.IBusinessUnitManagement = {
        ...businessUnitManagementContent?.businessUnitManagement,
        notificationDisabled: true,
      };

      await window.App.services.ui.worker.apiCall({
        actionName: 'execUpdateBusinessUnitManagement',
        request: request,
      });
      success([successMessage]);

      //リロード
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  const onParentLoadingState = useCallback(
    (arg: boolean) => {
      onChangeLoadingState(arg);
    },
    [onChangeLoadingState]
  );

  return (
    <>
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            {/* 取引区分 */}
            <div className="w-20">
              <Textbox
                name="status"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.management.status"
                type="text"
                className=""
                columns={['status']}
                value={
                  businessUnitManagementContent?.businessUnitManagement?.status
                    ?.displayNameLang?.ja ?? ''
                }
                disabled={true}
              />
            </div>
            <div className="w-20 line-up">
              <CaptionButton
                name="statusChangeButton"
                buttonType="basic"
                caption={statusButtonCaption}
                onClick={() => {
                  if (
                    businessUnitManagementContent?.businessUnitManagement
                      ?.status?.systemName === 'B00'
                  ) {
                    setStopBusinessConfirmOpen(true);
                  } else {
                    setStartBusinessConfirmOpen(true);
                  }
                }}
              />
            </div>
            {/* 更新通知 */}
            <div className="w-20">
              <Textbox
                name="notificationStatus"
                type="text"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.management.notificationStatus"
                className=""
                columns={['notificationStatus']}
                value={
                  businessUnitManagementContent?.notificationStatus
                    ?.displayNameLang?.ja ?? ''
                }
                disabled={true}
              />
            </div>
            <div className="w-20 line-up">
              <CaptionButton
                name="saveButton"
                buttonType="basic"
                caption={notificationButtonCaption}
                onClick={() => {
                  if (
                    businessUnitManagementContent?.notificationStatus
                      ?.systemName === 'B00'
                  ) {
                    handleInactivateBusinessUnit();
                  } else {
                    handleActivateBusinessUnit();
                  }
                }}
                disabled={isDisabledNotificationButton}
              />
            </div>
            {/* テナント */}
            <div className="w-20">
              <Textbox
                name="tenantStatus"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.management.tenant"
                type="text"
                className=""
                columns={['tenantStatus']}
                value={
                  businessUnitManagementContent?.tenantStatus?.displayNameLang
                    ?.ja ?? ''
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line management-item-list-area">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleListView
                data={managementItems}
                viewOptions={{
                  previewRowCount: 6,
                  keyColumn: 'id',
                  columns: managementColumns,
                  omitFooter: true,
                }}
                actionOptions={{}}
              />
            </div>
          </div>
        </div>
        {/* テナント招待用エリア */}
        <div className="input-line information-area">
          <div className="item-group-100">
            <div className="w-50">
              <Textbox
                name="businessUnitStaffName"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.management.businessUnitStaffName"
                type="text"
                className=""
                columns={['businessUnitStaffName']}
                value={
                  businessUnitManagementContent?.businessUnitManagement
                    ?.invitees &&
                  businessUnitManagementContent?.businessUnitManagement.invitees
                    .length > 0
                    ? businessUnitManagementContent?.businessUnitManagement
                        .invitees[0].displayName ?? ''
                    : ''
                }
                disabled={true}
              />
            </div>
            <div className="w-50">
              <Textbox
                name="businessUnitStaffEmail"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.management.businessUnitStaffEmail"
                type="text"
                className=""
                columns={['businessUnitStaffEmail']}
                value={
                  businessUnitManagementContent?.businessUnitManagement
                    ?.invitees &&
                  businessUnitManagementContent?.businessUnitManagement.invitees
                    .length > 0
                    ? businessUnitManagementContent?.businessUnitManagement
                        ?.invitees[0].email ?? ''
                    : ''
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 取引停止 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isStopBusinessConfirmOpen}
        viewMessage={stopBusinessMessage}
        onDecision={() => {
          handleStopBusiness();
        }}
        onCancel={() => {
          setStopBusinessConfirmOpen(false);
        }}
      />
      {/* 取引再開 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isStartBusinessConfirmOpen}
        viewMessage={startBusinessMessage}
        onDecision={() => {
          handleStartBusiness();
        }}
        onCancel={() => {
          setStartBusinessConfirmOpen(false);
        }}
      />
      {confirmationElement}
    </>
  );
};
