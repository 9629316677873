import { HistoryDialog } from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getDateFormat } from '~/shared/utils';

interface RevisionHistoriesProps {
  isOpen: boolean;
  onChangeOpen: (isOpen: boolean) => void;
  revisions?: mtechnavi.api.blueprint.IBlueprintRevision[];
  isFromBlueprintSearch?: boolean;
}
/** 改訂履歴ダイアログ用の履歴ダイアログラッパー */
export const RevisionHistories = ({
  isOpen,
  revisions,
  onChangeOpen,
  isFromBlueprintSearch,
}: RevisionHistoriesProps) => {
  const revisionHistories = revisions?.map((rev) => ({
    id: rev.blueprintRevisionId,
    path: `/blueprint/blueprint-confirmation/${rev.blueprintId}/${
      rev.revision
    }${isFromBlueprintSearch ? '?from=blueprint-search' : ''}`,
    revision: rev.revision,
    revisionDt: getDateFormat(rev.revisedDt?.timestamp ?? '', 'YYYY/MM/DD'),
    comment: rev.comment,
    updatedAt: getDateFormat(rev.updatedAt ?? '', 'YYYY/MM/DD HH:mm'),
  }));
  return (
    <HistoryDialog
      isOpen={isOpen}
      messageOption={{
        headerLabelId: {
          prefixId: 'DIALOG_TITLE',
          id: 'BlueprintRevisionHistory',
        },
      }}
      inputOption={{
        data: revisionHistories ?? [],
        keyColumn: 'path',
        rowAction: {
          iconType: 'description',
          buttonType: 'basic',
          action: (path) => {
            if (typeof path !== 'string') {
              return;
            }
            window.open(path);
          },
        },
        columns: [
          {
            propertyName: 'revision',
            width: '8rem',
            header: {
              prefixId: 'BlueprintRevisionHistory',
              id: 'revisionCount',
            },
          },
          {
            propertyName: 'revisionDt',
            width: '12rem',
            header: {
              prefixId: 'BlueprintRevisionHistory',
              id: 'revisionDate',
            },
          },
          {
            propertyName: 'comment',
            header: {
              prefixId: 'BlueprintRevisionHistory',
              id: 'comment',
            },
          },
          {
            propertyName: 'updatedAt',
            width: '12rem',
            header: {
              prefixId: 'BlueprintRevisionHistory',
              id: 'lastUpdatedAt',
            },
          },
        ],
      }}
      onCancel={() => onChangeOpen(false)}
    />
  );
};
