import {
  ViewId,
  getDateFormatWithTimezone,
  mergeAmountNumberWithRounding,
  convertBaseDigitOnceTo,
  BASE_DIGIT_THOUSAND,
  StringDateToNumber,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { SimpleIconListView } from '~/shared/components/ui';
import { Property } from 'csstype';
import { GetMessage } from '~/shared/components';

interface FinancialTabItemProps {
  businessUnitFinancials?: mtechnavi.api.company.IBusinessUnitFinancial[];
}

interface ViewFinancialType {
  businessUnitFinancialId: string; // Key
  period: string; // periodStartDt-periodEndDt YYYY/MM-YYYY/MM
  netSales: string; // netSales / 1000 切り捨て #,###
  operatingProfit: string; // operatingProfit / 1000 切り捨て #,###
  ordinaryIncome: string; // ordinaryIncome / 1000 切り捨て #,###
  notes: string; // nonDisclosure == true, 特記事項：非開示、それ以外空白
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';

const financialColumns = [
  {
    header: {
      id: 'financial.fiscalYear',
      prefixId: VIEW_ID,
    },
    propertyName: 'period',
    width: '10rem',
  },
  {
    header: {
      id: 'financial.netSales',
      prefixId: VIEW_ID,
    },
    propertyName: 'netSales',
    align: 'right' as Property.TextAlign,
    width: '12rem',
  },
  {
    header: {
      id: 'financial.operatingProfit',
      prefixId: VIEW_ID,
    },
    propertyName: 'operatingProfit',
    align: 'right' as Property.TextAlign,
    width: '12rem',
  },
  {
    header: {
      id: 'financial.ordinaryIncome',
      prefixId: VIEW_ID,
    },
    propertyName: 'ordinaryIncome',
    align: 'right' as Property.TextAlign,
    width: '12rem',
  },
  {
    header: {
      id: 'financial.notes',
      prefixId: VIEW_ID,
    },
    propertyName: 'notes',
    align: 'right' as Property.TextAlign,
    width: '8rem',
  },
];

export const FinancialTabItem = ({
  businessUnitFinancials,
}: FinancialTabItemProps) => {
  const getFinancialItems = () => {
    if (!businessUnitFinancials) {
      return [];
    }
    const vals: ViewFinancialType[] = [];
    const sortData = businessUnitFinancials;
    // 開始年月日で降順
    sortData.sort(
      (val1, val2) =>
        StringDateToNumber(getDateFormatWithTimezone(val2.periodStartDt)) -
        StringDateToNumber(getDateFormatWithTimezone(val1.periodStartDt))
    );
    sortData.map((v) =>
      vals.push({
        businessUnitFinancialId: v.businessUnitFinancialId ?? '',
        period: [
          getDateFormatWithTimezone(v.periodStartDt),
          getDateFormatWithTimezone(v.periodEndDt),
        ].join('-'),
        netSales: mergeAmountNumberWithRounding(
          convertBaseDigitOnceTo(v.netSales, BASE_DIGIT_THOUSAND),
          0,
          {
            rounding: 'RoundDown',
            digits: 0,
          }
        ),
        operatingProfit: mergeAmountNumberWithRounding(
          convertBaseDigitOnceTo(v.operatingProfit, BASE_DIGIT_THOUSAND),
          0,
          { rounding: 'RoundDown', digits: 0 }
        ),
        ordinaryIncome: mergeAmountNumberWithRounding(
          convertBaseDigitOnceTo(v.ordinaryIncome, BASE_DIGIT_THOUSAND),
          0,
          {
            rounding: 'RoundDown',
            digits: 0,
          }
        ),
        notes: v.nondisclosure
          ? GetMessage({
              id: 'financial.notes.nonDisclosure',
              prefixId: 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION',
            })
          : '',
      })
    );
    return vals;
  };

  return (
    <>
      <div className="tab-info-body">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleIconListView
                data={getFinancialItems()}
                viewOptions={{
                  previewRowCount: 10,
                  keyColumn: 'businessUnitFinancialId',
                  columns: financialColumns,
                  omitFooter: true,
                }}
                actionOptions={{}}
                iconMenuOptions={{ iconMenu: [] }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
