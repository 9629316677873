/* eslint @typescript-eslint/no-explicit-any: 0 */

import { IntlShape } from 'react-intl';
import { GetMessageWithIntl } from '../components';
import { sharelib } from '../libs/clientsdk/generated';
import { autoDownloadFileWithDate } from '../utils';
import {
  FullMethodName_ExportBlueprints,
  FullMethodName_ExportBusinessUnitCertificates,
  FullMethodName_ExportBusinessUnitContacts,
  FullMethodName_ExportBusinessUnitFacilitiess,
  FullMethodName_ExportBusinessUnitFinancials,
  FullMethodName_ExportBusinessUnitManagementItemValues,
  FullMethodName_ExportBusinessUnitManagements,
  FullMethodName_ExportBusinessUnitRankReceptions,
  FullMethodName_ExportBusinessUnitRankRequests,
  FullMethodName_ExportBusinessUnitSkills,
  FullMethodName_ExportBusinessUnits,
  FullMethodName_ExportComponentUnits,
  FullMethodName_ExportEstimateRequestPlans,
  FullMethodName_ExportOldBusinessUnitManagements,
  FullMethodName_ExportOrganizationRelations,
  FullMethodName_ExportProgramOptions,
  FullMethodName_ExportStaffs,
  FullMethodName_ExportUserAttributes,
  FullMethodName_ExportUserBelongsUserGroups,
  FullMethodName_AsyncImportBlueprints,
  FullMethodName_ImportBusinessUnitCertificates,
  FullMethodName_ImportBusinessUnitContacts,
  FullMethodName_ImportBusinessUnitFacilitiess,
  FullMethodName_ImportBusinessUnitFinancials,
  FullMethodName_ImportBusinessUnitManagementItemValues,
  FullMethodName_ImportBusinessUnitManagements,
  FullMethodName_ImportBusinessUnitRankReceptions,
  FullMethodName_ImportBusinessUnitRankRequests,
  FullMethodName_ImportBusinessUnitSkills,
  FullMethodName_ImportBusinessUnits,
  FullMethodName_ImportComponentUnits,
  FullMethodName_ImportEstimateRequestPlans,
  FullMethodName_ImportOldBusinessUnitManagements,
  FullMethodName_ImportOrganizationRelations,
  FullMethodName_ImportProgramOptions,
  FullMethodName_ImportStaffs,
  FullMethodName_ImportUserAttributes,
  FullMethodName_ImportUserBelongsUserGroups,
  FullMethodName_ExportTransactionUnits,
  FullMethodName_ImportTransactionUnits,
  FullMethodName_ExportBlueprintAttributes,
  FullMethodName_AsyncImportBlueprintAttributes,
  FullMethodName_AsyncImportBlueprintLinkItems,
  FullMethodName_ExportBlueprintLinkItems,
} from 'worker/fullMethodName/crud';
import {
  FullMethodName_ListBlueprintAttributeFormats,
  FullMethodName_ListBlueprintFormats,
  FullMethodName_ListBlueprintLinkItemFormats,
  FullMethodName_ListBusinessUnitCertificateFormats,
  FullMethodName_ListBusinessUnitContactFormats,
  FullMethodName_ListBusinessUnitFacilitiesFormats,
  FullMethodName_ListBusinessUnitFinancialFormats,
  FullMethodName_ListBusinessUnitFormats,
  FullMethodName_ListBusinessUnitManagementFormats,
  FullMethodName_ListBusinessUnitManagementItemValueFormats,
  FullMethodName_ListBusinessUnitRankReceptionFormats,
  FullMethodName_ListBusinessUnitRankRequestFormats,
  FullMethodName_ListBusinessUnitSkillFormats,
  FullMethodName_ListEstimateRequestPlanFormats,
  FullMethodName_ListOldBusinessUnitManagementFormats,
  FullMethodName_ListOrderFormats,
  FullMethodName_ListOrganizationRelationFormats,
  FullMethodName_ListProgramOptionFormats,
  FullMethodName_ListStaffFormats,
  FullMethodName_ListSurveyRequestFormats,
  FullMethodName_ListTransactionUnitFormats,
  FullMethodName_ListUserAttributeFormats,
  FullMethodName_ListUserBelongsUserGroupFormats,
  FullMethodName_ListUserGroupAllowedMenuItemFormats,
  FullMethodName_ListUserGroupAttachedRolesFormats,
  FullMethodName_ListWorkTaskFormats,
} from '~/worker';
import { Catalog, rpcImpl } from '../libs/clientsdk';
interface FileProcessDetailConfigType<T> {
  prefixMsg: T;
  fullMethodName: T;
  systemCode?: T;
}

interface FileFormatConfigType<T> {
  fullMethodName: T;
  systemCode?: T[];
}
interface ProcessConfig<T> {
  import?: FileProcessDetailConfigType<T>;
  export?: FileProcessDetailConfigType<T>;
  fileFormat?: FileFormatConfigType<T>;
}

interface FileTypeConfigType<T> {
  [fileTypeName: string]: ProcessConfig<T>;
}

type InternalBranches = 'businessUnitManagement' | 'blueprint';

type InternalBranchMapType = {
  [K in InternalBranches]: { [key: string]: string };
};

export const internalBranchMap: InternalBranchMapType = {
  businessUnitManagement: {
    B01: 'businessUnitManagement',
    B02: 'businessUnitManagementItemValue',
    B04: 'oldBusinessUnitManagement',
    B05: 'rankRequest',
    B06: 'businessUnitContacts',
    B07: 'businessUnitFinancials',
    B08: 'businessUnitFacilitiess',
    B09: 'businessUnitCertificates',
    B10: 'businessUnitStrengths',
    B11: 'businessUnitSkills',
    B12: 'rankReception',
  },
  blueprint: {
    B01: 'blueprint',
    B02: 'blueprintAttribute',
    B03: 'blueprintLinkItem',
  },
};

export const fileExportConfigMap: FileTypeConfigType<string> = {
  transactionUnit: {
    export: {
      prefixMsg: 'EXPORT_TRANSACTION_UNIT_FILE_NAME',
      fullMethodName: FullMethodName_ExportTransactionUnits,
    },
    import: {
      prefixMsg: 'IMPORT_TRANSACTION_UNIT_FILE_NAME',
      fullMethodName: FullMethodName_ImportTransactionUnits,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListTransactionUnitFormats,
    },
  },
  componentUnit: {
    export: {
      prefixMsg: 'EXPORT_COMPONENT_UNIT_FILE_NAME',
      fullMethodName: FullMethodName_ExportComponentUnits,
    },
    import: {
      prefixMsg: 'IMPORT_COMPONENT_UNIT_FILE_NAME',
      fullMethodName: FullMethodName_ImportComponentUnits,
    },
    fileFormat: {
      // ない
      fullMethodName: 'FullMethodName_ListComponentUnitFormats',
    },
  },
  businessUnit: {
    export: {
      prefixMsg: 'EXPORT_BUSINESS_UNIT_FILE_NAME',
      fullMethodName: FullMethodName_ExportBusinessUnits,
    },
    import: {
      prefixMsg: 'IMPORT_BUSINESS_UNIT_FILE_NAME',
      fullMethodName: FullMethodName_ImportBusinessUnits,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBusinessUnitFormats,
    },
  },
  businessUnitManagement: {
    export: {
      prefixMsg: 'EXPORT_BUSINESS_UNIT_MANAGEMENT_FILE_NAME',
      fullMethodName: FullMethodName_ExportBusinessUnitManagements,
    },
    import: {
      prefixMsg: 'IMPORT_BUSINESS_UNIT_MANAGEMENT_FILE_NAME',
      fullMethodName: FullMethodName_ImportBusinessUnitManagements,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBusinessUnitManagementFormats,
    },
  },
  businessUnitManagementItemValue: {
    export: {
      prefixMsg: 'EXPORT_BUSINESS_UNIT_MANAGEMENT_ITEM_VALUE_FILE_NAME',
      fullMethodName: FullMethodName_ExportBusinessUnitManagementItemValues,
    },
    import: {
      prefixMsg: 'IMPORT_BUSINESS_UNIT_MANAGEMENT_ITEM_VALUE_FILE_NAME',
      fullMethodName: FullMethodName_ImportBusinessUnitManagementItemValues,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBusinessUnitManagementItemValueFormats,
    },
  },
  oldBusinessUnitManagement: {
    export: {
      prefixMsg: 'EXPORT_OLD_BUSINESS_UNIT_MANAGEMENT_FILE_NAME',
      fullMethodName: FullMethodName_ExportOldBusinessUnitManagements,
    },
    import: {
      prefixMsg: 'IMPORT_OLD_BUSINESS_UNIT_MANAGEMENT_FILE_NAME',
      fullMethodName: FullMethodName_ImportOldBusinessUnitManagements,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListOldBusinessUnitManagementFormats,
    },
  },
  rankRequest: {
    export: {
      prefixMsg: 'EXPORT_BUSINESS_UNIT_MANAGEMENT_RANK_REQUESTS_FILE_NAME',
      fullMethodName: FullMethodName_ExportBusinessUnitRankRequests,
    },
    import: {
      prefixMsg: 'IMPORT_BUSINESS_UNIT_MANAGEMENT_RANK_REQUESTS_FILE_NAME',
      fullMethodName: FullMethodName_ImportBusinessUnitRankRequests,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBusinessUnitRankRequestFormats,
    },
  },
  businessUnitContacts: {
    export: {
      prefixMsg: 'EXPORT_BUSINESS_UNIT_MANAGEMENT_CONTACTS_FILE_NAME',
      fullMethodName: FullMethodName_ExportBusinessUnitContacts,
    },
    import: {
      prefixMsg: 'IMPORT_BUSINESS_UNIT_MANAGEMENT_CONTACTS_FILE_NAME',
      fullMethodName: FullMethodName_ImportBusinessUnitContacts,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBusinessUnitContactFormats,
    },
  },
  businessUnitFinancials: {
    export: {
      prefixMsg: 'EXPORT_BUSINESS_UNIT_MANAGEMENT_FINANCIALS_FILE_NAME',
      fullMethodName: FullMethodName_ExportBusinessUnitFinancials,
    },
    import: {
      prefixMsg: 'IMPORT_BUSINESS_UNIT_MANAGEMENT_FINANCIALS_FILE_NAME',
      fullMethodName: FullMethodName_ImportBusinessUnitFinancials,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBusinessUnitFinancialFormats,
    },
  },
  businessUnitFacilitiess: {
    export: {
      prefixMsg: 'EXPORT_BUSINESS_UNIT_MANAGEMENT_FACILITIESS_FILE_NAME',
      fullMethodName: FullMethodName_ExportBusinessUnitFacilitiess,
    },
    import: {
      prefixMsg: 'IMPORT_BUSINESS_UNIT_MANAGEMENT_FACILITIESS_FILE_NAME',
      fullMethodName: FullMethodName_ImportBusinessUnitFacilitiess,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBusinessUnitFacilitiesFormats,
    },
  },
  businessUnitCertificates: {
    export: {
      prefixMsg: 'EXPORT_BUSINESS_UNIT_MANAGEMENT_CERTIFICATES_FILE_NAME',
      fullMethodName: FullMethodName_ExportBusinessUnitCertificates,
    },
    import: {
      prefixMsg: 'IMPORT_BUSINESS_UNIT_MANAGEMENT_CERTIFICATES_FILE_NAME',
      fullMethodName: FullMethodName_ImportBusinessUnitCertificates,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBusinessUnitCertificateFormats,
    },
  },
  businessUnitSkills: {
    export: {
      prefixMsg: 'EXPORT_BUSINESS_UNIT_MANAGEMENT_SKILLS_FILE_NAME',
      fullMethodName: FullMethodName_ExportBusinessUnitSkills,
    },
    import: {
      prefixMsg: 'IMPORT_BUSINESS_UNIT_MANAGEMENT_SKILLS_FILE_NAME',
      fullMethodName: FullMethodName_ImportBusinessUnitSkills,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBusinessUnitSkillFormats,
    },
  },
  rankReception: {
    export: {
      prefixMsg: 'EXPORT_BUSINESS_UNIT_MANAGEMENT_RANK_RECEPTIONS_FILE_NAME',
      fullMethodName: FullMethodName_ExportBusinessUnitRankReceptions,
    },
    import: {
      prefixMsg: 'IMPORT_BUSINESS_UNIT_MANAGEMENT_RANK_RECEPTIONS_FILE_NAME',
      fullMethodName: FullMethodName_ImportBusinessUnitRankReceptions,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBusinessUnitRankReceptionFormats,
    },
  },
  staff: {
    export: {
      prefixMsg: 'EXPORT_STAFF_FILE_NAME',
      fullMethodName: FullMethodName_ExportStaffs,
    },
    import: {
      prefixMsg: 'IMPORT_STAFF_FILE_NAME',
      fullMethodName: FullMethodName_ImportStaffs,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListStaffFormats,
    },
  },
  blueprint: {
    export: {
      prefixMsg: 'EXPORT_BLUEPRINT_FILE_NAME',
      fullMethodName: FullMethodName_ExportBlueprints,
    },
    import: {
      prefixMsg: 'IMPORT_BLUEPRINT_FILE_NAME',
      fullMethodName: FullMethodName_AsyncImportBlueprints,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBlueprintFormats,
    },
  },
  blueprintAttribute: {
    export: {
      prefixMsg: 'EXPORT_BLUEPRINT_ATTRIBUTE_FILE_NAME',
      fullMethodName: FullMethodName_ExportBlueprintAttributes,
    },
    import: {
      prefixMsg: 'IMPORT_BLUEPRINT_ATTRIBUTE_FILE_NAME',
      fullMethodName: FullMethodName_AsyncImportBlueprintAttributes,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBlueprintAttributeFormats,
    },
  },
  blueprintLinkItem: {
    export: {
      prefixMsg: 'EXPORT_BLUEPRINT_LINK_ITEM_FILE_NAME',
      fullMethodName: FullMethodName_ExportBlueprintLinkItems,
    },
    import: {
      prefixMsg: 'IMPORT_BLUEPRINT_LINK_ITEM_FILE_NAME',
      fullMethodName: FullMethodName_AsyncImportBlueprintLinkItems,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListBlueprintLinkItemFormats,
    },
  },
  userAttribute: {
    export: {
      prefixMsg: 'EXPORT_USER_ATTRIBUTES_FILE_NAME',
      fullMethodName: FullMethodName_ExportUserAttributes,
    },
    import: {
      prefixMsg: 'IMPORT_USER_ATTRIBUTES_FILE_NAME',
      fullMethodName: FullMethodName_ImportUserAttributes,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListUserAttributeFormats,
    },
  },
  userBelongsUserGroup: {
    export: {
      prefixMsg: 'EXPORT_USER_BELONGS_USER_GROUP_FILE_NAME',
      fullMethodName: FullMethodName_ExportUserBelongsUserGroups,
    },
    import: {
      prefixMsg: 'IMPORT_USER_BELONGS_USER_GROUP_FILE_NAME',
      fullMethodName: FullMethodName_ImportUserBelongsUserGroups,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListUserBelongsUserGroupFormats,
    },
  },
  masterProgramOpiton: {
    export: {
      prefixMsg: 'EXPORT_MASTER_PROGRAM_OPTION_FILE_NAME',
      fullMethodName: FullMethodName_ExportProgramOptions,
    },
    import: {
      prefixMsg: 'IMPORT_MASTER_PROGRAM_OPTION_FILE_NAME',
      fullMethodName: FullMethodName_ImportProgramOptions,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListProgramOptionFormats,
    },
  },
  estimateRequestPlans: {
    export: {
      prefixMsg: 'EXPORT_ESTIMATE_REQUEST_PLANS_FILE_NAME',
      fullMethodName: FullMethodName_ExportEstimateRequestPlans,
    },
    import: {
      prefixMsg: 'IMPORT_ESTIMATE_REQUEST_PLANS_FILE_NAME',
      fullMethodName: FullMethodName_ImportEstimateRequestPlans,
    },
    fileFormat: {
      fullMethodName: FullMethodName_ListEstimateRequestPlanFormats,
    },
  },
  organizationRelations: {
    export: {
      prefixMsg: 'EXPORT_ORGANIZATION_RELATIONS_FILE_NAME',
      fullMethodName: FullMethodName_ExportOrganizationRelations,
    },
    import: {
      prefixMsg: 'IMPORT_ORGANIZATION_RELATIONS_FILE_NAME',
      fullMethodName: FullMethodName_ImportOrganizationRelations,
    },
    fileFormat: {
      systemCode: ['B01', 'B02'],
      fullMethodName: FullMethodName_ListOrganizationRelationFormats,
    },
  },
  estimateRequestPlanUnit: {
    fileFormat: {
      fullMethodName: FullMethodName_ListEstimateRequestPlanFormats,
    },
  },
  order: {
    fileFormat: {
      fullMethodName: FullMethodName_ListOrderFormats,
    },
  },
  userGroupAttachedRoles: {
    fileFormat: {
      fullMethodName: FullMethodName_ListUserGroupAttachedRolesFormats,
    },
  },
  userGroupAllowedMenuItem: {
    fileFormat: {
      fullMethodName: FullMethodName_ListUserGroupAllowedMenuItemFormats,
    },
  },
  wortTask: {
    fileFormat: {
      fullMethodName: FullMethodName_ListWorkTaskFormats,
    },
  },
  surveyRequest: {
    fileFormat: {
      fullMethodName: FullMethodName_ListSurveyRequestFormats,
    },
  },
};

export type FileExportRequestType = {
  fileType: string;
  systemCode: string;
  intl: IntlShape;
  request: {
    fileFormat?: sharelib.IFileFormat;
    ids: string[];
  };
};

export type FileImportRequestType = {
  fileType: string;
  systemCode: string;
  intl: IntlShape;
  request: {
    fileFormat?: sharelib.IFileFormat;
    url: string;
    dryRun: boolean;
    exportError: boolean;
  };
};

export type FileFormatRequestType = {
  fileType: string;
  request: {
    systemCode: string;
    intl: IntlShape;
  };
};

// エキスポート処理
export async function fileExportApiCall<T extends FileExportRequestType>(
  req: T
): Promise<void> {
  const { systemCode, request, intl } = req;
  let fileType = req.fileType;
  if (isInternalBranch(fileType) && systemCode) {
    fileType = internalBranchMap[fileType][systemCode];
  }
  const filetypeConf = Object.entries(fileExportConfigMap).find(
    ([type]) => type === fileType
  );
  if (!filetypeConf) {
    throw new Error(`unknown fileType: ${fileType}`);
  }
  if (!request.fileFormat) {
    const fileFormat = await fileFormatApiCall({
      fileType,
      request: { systemCode, intl },
    });
    request.fileFormat = fileFormat;
  }
  const [, { export: exportConf }] = filetypeConf;
  if (!exportConf) {
    throw new Error(`export dose no exist: ${fileType}`);
  }
  const result = (await fileApiCall(exportConf.fullMethodName, request)).at(
    0
  ) as any;
  if (!result) {
    throw new Error(`assetId dose no exist: ${exportConf.prefixMsg}`);
  }
  autoDownloadFileWithDate(
    GetMessageWithIntl(intl, {
      prefixId: exportConf.prefixMsg,
    }),
    'csv',
    result?.assetId
  );
}

// インポート処理
export async function fileImportApiCall<T extends FileImportRequestType>(
  req: T
) {
  const { systemCode, request, intl } = req;
  let fileType = req.fileType;
  if (isInternalBranch(fileType) && systemCode) {
    fileType = internalBranchMap[fileType][systemCode];
  }
  const fileTypeConf = Object.entries(fileExportConfigMap).find(
    ([type]) => type === fileType
  );
  if (!fileTypeConf) {
    throw new Error(`unknown fileType: ${fileType}`);
  }
  if (!request.fileFormat) {
    const fileFormat = await fileFormatApiCall({
      fileType,
      request: { systemCode, intl },
    });
    request.fileFormat = fileFormat;
  }
  const [, { import: importConf }] = fileTypeConf;
  if (!importConf) {
    throw new Error(`import dose no exist: ${fileType}`);
  }

  return (await fileApiCall(importConf?.fullMethodName, request)).at(0) as any;
}

// ファイルフォーマット取得処理
export async function fileFormatApiCall<T extends FileFormatRequestType>(
  req: T
) {
  const { systemCode, intl } = req.request;
  let fileType = req.fileType;
  if (isInternalBranch(fileType) && systemCode) {
    fileType = internalBranchMap[fileType][systemCode];
  }
  const fileTypeConf = Object.entries(fileExportConfigMap).find(([type]) => {
    return type === fileType;
  });
  if (!fileTypeConf) {
    throw new Error(`unknown fileType: ${fileType}`);
  }
  const [, { fileFormat }] = fileTypeConf;
  if (!fileFormat) {
    throw new Error(`fileFormat dose no exist: ${fileType}`);
  }
  let result = (await fileApiCall(fileFormat?.fullMethodName, {})) as any;
  if (
    req.request.systemCode &&
    fileFormat?.systemCode?.includes(req.request.systemCode)
  ) {
    result = result?.filter(
      (item: sharelib.IFileFormat) =>
        item.format?.systemName === req.request.systemCode
    );
  }
  const items: sharelib.IFileFormat = result.pop() ?? {};
  (items.headerColumns ?? []).map((v) => {
    if (v.messageName) {
      v.displayName = GetMessageWithIntl(intl, { id: v.messageName });
    }
  });
  return items;
}

/**
 * 内部分岐があるかチェック（取引先確認など）
 *
 * @param value 分類名
 * @returns
 */
export function isInternalBranch(value: string): value is InternalBranches {
  return value in internalBranchMap;
}

async function fileApiCall<U>(fullMethodName: string, req: any) {
  return invokeFileRequest<U>(fullMethodName, req);
}

async function invokeFileRequest<U>(
  fullMethodName: string,
  req: any
): Promise<U[]> {
  const [fullServiceName, methodName] = fullMethodName.split('/', 2);
  const $service = Catalog.lookupService(fullServiceName);
  const $method = $service.methods[methodName];
  const service = $service.create(rpcImpl);
  (service as any).__fullServiceName = fullServiceName;
  const result: any[] = [];
  const res = await service.rpcCall(
    $method,
    $method.resolvedRequestType!.ctor as any,
    $method.resolvedResponseType!.ctor as any,
    req ?? ({} as any),
    null as any
  );
  // @ts-ignore
  if (res.items) {
    // @ts-ignore
    res.items.map((v) => result.push(v));
  } else {
    result.push(res as unknown as U);
  }

  return result;
}
