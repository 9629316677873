import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { PresetItem } from '~/shared/services';
import {
  Container,
  GetMessage,
  Toast,
  LoadingIcon,
  success,
  error,
  GetMessageWithIntl,
} from '~/shared/components';
import { Textbox } from '~/shared/components/ui/Textbox';
import { CaptionButton } from '~/shared/components/ui/Button';
import { useLocation } from 'react-router-dom';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  HistoryDialog,
  HistoryDialogColumnSetting,
} from '~/shared/components/ui/Dialog';
import { ChangeNotificationDialog } from '~/shared/components/ui/Dialog/ChangeNotificationDialog';

import './BusinessUnitManagementConfirmation.css';
import {
  PageNavigation,
  NavigationIconMenu,
} from '~/shared/components/ui/PageNavigation/PageNavigation';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  PageState,
  getViewIdPreset,
  getWorkerExceptionMessage,
  ViewId,
  FullMethodName_ListBusinessUnitManagementContents,
  getSortSettingFromLocalStorage,
  getExceptionMessage,
  HistoryItems,
  getHistoryItems,
  isShowNotificationArea,
  typeName,
  convertSkillTreeToCategorizedList,
} from '~/shared/utils';
import { FullMethodName_SharedListLicenses } from '~/worker';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { ReactComponent as PriorityHighIcon } from '@material-design-icons/svg//filled/priority_high.svg';
import { CategorizedList } from '~/shared/components/ui/CategorizedCheckList';
import { BranchTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/BranchTabItem';
import { CertificateTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/CertificateTabItem';
import { ContactTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/ContactTabItem';
import { DocumentTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/DocumentTabItem';
import { FacilitiesTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/FacilitiesTabItem';
import { FinancialTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/FinancialTabItem';
import { ManagementTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/ManagementTabItem';
import { ProfileTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/ProfileTabItem';
import { RankReceptionTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/RankReceptionTabItem';
import { RankRequestTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/RankRequestTabItem';
import { SkillTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/SkillTabItem';
import { StrengthTabItem } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/parts/StrengthTabItem';
import {
  listBusinessUnitContactContents,
  listBusinessUnitBranchs,
  listBusinessUnitFinancials,
  listBusinessUnitFacilitiess,
  listBusinessUnitCertificates,
  listBusinessUnitStrengths,
  listBusinessUnitDocuments,
  listBusinessUnitSkills,
  listBusinessUnitRankRequestContents,
  listBusinessUnitRankReceptionContents,
  listOwnCompanyBranchs,
  listBusinessUnitChangeNotifications,
  listBusinessUnitPublicDocument,
} from '~/tenant/core/pages/BusinessUnitManagementConfirmation/utils/api';
import { isLinkedTenant } from '~/tenant/core/pages/BusinessUnitManagementConfirmation/utils/conditions';
import { PublicDocumentTabItem } from './parts/PublicDocumentTabItem';

// 更新通知ダイアログ用
interface ViewChangeNotificationType {
  changeNotificationId: string; // key
  typeName: string; // 抽出用
  recordId: string; // 抽出用
  targetKey: string; // 名称を格納
  targetFieldPath: string; // 辞書引きが必要
  oldValue: string; // フォーマット済の値
  newValue: string; // フォーマット済の値
  remarks: string; // string[] からの変換、辞書引きが必要
  notifiedAt: Long | undefined; // ダイアログ側でフォーマット
}

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION';
const LICENSE_NITERRA_BUSINESS_BETA = '_NITERRA_BUSINESSUNIT_BETA';
const LICENSE_NITERRA_BUSINESS = '_NITERRA_BUSINESSUNIT';

const historyColumns: HistoryDialogColumnSetting<HistoryItems>[] = [
  {
    propertyName: 'slip_category',
    width: '12rem',
    header: {
      prefixId: 'HistoryDialog',
      id: 'slip_category',
    },
  },
  {
    propertyName: 'occurred_at',
    width: '12rem',
    header: {
      prefixId: 'HistoryDialog',
      id: 'occurred_at',
    },
  },
  {
    propertyName: 'content',
    header: {
      prefixId: 'HistoryDialog',
      id: 'content',
    },
  },
];

const changeNotificationColumns = [
  {
    propertyName: 'targetKey',
    width: '18%',
    header: {
      prefixId: 'ChangeNotificationDialog',
      id: 'targetKey',
    },
  },
  {
    propertyName: 'targetFieldPath',
    width: '18%',
    header: {
      prefixId: 'ChangeNotificationDialog',
      id: 'targetFieldPath',
    },
  },
  {
    propertyName: 'oldValue',
    width: '18%',
    header: {
      prefixId: 'ChangeNotificationDialog',
      id: 'oldValue',
    },
  },
  {
    propertyName: 'newValue',
    width: '18%',
    header: {
      prefixId: 'ChangeNotificationDialog',
      id: 'newValue',
    },
  },
  {
    propertyName: 'remarks',
    width: '28%',
    header: {
      prefixId: 'ChangeNotificationDialog',
      id: 'remarks',
    },
  },
];

export function BusinessUnitManagementConfirmation() {
  // ログイン者の情報特定用email
  const auth = useAuth();
  const myTenantId = useAuth().tenant?.tenantId ?? '';
  const myEmail = useAuth().user?.email ?? '';
  // 自社企業情報
  const myCompanyInfo = useRef<mtechnavi.api.company.ICompany>();
  const handleError = useErrorHandler();
  const intl = useIntl();
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];
  // ユーザー権限の有無

  const getTargetId = () => {
    const urlPathName = window.location.pathname;
    const splitPath = urlPathName.split('/');
    if (location.ids) {
      return location.ids ?? [];
    } else {
      if (splitPath.length > 2) {
        const tabPath = splitPath[splitPath.length - 1];
        const idPath = splitPath[splitPath.length - 2];
        const endpointPath = splitPath[splitPath.length - 3];
        if (
          tabPath !== 'businessunitmanagement-confirmation' &&
          idPath !== 'businessunitmanagement-confirmation' &&
          endpointPath === 'businessunitmanagement-confirmation'
        ) {
          return [idPath];
        }
      }
    }
    return [];
  };

  /**
   * @param:TabName 初回の起動のみ、初回タブ表示をプロフィールにするために設定
   */
  const getTargetTabItem = (initialTab?: TabName): TabName => {
    // タブ内で更新がかかりリロードした後に元のタブに戻す（基本情報には更新系なし）
    if (tabPanel !== 'profile' && !initialTab) {
      return tabPanel;
    }
    const urlPathName = window.location.pathname;
    const splitPath = urlPathName.split('/');
    if (splitPath.length > 2) {
      return splitPath[splitPath.length - 1] as TabName;
    }
    return 'profile';
  };

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListBusinessUnitManagementContents,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    requestBody: {
      dataFilter: {
        businessUnitManagementIds: getTargetId(),
      } as mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.IDataFilter,
    },
    filter: {
      'businessUnitManagement.businessUnitManagementId': {
        $in: getTargetId() ?? [],
      },
    },
    sort: getSortSettingFromLocalStorage(
      'BUM_BUSINESS_UNIT_MANAGEMENT_LIST',
      myEmail
    ),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  // Loading制御用
  const [isLoading, setLoading] = useState(false);

  // メインデータ（取引先管理/基本情報）
  const [item, setItem] =
    useState<mtechnavi.api.company.BusinessUnitManagementContent>();
  // 取得データ格納用 取引先管理（連絡先）コンテンツ
  const [businessUnitContactContentsData, setBusinessUnitContactContentsData] =
    useState<mtechnavi.api.company.IBusinessUnitContactContent[]>([]);
  // 取得データ格納用 取引先管理（拠点）マスタ
  const businessUnitBranchsData = useRef<
    mtechnavi.api.company.IBusinessUnitBranch[]
  >([]);
  // 取得データ格納用 取引先管理（決算）マスタ
  const businessUnitFinancialsData = useRef<
    mtechnavi.api.company.IBusinessUnitFinancial[]
  >([]);
  // 取得データ格納用 取引先管理（設備）マスタ
  const businessUnitFacilitiessData = useRef<
    mtechnavi.api.company.IBusinessUnitFacilities[]
  >([]);
  // 取得データ格納用 取引先管理（認証）マスタ
  const businessUnitCertificatesData = useRef<
    mtechnavi.api.company.IBusinessUnitCertificate[]
  >([]);
  // 取得データ格納用 取引先管理（得意分野）マスタ
  const businessUnitStrengthsData = useRef<
    mtechnavi.api.company.IBusinessUnitStrength[]
  >([]);
  // 取得データ格納用 取引先管理（文書）マスタ
  const [businessUnitDocumentsData, setBusinessUnitDocumentsData] = useState<
    mtechnavi.api.company.IBusinessUnitDocument[]
  >([]);
  // 取得データ格納用 取引先管理（技術情報）マスタ
  const businessUnitSkillsData = useRef<
    mtechnavi.api.company.IBusinessUnitSkill[]
  >([]);
  // 技術情報データ格納用 ツリー情報
  const skillTreeData = useRef<CategorizedList>([]);
  // 取得データ格納用 更新通知データ
  const businessUnitChangeNotificationsData = useRef<
    mtechnavi.api.company.IBusinessUnitChangeNotification[]
  >([]);
  // 取得データ格納用 品質要求
  const businessUnitRankRequestData = useRef<
    mtechnavi.api.company.IBusinessUnitRankContent[]
  >([]);
  // 取得データ格納用 品質管理
  const [businessUnitRankReceptionData, setBusinessUnitRankReceptionData] =
    useState<mtechnavi.api.company.IBusinessUnitRankContent[]>([]);
  // 取得データ格納用 品質管理で使用する自社拠点情報
  const ownCompanyBranchData = useRef<
    mtechnavi.api.company.IBusinessUnitBranch[]
  >([]);
  // 取得データ格納用 公開資料
  const businessUnitPublicDocumentData = useRef<
    mtechnavi.api.company.IBusinessUnitPublicDocument[]
  >([]);

  // 履歴ダイアログ
  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const [historys, setHistorys] = useState<HistoryItems[]>([]);
  const historyMessage = GetMessage({
    id: 'I0000005',
    value: { $1: historys?.length > 0 ? historys[0].slip_category : '' },
  });

  // 更新通知ダイアログ
  const [
    isBusinessUnitChangeNotificationDialogOpen,
    setBusinessUnitChangeNotificationDialogOpen,
  ] = useState(false);
  const [changeNotifications, setChangeNotifications] = useState<
    ViewChangeNotificationType[]
  >([]);

  // 項目表示制御
  const [isVisibleHistory, setVisibleHistory] = useState(false);
  const [isVisibleChangeNotification, setVisibleChangeNotification] =
    useState(false);
  const [isVisibleBranchTab, setVisibleBranchTab] = useState(false);
  const [isVisiblePublicDocumentTab, setVisiblePublicDocumentTab] =
    useState(false);
  const [isVisibleRankRequestTab, setVisibleRankRequestTab] = useState(false);
  const [isVisibleRankReceptionTab, setVisibleRankReceptionTab] =
    useState(false);
  // note
  //  ライセンスによる画面の表示切り替えで表示状態から非表示にすると見せたくないタブのチラつきが発生するため
  //  制限がある（LICENSE_NITERRA_BUSINESS_BETA がある）方を初期セットとする
  const [isNiterraBusinessBeta, setNiterraBusinessBeta] = useState(true);
  const [isNiterraBusiness, setNiterraBusiness] = useState(false);

  // メッセージ
  const successMessage = GetMessage({ id: 'I0000001' });

  // タブ制御
  type TabName =
    | 'profile'
    | 'contact'
    | 'branch'
    | 'financial'
    | 'facilities'
    | 'certificate'
    | 'strength'
    | 'document'
    | 'management'
    | 'skill'
    | 'rankRequest'
    | 'rankReception'
    | 'publicDocument';
  const [tabs, setTabs] = useState<TabName[]>([]);
  const [tabPanel, setTabPanel] = useState<TabName>('contact');
  const isCurrentTab = (target: TabName) => {
    return tabPanel === target;
  };

  // ヘッダアイコン設定
  const getPageNavigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];

    if (isVisibleHistory) {
      // 履歴
      iconItems.push({
        name: 'history',
        displayName: '履歴',
        func: () => {
          setHistoryOpen(true);
        },
      });
    }

    return iconItems;
  };

  // 履歴関連処理
  // 通知エリア制御
  const [isNotificationShow, setNotificationShow] = useState<boolean>(false);
  // 履歴データ取得
  const listHistoryData = async () => {
    setLoading(true);
    try {
      let typeName: typeName = '';
      let recordId = '';
      if (isCurrentTab('contact')) {
        typeName = 'mtechnavi.api.company.BusinessUnitContact';
        recordId = item?.businessUnitManagement?.businessUnitManagementId ?? '';
      } else {
        typeName = 'mtechnavi.api.company.BusinessUnitManagement';
        recordId = item?.businessUnitManagement?.businessUnitManagementId ?? '';
      }

      // 履歴一覧を取得
      const datas = await getHistoryItems(intl, typeName, [recordId]);
      setHistorys(datas);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const getChangeNotificationItems = () => {
    // 更新通知データを取得
    const changeNotificationItems: ViewChangeNotificationType[] = [];
    listBusinessUnitChangeNotificationsFilterTabName(tabPanel).map((v) =>
      changeNotificationItems.push({
        changeNotificationId: v.businessUnitChangeNotificationId ?? '',
        typeName: v.typeName ?? '',
        recordId: v.recordId ?? '',
        targetKey: v.targetKey ?? '',
        targetFieldPath: GetMessageWithIntl(intl, {
          id: v.targetFieldPath ?? '',
        }),
        oldValue: v.oldValue ?? '',
        newValue: v.newValue ?? '',
        remarks:
          v.remarksNames
            ?.map((w) => {
              return GetMessageWithIntl(intl, { id: w });
            })
            .join('') ?? '',
        notifiedAt: v.notifiedAt ?? undefined,
      })
    );
    return changeNotificationItems;
  };
  // 更新通知ダイアログ起動時
  useEffect(() => {
    // 基本情報はタブ遷移前にダイアログ表示するケースが存在するため、直前にもセットする
    if (isBusinessUnitChangeNotificationDialogOpen) {
      // 更新通知データをセット
      setChangeNotifications(getChangeNotificationItems());
    }

    // isBusinessUnitChangeNotificationDialogOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBusinessUnitChangeNotificationDialogOpen]);

  // 履歴ダイアログ起動時
  useEffect(() => {
    if (isHistoryOpen) {
      //履歴データを取得
      (async () => {
        await listHistoryData();
      })();
    }

    // isHistoryOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHistoryOpen]);

  // 履歴データ変更時
  useEffect(() => {
    // 最新の履歴通知レベルによる制御
    setNotificationShow(isShowNotificationArea(historys));
  }, [historys]);

  // [実行処理]ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListBusinessUnitManagementContents,
      pageNumber: n,
    });
  };

  // [実行処理]リロード
  //  メインデータ（BusinessUnitManagementContent）の変更をトリガーに
  //  全件データ取得（listAllData）が実行されるため再取得に注意すること
  const handleReloadManagement = () => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitManagementContents,
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
    });
  };

  // [実行処理]リロード(連絡先情報のみ)
  const handleReloadContact = async () => {
    const [listBusinessUnitContactContentsRes] = await Promise.all([
      listBusinessUnitContactContents(
        [item?.businessUnitManagement?.businessUnitManagementId ?? ''],
        isLinkedTenant(item?.tenantStatus?.systemName)
      ),
    ]);
    setBusinessUnitContactContentsData(
      listBusinessUnitContactContentsRes.items ?? []
    );
  };

  // [実行処理]リロード(文書情報のみ)
  const handleReloadDocument = async () => {
    const [listBusinessUnitDocumentsRes] = await Promise.all([
      listBusinessUnitDocuments([
        item?.businessUnitManagement?.businessUnitManagementId ?? '',
      ]),
    ]);
    setBusinessUnitDocumentsData(listBusinessUnitDocumentsRes.items ?? []);
  };

  // [実行処理]リロード(品質管理情報のみ)
  const handleReloadRankReceptionContent = async () => {
    const [listBusinessUnitRankReceptionContentsRes] = await Promise.all([
      listBusinessUnitRankReceptionContents([
        item?.businessUnitManagement?.businessUnitManagementId ?? '',
      ]),
    ]);
    setBusinessUnitRankReceptionData(
      listBusinessUnitRankReceptionContentsRes.items ?? []
    );
  };

  // [実行処理]確認済み 更新通知ダイアログ
  const handleConfirmedChangeNotification = async () => {
    setLoading(true);
    try {
      const targets =
        listBusinessUnitChangeNotificationsFilterTabName(tabPanel);
      const targetIds: string[] = [];
      targets.map((v) => targetIds.push(v.recordId ?? ''));

      if (targetIds.length > 0) {
        const targetTypeName = targets.length > 0 ? targets[0].typeName : '';
        const request: mtechnavi.api.company.IConfirmBusinessUnitChangeNotificationRequest =
          {
            typeName: targetTypeName,
            recordIds: targetIds,
          };

        await window.App.services.ui.worker.apiCall({
          actionName: 'execConfirmBusinessUnitChangeNotification',
          request: request,
        });
      }

      setBusinessUnitChangeNotificationDialogOpen(false);
      success([successMessage]);

      //リロード(更新通知データのみ)
      const [listBusinessUnitChangeNotificationsRes] = await Promise.all([
        listBusinessUnitChangeNotifications([
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
        ]),
      ]);
      businessUnitChangeNotificationsData.current =
        listBusinessUnitChangeNotificationsRes.items ?? [];
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 制御対象のコントロールの非活性(非表示)化
  const disableAllItems = () => {
    setVisibleHistory(false);
    setVisibleChangeNotification(false);
    setVisibleBranchTab(false);
    setVisiblePublicDocumentTab(false);
  };

  // [データ取得] 全データ取得
  const listAllData = async () => {
    // 以下のデータ取得は取引先管理コンテンツの取得後の前提
    setLoading(true);
    try {
      const [
        listBusinessUnitContactContentsRes,
        listBusinessUnitBranchsRes,
        listBusinessUnitFinancialsRes,
        listBusinessUnitFacilitiessRes,
        listBusinessUnitCertificatesRes,
        listBusinessUnitStrengthsRes,
        listBusinessUnitDocumentsRes,
        listBusinessUnitSkillsRes,
        listBusinessUnitRankRequestContentsRes,
        listBusinessUnitRankReceptionContentsRes,
        listOwnCompanyBranchsRes,
        listBusinessUnitChangeNotificationsRes,
        listBusinessUnitPublicDocumentRes,
      ] = await Promise.all([
        listBusinessUnitContactContents(
          [item?.businessUnitManagement?.businessUnitManagementId ?? ''],
          isLinkedTenant(item?.tenantStatus?.systemName)
        ),
        listBusinessUnitBranchs([
          item?.businessUnitManagement?.companyId ?? '',
        ]),
        listBusinessUnitFinancials(
          [item?.businessUnitManagement?.companyId ?? ''],
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
          isLinkedTenant(item?.tenantStatus?.systemName)
        ),
        listBusinessUnitFacilitiess(
          [item?.businessUnitManagement?.companyId ?? ''],
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
          isLinkedTenant(item?.tenantStatus?.systemName)
        ),
        listBusinessUnitCertificates(
          [item?.businessUnitManagement?.companyId ?? ''],
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
          isLinkedTenant(item?.tenantStatus?.systemName)
        ),
        listBusinessUnitStrengths(
          [item?.businessUnitManagement?.companyId ?? ''],
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
          isLinkedTenant(item?.tenantStatus?.systemName)
        ),
        listBusinessUnitDocuments([
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
        ]),
        listBusinessUnitSkills(
          [item?.businessUnitManagement?.companyId ?? ''],
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
          isLinkedTenant(item?.tenantStatus?.systemName)
        ),
        listBusinessUnitRankRequestContents(
          [item?.businessUnitManagement?.businessUnitManagementId ?? ''],
          isLinkedTenant(item?.tenantStatus?.systemName)
        ),
        listBusinessUnitRankReceptionContents([
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
        ]),
        listOwnCompanyBranchs([myTenantId ?? '']),
        listBusinessUnitChangeNotifications([
          item?.businessUnitManagement?.businessUnitManagementId ?? '',
        ]),
        listBusinessUnitPublicDocument([
          item?.businessUnitManagement?.companyId ?? '',
        ]),
      ]);

      setBusinessUnitContactContentsData(
        listBusinessUnitContactContentsRes.items ?? []
      );
      businessUnitBranchsData.current = listBusinessUnitBranchsRes.items ?? [];
      businessUnitFinancialsData.current =
        listBusinessUnitFinancialsRes.items ?? [];
      businessUnitFacilitiessData.current =
        listBusinessUnitFacilitiessRes.items ?? [];
      businessUnitCertificatesData.current =
        listBusinessUnitCertificatesRes.items ?? [];
      businessUnitStrengthsData.current =
        listBusinessUnitStrengthsRes.items ?? [];
      setBusinessUnitDocumentsData(listBusinessUnitDocumentsRes.items ?? []);
      businessUnitSkillsData.current = listBusinessUnitSkillsRes.items ?? [];
      businessUnitRankRequestData.current =
        listBusinessUnitRankRequestContentsRes.items ?? [];
      setBusinessUnitRankReceptionData(
        listBusinessUnitRankReceptionContentsRes.items ?? []
      );
      ownCompanyBranchData.current = listOwnCompanyBranchsRes.items ?? [];
      businessUnitChangeNotificationsData.current =
        listBusinessUnitChangeNotificationsRes.items ?? [];
      businessUnitPublicDocumentData.current =
        listBusinessUnitPublicDocumentRes.items ?? [];
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // タブ別更新通知データ取得
  const listBusinessUnitChangeNotificationsFilterTabName = (
    tabName: TabName
  ): mtechnavi.api.company.IBusinessUnitChangeNotification[] => {
    let typeName = '';
    switch (tabName) {
      case 'profile':
        typeName = 'mtechnavi.api.company.BusinessUnitProfile';
        break;
      case 'branch':
        typeName = 'mtechnavi.api.company.BusinessUnitBranch';
        break;
      case 'certificate':
        typeName = 'mtechnavi.api.company.BusinessUnitCertificate';
        break;
      case 'contact':
        typeName = 'mtechnavi.api.company.BusinessUnitContact';
        break;
      case 'rankRequest':
        typeName = 'mtechnavi.api.company.BusinessUnitRankReception';
        break;
      default:
        return [];
    }
    return businessUnitChangeNotificationsData.current.filter(
      (v) => v.typeName === typeName
    );
  };

  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      setLoading(true);
      try {
        const [{ presetItem }, getCompanyRes, skillTree, [roleNames]] =
          await Promise.all([
            getViewIdPreset(intl, VIEW_ID),
            window.App.services.ui.getMyCompany(),
            window.App.services.businessUnitManagementService.getBusinessUnitSkillTree(
              {}
            ),
            window.App.services.ui.getUserRoles(auth.user?.userGroupIds),
          ]);

        setPreset(presetItem);
        myCompanyInfo.current = getCompanyRes;
        skillTreeData.current = convertSkillTreeToCategorizedList(skillTree);
        roleNames.some((name) => name === 'Role-QualityManagement-Request')
          ? setVisibleRankRequestTab(true)
          : setVisibleRankRequestTab(false);
        roleNames.some((name) => name === 'Role-QualityManagement-Result')
          ? setVisibleRankReceptionTab(true)
          : setVisibleRankReceptionTab(false);

        const fetchLicenses = [
          LICENSE_NITERRA_BUSINESS,
          LICENSE_NITERRA_BUSINESS_BETA,
        ];
        const licenses = (
          (await window.App.services.ui.worker.filter({
            action: 'reload',
            fullMethodName: FullMethodName_SharedListLicenses,
            filter: {
              licenseCode: { $in: fetchLicenses },
            },
            sort: [],
          })) as mtechnavi.api.license.SharedListLicensesResponse
        ).items;

        if (
          licenses.length !== 0 &&
          licenses.some(
            (license) => license.licenseCode === LICENSE_NITERRA_BUSINESS
          )
        ) {
          setNiterraBusiness(true);
        }

        if (
          licenses.length !== 0 &&
          licenses.some(
            (license) => license.licenseCode === LICENSE_NITERRA_BUSINESS_BETA
          )
        ) {
          setNiterraBusinessBeta(true);
          setTabPanel('contact');
        } else {
          setNiterraBusinessBeta(false);
          roleNames.some((name) => name === 'Role-QualityManagement-Request')
            ? setVisibleRankRequestTab(true)
            : setVisibleRankRequestTab(false);
          roleNames.some((name) => name === 'Role-QualityManagement-Result')
            ? setVisibleRankReceptionTab(true)
            : setVisibleRankReceptionTab(false);
          setTabPanel(getTargetTabItem('profile'));
        }
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
    handleReloadManagement();

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // pageデータ変更時の処理
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.company.BusinessUnitManagementContent[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
    setNotificationShow(false);
    setTabPanel(getTargetTabItem());

    // page変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // メインデータ変更時
  useEffect(() => {
    if (!item) {
      return;
    }

    // 対象データ取得
    (async () => {
      await listAllData();
    })();

    // item変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, intl]);

  // タブ切り替え時の処理
  useEffect(() => {
    // 履歴データはタブごとに内容が変化するため、各タブに遷移したときに取得する
    switch (tabPanel) {
      case 'profile':
      case 'contact':
      case 'management':
        //履歴データを取得
        (async () => {
          await listHistoryData();
        })();
        break;
      default:
        setHistorys([]);
    }

    // 更新通知ダイアログのデータは各タブに遷移したときにセットする
    setChangeNotifications(getChangeNotificationItems());

    // tabPanel変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tabPanel,
    // 連続確認モードで別ページへ遷移した場合、直接URLから遷移した場合も同様の挙動とする
    item?.businessUnitManagement?.businessUnitManagementId,
  ]);

  // ステータス管理関連データ変更時
  useEffect(() => {
    // 制御対象を非活性化
    disableAllItems();

    // テナントステータス=紐付けありの場合のみ対象になるもの
    if (isLinkedTenant(item?.tenantStatus?.systemName)) {
      setVisibleBranchTab(true);
      setVisiblePublicDocumentTab(true);

      // 更新通知確認するボタン
      if (
        listBusinessUnitChangeNotificationsFilterTabName(tabPanel).length > 0
      ) {
        setVisibleChangeNotification(true);
      }
    }
    // 履歴
    switch (tabPanel) {
      case 'profile':
      case 'contact':
      case 'management':
        setVisibleHistory(true);
        break;
      default:
      // それ以外は表示しない
    }
    // ステータス管理関連データ 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    item?.tenantStatus,
    tabPanel,
    businessUnitChangeNotificationsData.current.length,
  ]);

  // タブの表示制御
  useEffect(() => {
    const tb: TabName[] = [];
    if (isNiterraBusinessBeta) {
      tb.push('contact');
      if (isVisiblePublicDocumentTab) tb.push('publicDocument');
    } else {
      tb.push('profile');
      tb.push('contact');
      if (isVisibleBranchTab) tb.push('branch');
      tb.push('financial');
      tb.push('facilities');
      tb.push('certificate');
      tb.push('strength');
      tb.push('document');
      tb.push('management');
      tb.push('skill');
      if (isVisibleRankRequestTab) tb.push('rankRequest');
      if (isVisibleRankReceptionTab) tb.push('rankReception');
      if (isVisiblePublicDocumentTab) tb.push('publicDocument');
    }
    setTabs(tb);
  }, [
    isVisibleBranchTab,
    isVisiblePublicDocumentTab,
    isVisibleRankRequestTab,
    isVisibleRankReceptionTab,
    isNiterraBusinessBeta,
  ]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="BusinessUnitManagementConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath="/businessunitmanagement-list"
              iconItems={getPageNavigationIconItems()}
              pageInfo={{
                isVisibleMoveNavi: true,
                isFirstPage,
                isMaxPage,
                pageNumber: page.pageNumber,
              }}
              handleMovePage={handleMovePage}
              infoOption={{
                lastUpdateInfo: { isVisibleUpdateInfo: false },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
            />
          </div>
          <div className="info">
            <div className="basic-info-body">
              {/* ////通知枠//// */}
              <div
                className={`notification-area ${
                  isNotificationShow ? '' : 'close'
                }`}
              >
                {/* {履歴通知エリア} */}
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      <div className="notification-display-area">
                        {historyMessage}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="code"
                      className="field"
                      type="text"
                      disabled={true}
                      value={item?.businessUnitManagement?.code ?? ''}
                      labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.code"
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="displayName"
                      className="field"
                      type="text"
                      disabled={true}
                      value={item?.businessUnitManagement?.displayName ?? ''}
                      labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.displayName"
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="status"
                      className="field"
                      type="text"
                      disabled={true}
                      value={
                        item?.businessUnitManagement?.status?.displayNameLang
                          ?.ja ?? ''
                      }
                      labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.status"
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-20">
                    {isVisibleChangeNotification && (
                      <CaptionButton
                        buttonType="basic"
                        caption="更新通知を確認"
                        className="confirmBtn"
                        name="confirmBtn"
                        onClick={() => {
                          setBusinessUnitChangeNotificationDialogOpen(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="tabPanelContents">
                {tabs.map((v, index) => {
                  const selectPane = v === tabPanel ? 'current' : '';
                  return (
                    <div
                      key={`tab-${index}`}
                      className={`tabPanel ${selectPane}`}
                      onClick={() => setTabPanel(v)}
                    >
                      {GetMessageWithIntl(intl, { prefixId: VIEW_ID, id: v })}
                      {listBusinessUnitChangeNotificationsFilterTabName(v)
                        .length > 0 && (
                        <PriorityHighIcon className="notification-icon" />
                      )}
                    </div>
                  );
                })}
              </div>
              {isCurrentTab('profile') && (
                <ProfileTabItem
                  preset={preset}
                  businessUnitProfile={item?.businessUnitProfile ?? {}}
                />
              )}
              {isCurrentTab('contact') && (
                <ContactTabItem
                  businessUnitContactContents={businessUnitContactContentsData}
                  businessUnitManagementContent={item ?? {}}
                  myCompanyInfo={myCompanyInfo.current}
                  isNiterraBusiness={isNiterraBusiness}
                  onChangeLoadingState={setLoading}
                  handleReload={handleReloadContact}
                />
              )}
              {isCurrentTab('branch') && (
                <BranchTabItem
                  businessUnitBranchs={businessUnitBranchsData.current}
                />
              )}
              {isCurrentTab('financial') && (
                <FinancialTabItem
                  businessUnitFinancials={businessUnitFinancialsData.current}
                />
              )}
              {isCurrentTab('facilities') && (
                <FacilitiesTabItem
                  businessUnitFacilitiess={businessUnitFacilitiessData.current}
                  businessUnitBranchs={businessUnitBranchsData.current}
                />
              )}
              {isCurrentTab('certificate') && (
                <CertificateTabItem
                  businessUnitCertificates={
                    businessUnitCertificatesData.current
                  }
                />
              )}
              {isCurrentTab('strength') && (
                <StrengthTabItem
                  businessUnitStrengths={businessUnitStrengthsData.current}
                  businessUnitFacilitiess={businessUnitFacilitiessData.current}
                  businessUnitBranchs={businessUnitBranchsData.current}
                />
              )}
              {isCurrentTab('document') && (
                <DocumentTabItem
                  businessUnitDocuments={businessUnitDocumentsData}
                  businessUnitManagementContent={item ?? {}}
                  onChangeLoadingState={setLoading}
                  handleReload={handleReloadDocument}
                />
              )}
              {isCurrentTab('management') && (
                <ManagementTabItem
                  businessUnitManagementContent={item ?? {}}
                  onChangeLoadingState={setLoading}
                  handleReload={handleReloadManagement}
                />
              )}
              {isCurrentTab('skill') && (
                <SkillTabItem
                  skillTreeData={skillTreeData.current}
                  businessUnitSkills={businessUnitSkillsData.current}
                />
              )}
              {isCurrentTab('rankRequest') && (
                <RankRequestTabItem
                  businessUnitRankContents={businessUnitRankRequestData.current}
                  businessUnitManagementContent={item ?? {}}
                  onChangeLoadingState={setLoading}
                />
              )}
              {isCurrentTab('rankReception') && (
                <RankReceptionTabItem
                  businessUnitRankContents={businessUnitRankReceptionData}
                  businessUnitManagementContent={item ?? {}}
                  ownCompanyBranchs={ownCompanyBranchData.current}
                  onChangeLoadingState={setLoading}
                  handleReload={handleReloadRankReceptionContent}
                />
              )}
              {isCurrentTab('publicDocument') && (
                <PublicDocumentTabItem
                  businessUnitPublicDocuments={
                    businessUnitPublicDocumentData.current
                  }
                />
              )}
            </div>
          </div>
        </div>
        {/* 履歴ダイアログ */}
        <HistoryDialog
          isOpen={isHistoryOpen}
          messageOption={{
            headerLabelId: {
              id: 'history',
              prefixId: 'DIALOG_TITLE',
            },
          }}
          inputOption={{
            data: historys ?? [],
            keyColumn: 'history_id',
            columns: historyColumns,
          }}
          onCancel={() => {
            setHistoryOpen(false);
          }}
        />
        {/* 更新通知ダイアログ */}
        <ChangeNotificationDialog
          isOpen={isBusinessUnitChangeNotificationDialogOpen}
          inputOption={{
            data: changeNotifications,
            keyColumn: 'changeNotificationId',
            columns: changeNotificationColumns,
          }}
          onCancel={() => {
            setBusinessUnitChangeNotificationDialogOpen(false);
          }}
          onDecision={() => {
            handleConfirmedChangeNotification();
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
