import {
  Container,
  error,
  GetMessageWithIntl,
  Radio,
  Toast,
} from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  getExceptionMessage,
  getRandomStringId,
  getWorkerExceptionMessage,
  includeInputValidateError,
  RequireInput,
  ViewId,
} from '~/shared/utils';
import './BlueprintBlueprintSearch.css';
import { FileItem, FileUploader } from '~/shared/components/file';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  BLUEPRINT_SEARCH_LIMIT,
  BlueprintSearchCondition,
  getBlueprintSearchState,
  saveBlueprintSearchState,
} from '../BlueprintBlueprintSearchResult/util';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useBlueprintSearch } from '../BlueprintSearchProvider';
import {
  validateNumberMinMaxOverDigit,
  validateOnlyNumberWithMaxMin,
} from '~/shared/components/parts/validator';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { Checkbox, Textbox } from '~/shared/components/ui';
import React from 'react';
import { ConditionCheckbox } from '~/tenant/company/pages/CompanySearch/parts/ConditionCheckbox';
import { useIntl } from 'react-intl';
import {
  SegmentConfirmationDialog,
  SegmentConfirmationDialogResult,
} from '~/shared/components/ui/Dialog/SegmentConfirmationDialog';
import { FullMethodName_ListBlueprintDisplayAttributes } from '~/worker';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { BlueprintSegmentNames } from '../../utils';

const VIEW_ID: ViewId = 'BPR_BLUEPRINT_SEARCH';

interface CheckBoxItem {
  id: string;
  displayName: string;
  order?: number;
}
export const BlueprintBlueprintSearch = () => {
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';
  const navi = useNavigate();
  const { search } = useBlueprintSearch();

  const [isOpenSegmentConfirmation, setOpenSegmentConfirmation] =
    useState(false);
  const { showLoading, hideLoading } = useLoading();

  // 以前の検索条件
  const savedConditions = useMemo(
    () => getBlueprintSearchState(VIEW_ID, myEmail),
    [myEmail]
  );

  // 画像検索
  const [filename, setFilename] = useState<string | null | undefined>(
    savedConditions?.file?.filename ?? null
  );
  const [temporaryImage, setTemporaryImage] = useState<
    | mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.ICreatedImage
    | null
    | undefined
  >(savedConditions?.file?.temporaryImage);
  const [temporarySegmentProperties, setTemporarySegmentProperties] = useState<
    mtechnavi.api.blueprint.ITemporarySegmentProperty[] | null | undefined
  >(savedConditions?.file?.segments);
  const [isFront, setFront] = useState(
    savedConditions?.shape?.classNames?.includes(BlueprintSegmentNames.Front)
  );
  const [isRight, setRight] = useState(
    savedConditions?.shape?.classNames?.includes(BlueprintSegmentNames.Right)
  );
  const [isBottom, setBottom] = useState(
    savedConditions?.shape?.classNames?.includes(BlueprintSegmentNames.Bottom)
  );
  const [topLevel, setTopLevel] = useState(savedConditions?.shape?.topX ?? '');
  // 詳細検索
  const [keywords, setKeywords] = useState(
    savedConditions?.keyword?.text ?? ''
  );
  const [matchType, setMatchType] = useState(
    savedConditions?.keyword?.matchType ?? 'or'
  );
  const [leadNumber, setLeadNumber] = useState(
    savedConditions?.amount?.leadNumber?.value ?? ''
  );
  const [leadNumberLower, setLeadNumberLower] = useState(
    savedConditions?.amount?.leadNumber?.tolerance?.lower ?? '0'
  );
  const [leadNumberUpper, setLeadNumberUpper] = useState(
    savedConditions?.amount?.leadNumber?.tolerance?.upper ?? '0'
  );
  const [unit, setUnit] = useState(savedConditions?.size?.unit ?? 'mm');
  const [outerX, setOuterX] = useState(
    savedConditions?.size?.outerX?.value ?? ''
  );
  const [outerXLower, setOuterXLower] = useState(
    savedConditions?.size?.outerX?.tolerance?.lower ?? '0'
  );
  const [outerXUpper, setOuterXUpper] = useState(
    savedConditions?.size?.outerX?.tolerance?.upper ?? '0'
  );
  const [outerY, setOuterY] = useState(
    savedConditions?.size?.outerY?.value ?? ''
  );
  const [outerYLower, setOuterYLower] = useState(
    savedConditions?.size?.outerY?.tolerance?.lower ?? '0'
  );
  const [outerYUpper, setOuterYUpper] = useState(
    savedConditions?.size?.outerY?.tolerance?.upper ?? '0'
  );
  const [topCV, setTopCV] = useState(savedConditions?.size?.topCV?.value ?? '');
  const [topCVLower, setTopCVLower] = useState(
    savedConditions?.size?.topCV?.tolerance?.lower ?? '0'
  );
  const [topCVUpper, setTopCVUpper] = useState(
    savedConditions?.size?.topCV?.tolerance?.upper ?? '0'
  );
  const [secondCV, setSecondCV] = useState(
    savedConditions?.size?.secondCV?.value ?? ''
  );
  const [secondCVLower, setSecondCVLower] = useState(
    savedConditions?.size?.secondCV?.tolerance?.lower ?? '0'
  );
  const [secondCVUpper, setSecondCVUpper] = useState(
    savedConditions?.size?.secondCV?.tolerance?.upper ?? '0'
  );
  const [da, setDa] = useState(savedConditions?.size?.da?.value ?? '');
  const [daLower, setDaLower] = useState(
    savedConditions?.size?.da?.tolerance?.lower ?? '0'
  );
  const [daUpper, setDaUpper] = useState(
    savedConditions?.size?.da?.tolerance?.upper ?? '0'
  );
  const [boardWarp, setBoardWarp] = useState(
    savedConditions?.size?.boardWarp?.value ?? ''
  );
  const [boardWarpLower, setBoardWarpLower] = useState(
    savedConditions?.size?.boardWarp?.tolerance?.lower ?? '0'
  );
  const [boardWarpUpper, setBoardWarpUpper] = useState(
    savedConditions?.size?.boardWarp?.tolerance?.upper ?? '0'
  );
  const [srWarp, setSrWarp] = useState(
    savedConditions?.size?.srWarp?.value ?? ''
  );
  const [srWarpLower, setSrWarpLower] = useState(
    savedConditions?.size?.srWarp?.tolerance?.lower ?? '0'
  );
  const [srWarpUpper, setSrWarpUpper] = useState(
    savedConditions?.size?.srWarp?.tolerance?.upper ?? '0'
  );
  const [daWarp, setDaWarp] = useState(
    savedConditions?.size?.daWarp?.value ?? ''
  );
  const [daWarpLower, setDaWarpLower] = useState(
    savedConditions?.size?.daWarp?.tolerance?.lower ?? '0'
  );
  const [daWarpUpper, setDaWarpUpper] = useState(
    savedConditions?.size?.daWarp?.tolerance?.upper ?? '0'
  );
  const [displayAttributes, setDisplayAttributes] = useState<CheckBoxItem[]>(
    []
  );
  const [checkedAttributes, setCheckedAttributes] = useState<CheckBoxItem[]>(
    []
  );

  /** 必須チェックのための `上位` 入力項目への参照 */
  const topLevelRef = useRef(null);

  /** 形状条件操作不可フラグ */
  const disableSegmentCondition = !temporaryImage;

  /** 各セグメントが抽出・指定されているかフラグ */
  const existsFrontSegment = hasSegment(
    temporarySegmentProperties,
    BlueprintSegmentNames.Front
  );
  const existsRightSegment = hasSegment(
    temporarySegmentProperties,
    BlueprintSegmentNames.Right
  );
  const existsBottomSegment = hasSegment(
    temporarySegmentProperties,
    BlueprintSegmentNames.Bottom
  );
  const existsSomeSegments =
    existsFrontSegment || existsRightSegment || existsBottomSegment;

  /** 上位nの必須フラグ */
  const requireTopLevel = isFront || isRight || isBottom;

  /** サイズ・リード数の値と許容誤差バリデーションを作成する */
  const getSizeConditionValidator = () => {
    return validateNumberMinMaxOverDigit(intl, 2, 0, 999999.99);
  };

  /** 初期データの取得 */
  useEffect(() => {
    (async () => {
      try {
        showLoading();
        const displayAttributesRes =
          await window.App.services.ui.worker.filter<mtechnavi.api.blueprint.IListBlueprintDisplayAttributesResponse>(
            {
              action: 'reload',
              fullMethodName: FullMethodName_ListBlueprintDisplayAttributes,
              filter: {},
              sort: [{ order: 'asc' }],
            }
          );
        const displayAttributeList =
          displayAttributesRes?.items.at(0)?.displayAttributes ?? [];
        const setItem = displayAttributeList.map((item) => ({
          id: getRandomStringId(),
          displayName: item.attributeName ?? '',
          originalOrder: item.order,
        }));
        setDisplayAttributes(setItem);
        setCheckedAttributes(
          setItem
            ?.filter((item) =>
              savedConditions?.attribute?.includes(item?.displayName ?? '')
            )
            .map((item) => item)
        );
      } catch (err) {
        console.error(err);
        error(getWorkerExceptionMessage(intl, err));
      }
      hideLoading();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * ファイルアップロード処理
   *
   * ファイルから検索用の画像リソースを作成しセグメントを抽出する
   */
  const handleFileUpload = async (files: FileItem[]) => {
    const file = files.at(0);
    if (!file || file?.status !== 'OK') {
      return;
    }
    setFilename(file?.file.name);
    try {
      showLoading();
      // アップロードPDFをPNGに変換
      const result = await window.App.services.ui.worker.apiCall({
        actionName: 'createTemporaryImage',
        request: {
          sourceUrl: file.url,
          imagePropertys: [
            {
              name: 'base',
              format: mtechnavi.api.assetinventory.ImageFormat.PNG,
            },
          ],
        },
      });
      const resultCreateImage = result.at(0)?.createdImages?.at(0);
      if (!resultCreateImage?.signedUrl) {
        return;
      }
      setTemporaryImage(resultCreateImage);
      // セグメント抽出
      const resultSegment = await window.App.services.ui.worker.apiCall({
        actionName: 'extractBlueprintImageSegment',
        request: { url: resultCreateImage?.signedUrl },
      });
      const segmentProperties = resultSegment?.at(0)?.temporarySegmentPropertys;
      setTemporarySegmentProperties(segmentProperties);
      // 抽出されなかった面のチェックボックスを選択解除する
      setFront((prev) =>
        hasSegment(segmentProperties, BlueprintSegmentNames.Front)
          ? prev
          : false
      );
      setRight((prev) =>
        hasSegment(segmentProperties, BlueprintSegmentNames.Right)
          ? prev
          : false
      );
      setBottom((prev) =>
        hasSegment(segmentProperties, BlueprintSegmentNames.Bottom)
          ? prev
          : false
      );
      setTopLevel('');
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  /** セグメントの更新 */
  const handleSaveSegment = async (result: SegmentConfirmationDialogResult) => {
    if (!result?.segmentPropertys?.length) {
      setTemporarySegmentProperties([]);
      setFront(false);
      setRight(false);
      setBottom(false);
      setTopLevel('');
      setOpenSegmentConfirmation(false);
      return;
    }
    showLoading();
    try {
      // セグメント確認、保存
      const request: mtechnavi.api.assetinventory.ICreateTemporaryImagesRequest =
        {
          sourceUrl: temporaryImage?.signedUrl,
          imagePropertys: (result?.segmentPropertys ?? []).map((item) => ({
            name: item.segmentName ?? '',
            format: mtechnavi.api.assetinventory.ImageFormat.PNG,
            option: {
              crop: true,
              width: item.width,
              height: item.height,
              top: item.top,
              left: item.left,
            },
          })),
        };
      const resultCreateTempImages =
        (
          await window.App.services.ui.worker.apiCall({
            request: request,
            actionName: 'createTemporaryImage',
          })
        ).at(0)?.createdImages ?? [];

      const newSegmentProperties: mtechnavi.api.blueprint.ITemporarySegmentProperty[] =
        (result?.segmentPropertys ?? []).map((item) => {
          const segmentImage = resultCreateTempImages.find(
            (image) => image.name === item.segmentName
          );
          return {
            url: segmentImage?.signedUrl,
            segmentProperty: {
              ...item,
              assetId: segmentImage?.assetId,
            },
          };
        });
      setTemporarySegmentProperties(newSegmentProperties);
      // 抽出されなかった面のチェックボックスを選択解除する
      setFront((prev) =>
        hasSegment(newSegmentProperties, BlueprintSegmentNames.Front)
          ? prev
          : false
      );
      setRight((prev) =>
        hasSegment(newSegmentProperties, BlueprintSegmentNames.Right)
          ? prev
          : false
      );
      setBottom((prev) =>
        hasSegment(newSegmentProperties, BlueprintSegmentNames.Bottom)
          ? prev
          : false
      );
      setOpenSegmentConfirmation(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  /** 入力エラーチェック */
  const isInputError = (): boolean => {
    const requireInputs: RequireInput[] = [];
    if (requireTopLevel) {
      requireInputs.push({ value: topLevel, ref: topLevelRef });
    }
    return includeInputValidateError(document, intl, requireInputs);
  };

  /** 検索 */
  const handleSearch = async () => {
    if (isInputError()) {
      return;
    }
    showLoading();
    const shapeClassNames: BlueprintSegmentNames[] = [];
    if (isFront) {
      shapeClassNames.push(BlueprintSegmentNames.Front);
    }
    if (isRight) {
      shapeClassNames.push(BlueprintSegmentNames.Right);
    }
    if (isBottom) {
      shapeClassNames.push(BlueprintSegmentNames.Bottom);
    }
    try {
      const condition: BlueprintSearchCondition = {
        file: {
          filename,
          temporaryImage,
          segments: temporarySegmentProperties,
        },
        shape: {
          topX: topLevel,
          classNames: shapeClassNames,
        },
        keyword: {
          text: keywords,
          matchType: matchType,
        },
        amount: {
          leadNumber: {
            value: removeMinus(leadNumber),
            tolerance: {
              lower: removeMinus(leadNumberLower),
              upper: removeMinus(leadNumberUpper),
            },
          },
        },
        size: {
          unit: unit,
          outerX: {
            value: removeMinus(outerX),
            tolerance: {
              lower: removeMinus(outerXLower),
              upper: removeMinus(outerXUpper),
            },
          },
          outerY: {
            value: removeMinus(outerY),
            tolerance: {
              lower: removeMinus(outerYLower),
              upper: removeMinus(outerYUpper),
            },
          },
          topCV: {
            value: removeMinus(topCV),
            tolerance: {
              lower: removeMinus(topCVLower),
              upper: removeMinus(topCVUpper),
            },
          },
          secondCV: {
            value: removeMinus(secondCV),
            tolerance: {
              lower: removeMinus(secondCVLower),
              upper: removeMinus(secondCVUpper),
            },
          },
          da: {
            value: removeMinus(da),
            tolerance: {
              lower: removeMinus(daLower),
              upper: removeMinus(daUpper),
            },
          },
          boardWarp: {
            value: removeMinus(boardWarp),
            tolerance: {
              lower: removeMinus(boardWarpLower),
              upper: removeMinus(boardWarpUpper),
            },
          },
          srWarp: {
            value: removeMinus(srWarp),
            tolerance: {
              lower: removeMinus(srWarpLower),
              upper: removeMinus(srWarpUpper),
            },
          },
          daWarp: {
            value: removeMinus(daWarp),
            tolerance: {
              lower: removeMinus(daWarpLower),
              upper: removeMinus(daWarpUpper),
            },
          },
        },
        attribute: checkedAttributes.map((item) => item.displayName),
      };

      // エンドポイントへリクエストし、ウェイトを掛けた上で結果画面に遷移する
      search({ condition });
      saveBlueprintSearchState(VIEW_ID, condition, myEmail);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      navi('/blueprint/blueprint-search-result');
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  const removeMinus = (num: string) => {
    const reg = new RegExp('^-0+(\\.0+)?$');
    const newNum = reg.test(num) ? num.replace('-', '') : num;
    const zeroCheck = new RegExp('^0+$');
    return zeroCheck.test(newNum) ? '0' : newNum;
  };

  const handleReset = () => {
    setFilename(null);
    setTemporaryImage(null);
    setTemporarySegmentProperties(null);
    setFront(false);
    setRight(false);
    setBottom(false);
    setTopLevel('');
    setKeywords('');
    setMatchType('or');
    setLeadNumber('');
    setLeadNumberLower('0');
    setLeadNumberUpper('0');
    setUnit('mm');
    setOuterX('');
    setOuterXLower('0');
    setOuterXUpper('0');
    setOuterY('');
    setOuterYLower('0');
    setOuterYUpper('0');
    setTopCV('');
    setTopCVLower('0');
    setTopCVUpper('0');
    setSecondCV('');
    setSecondCVLower('0');
    setSecondCVUpper('0');
    setDa('');
    setDaLower('0');
    setDaUpper('0');
    setBoardWarp('');
    setBoardWarpLower('0');
    setBoardWarpUpper('0');
    setSrWarp('');
    setSrWarpLower('0');
    setSrWarpUpper('0');
    setDaWarp('');
    setDaWarpLower('0');
    setDaWarpUpper('0');
    setCheckedAttributes([]);
  };

  const handleFileDelete = (files: FileItem[]) => {
    if (files.length > 0) {
      return;
    }
    setFilename(null);
    setTemporaryImage(null);
    setTemporarySegmentProperties(null);
    setFront(false);
    setRight(false);
    setBottom(false);
    setTopLevel('');
  };

  const handleLoading = (isLoading: boolean) => {
    isLoading ? showLoading() : hideLoading();
  };

  const handleChange = (id: string, checked: boolean) => {
    if (checked) {
      setCheckedAttributes([
        ...checkedAttributes,
        ...displayAttributes.filter((item) => item.id === id),
      ]);
    } else {
      setCheckedAttributes(checkedAttributes.filter((item) => item.id !== id));
    }
  };

  return (
    <Container viewId={VIEW_ID}>
      <div className="BlueprintBlueprintSearch">
        <h2 className="section-head">
          {GetMessageWithIntl(intl, {
            prefixId: VIEW_ID,
            id: 'sectionHeader.image',
          })}
        </h2>
        <div className="condition-section">
          <dl className="detail-condition image">
            <dt>
              {GetMessageWithIntl(intl, {
                prefixId: VIEW_ID,
                id: 'image.imageFile',
              })}
            </dt>
            <dd>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-100">
                    <FileUploader
                      name="upload"
                      validateOption={{
                        allowedFileExtensions: ['pdf'],
                      }}
                      onUpload={handleFileUpload}
                      onDelete={handleFileDelete}
                      onChangeLoadingState={(isLoading) => {
                        handleLoading(isLoading);
                      }}
                    />
                  </div>
                </div>
              </div>
            </dd>
            <dt>
              {GetMessageWithIntl(intl, {
                prefixId: VIEW_ID,
                id: 'image.shape',
              })}
            </dt>
            <dd>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Checkbox
                      name={'frontCheck'}
                      className=""
                      columns={['frontCheck']}
                      items={[
                        {
                          value: '1',
                          displayName: GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'image.shape.front',
                          }),
                        },
                      ]}
                      value={[isFront ? '1' : '']}
                      onChangeState={(val) => {
                        setFront(val.includes('1'));
                      }}
                      disabled={disableSegmentCondition || !existsFrontSegment}
                    />
                  </div>
                  <div className="w-20">
                    <Checkbox
                      name={'rightCheck'}
                      className=""
                      columns={['rightCheck']}
                      items={[
                        {
                          value: '1',
                          displayName: GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'image.shape.right',
                          }),
                        },
                      ]}
                      value={[isRight ? '1' : '']}
                      onChangeState={(val) => {
                        setRight(val.includes('1'));
                      }}
                      disabled={disableSegmentCondition || !existsRightSegment}
                    />
                  </div>
                  <div className="w-20">
                    <Checkbox
                      name={'bottomCheck'}
                      className=""
                      columns={['bottomCheck']}
                      items={[
                        {
                          value: '1',
                          displayName: GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'image.shape.bottom',
                          }),
                        },
                      ]}
                      value={[isBottom ? '1' : '']}
                      onChangeState={(val) => {
                        setBottom(val.includes('1'));
                      }}
                      disabled={disableSegmentCondition || !existsBottomSegment}
                    />
                  </div>
                  <div className="w-40 segment-button">
                    <CaptionButton
                      name="segment"
                      caption={GetMessageWithIntl(intl, {
                        prefixId: VIEW_ID,
                        id: 'image.segmentDialog',
                      })}
                      buttonType="basic"
                      onClick={() => {
                        setOpenSegmentConfirmation(true);
                      }}
                      disabled={disableSegmentCondition}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line" ref={topLevelRef}>
                <div className="item-group-100">
                  <div className="w-40">
                    <div className="label-with-input">
                      <span className="label">
                        {GetMessageWithIntl(intl, {
                          prefixId: VIEW_ID,
                          id: 'image.shape.topLevel',
                        })}
                      </span>
                      <Textbox
                        name="topLevel"
                        columns={['topLevel']}
                        value={topLevel}
                        validator={validateOnlyNumberWithMaxMin(
                          intl,
                          1,
                          BLUEPRINT_SEARCH_LIMIT
                        )}
                        validateOption={{ required: requireTopLevel }}
                        type="text"
                        onChangeState={setTopLevel}
                        disabled={
                          disableSegmentCondition || !existsSomeSegments
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </dd>
          </dl>
        </div>
        {/* TODO：将来的にライセンスで検索条件を変更する予定 */}
        <h2 className="section-head">
          {GetMessageWithIntl(intl, {
            prefixId: VIEW_ID,
            id: 'sectionHeader.detail',
          })}
        </h2>
        <div className="condition-section">
          <dl className="detail-condition detail">
            <dt>
              {GetMessageWithIntl(intl, {
                prefixId: VIEW_ID,
                id: 'detail.keyword',
              })}
            </dt>
            <dd>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-77">
                    <Textbox
                      name="keywords"
                      className="keywords"
                      columns={['keywords']}
                      value={keywords}
                      type="text"
                      onChangeState={setKeywords}
                    />
                  </div>
                  <div className="w-20">
                    <Radio
                      name="searchOption"
                      className="searchOption"
                      value={matchType}
                      items={[
                        {
                          value: 'or',
                          displayName: 'or',
                        },
                        {
                          value: 'and',
                          displayName: 'and',
                        },
                      ]}
                      onChangeState={(val) =>
                        val === 'or' || val === 'and'
                          ? setMatchType(val)
                          : setMatchType('or')
                      }
                    />
                  </div>
                </div>
              </div>
              <p className="search-direction">
                {GetMessageWithIntl(intl, {
                  prefixId: VIEW_ID,
                  id: 'detail.searchDirection',
                })}
              </p>
            </dd>
            <dt>
              {GetMessageWithIntl(intl, {
                prefixId: VIEW_ID,
                id: 'detail.amount',
              })}
            </dt>
            <dd>
              <div className="indent size">
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.amount.leadNumber',
                          })}
                        </p>
                        <Textbox
                          name="leadNumber"
                          className=""
                          columns={['leadNumber']}
                          value={leadNumber}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setLeadNumber}
                        />
                      </div>
                    </div>
                    <div className="w-66">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.errorMargin',
                          })}
                        </p>
                        <p className="prefix">-</p>
                        <Textbox
                          name="leadNumber.min"
                          className=""
                          columns={['leadNumber.min']}
                          value={leadNumberLower}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setLeadNumberLower}
                        />
                        <p>~</p>
                        <Textbox
                          name="leadNumber.max"
                          className=""
                          columns={['leadNumber.max']}
                          value={leadNumberUpper}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setLeadNumberUpper}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </dd>
            <dt>
              {GetMessageWithIntl(intl, {
                prefixId: VIEW_ID,
                id: 'detail.size',
              })}
            </dt>
            <dd>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-33">
                    <Radio
                      name="unit"
                      className="unit"
                      value={unit}
                      items={[
                        {
                          value: 'mm',
                          displayName: 'mm',
                        },
                        {
                          value: 'inch',
                          displayName: 'inch',
                        },
                      ]}
                      onChangeState={(val) =>
                        val === 'mm' || val === 'inch'
                          ? setUnit(val)
                          : setUnit('mm')
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="indent size">
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.size.outerX',
                          })}
                        </p>
                        <Textbox
                          name="outerX"
                          className=""
                          columns={['outerX']}
                          value={outerX}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setOuterX}
                        />
                      </div>
                    </div>
                    <div className="w-66">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.errorMargin',
                          })}
                        </p>
                        <p className="prefix">-</p>
                        <Textbox
                          name="outerX.min"
                          className=""
                          columns={['outerX.min']}
                          value={outerXLower}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setOuterXLower}
                        />
                        <p>~</p>
                        <Textbox
                          name="outerX.max"
                          className=""
                          columns={['outerX.max']}
                          value={outerXUpper}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setOuterXUpper}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.size.outerY',
                          })}
                        </p>
                        <Textbox
                          name="outerY"
                          className=""
                          columns={['outerY']}
                          value={outerY}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setOuterY}
                        />
                      </div>
                    </div>
                    <div className="w-66">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.errorMargin',
                          })}
                        </p>
                        <p className="prefix">-</p>
                        <Textbox
                          name="outerY.min"
                          className=""
                          columns={['outerY.min']}
                          value={outerYLower}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setOuterYLower}
                        />
                        <p>~</p>
                        <Textbox
                          name="outerY.max"
                          className=""
                          columns={['outerY.max']}
                          value={outerYUpper}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setOuterYUpper}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.size.topCV',
                          })}
                        </p>
                        <Textbox
                          name="topCV"
                          className=""
                          columns={['topCV']}
                          value={topCV}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setTopCV}
                        />
                      </div>
                    </div>
                    <div className="w-66">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.errorMargin',
                          })}
                        </p>
                        <p className="prefix">-</p>
                        <Textbox
                          name="topCV.min"
                          className=""
                          columns={['topCV.min']}
                          value={topCVLower}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setTopCVLower}
                        />
                        <p>~</p>
                        <Textbox
                          name="topCV.max"
                          className=""
                          columns={['topCV.max']}
                          value={topCVUpper}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setTopCVUpper}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.size.secondCV',
                          })}
                        </p>
                        <Textbox
                          name="secondCV"
                          className=""
                          columns={['secondCV']}
                          value={secondCV}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setSecondCV}
                        />
                      </div>
                    </div>
                    <div className="w-66">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.errorMargin',
                          })}
                        </p>
                        <p className="prefix">-</p>
                        <Textbox
                          name="secondCV.min"
                          className=""
                          columns={['secondCV.min']}
                          value={secondCVLower}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setSecondCVLower}
                        />
                        <p>~</p>
                        <Textbox
                          name="secondCV.max"
                          className=""
                          columns={['secondCV.max']}
                          value={secondCVUpper}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setSecondCVUpper}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.size.da',
                          })}
                        </p>
                        <Textbox
                          name="da"
                          className=""
                          columns={['da']}
                          value={da}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setDa}
                        />
                      </div>
                    </div>
                    <div className="w-66">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.errorMargin',
                          })}
                        </p>
                        <p className="prefix">-</p>
                        <Textbox
                          name="da.min"
                          className=""
                          columns={['da.min']}
                          value={daLower}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setDaLower}
                        />
                        <p>~</p>
                        <Textbox
                          name="da.max"
                          className=""
                          columns={['da.max']}
                          value={daUpper}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setDaUpper}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.size.boardWarp',
                          })}
                        </p>
                        <Textbox
                          name="boardWarp"
                          className=""
                          columns={['boardWarp']}
                          value={boardWarp}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setBoardWarp}
                        />
                      </div>
                    </div>
                    <div className="w-66">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.errorMargin',
                          })}
                        </p>
                        <p className="prefix">-</p>
                        <Textbox
                          name="boardWarp.min"
                          className=""
                          columns={['boardWarp.min']}
                          value={boardWarpLower}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setBoardWarpLower}
                        />
                        <p>~</p>
                        <Textbox
                          name="boardWarp.max"
                          className=""
                          columns={['boardWarp.max']}
                          value={boardWarpUpper}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setBoardWarpUpper}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.size.srWarp',
                          })}
                        </p>
                        <Textbox
                          name="srWarp"
                          className=""
                          columns={['srWarp']}
                          value={srWarp}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setSrWarp}
                        />
                      </div>
                    </div>
                    <div className="w-66">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.errorMargin',
                          })}
                        </p>
                        <p className="prefix">-</p>
                        <Textbox
                          name="srWarp.min"
                          className=""
                          columns={['srWarp.min']}
                          value={srWarpLower}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setSrWarpLower}
                        />
                        <p>~</p>
                        <Textbox
                          name="srWarp.max"
                          className=""
                          columns={['srWarp.max']}
                          value={srWarpUpper}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setSrWarpUpper}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-33">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.size.daWarp',
                          })}
                        </p>
                        <Textbox
                          name="daWarp"
                          className=""
                          columns={['daWarp']}
                          value={daWarp}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setDaWarp}
                        />
                      </div>
                    </div>
                    <div className="w-66">
                      <div className="label-with-input-with-space">
                        <p className="label">
                          {GetMessageWithIntl(intl, {
                            prefixId: VIEW_ID,
                            id: 'detail.errorMargin',
                          })}
                        </p>
                        <p className="prefix">-</p>
                        <Textbox
                          name="daWarp.min"
                          className=""
                          columns={['daWarp.min']}
                          value={daWarpLower}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setDaWarpLower}
                        />
                        <p>~</p>
                        <Textbox
                          name="daWarp.max"
                          className=""
                          columns={['daWarp.max']}
                          value={daWarpUpper}
                          type="text"
                          validator={getSizeConditionValidator()}
                          onChangeState={setDaWarpUpper}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </dd>
          </dl>
        </div>
        <h2 className="section-head">表示属性</h2>
        <div className="condition-section">
          <dl className="detail-condition attributes">
            <dt>属性情報</dt>
            <dd>
              <div className="condition-list-item-detail">
                {displayAttributes.map((attribute) => (
                  <React.Fragment key={attribute.id}>
                    <ConditionCheckbox
                      value={attribute.id || ''}
                      checked={checkedAttributes.some(
                        (item) => item.id === attribute.id
                      )}
                      onChange={(id, checked) =>
                        handleChange(attribute.id || '', checked)
                      }
                      label={attribute.displayName || ''}
                    />
                  </React.Fragment>
                ))}
              </div>
            </dd>
          </dl>
        </div>
        {/* フッター */}
        <div className="footer">
          <div className="footer-contents">
            <CaptionButton
              buttonType="basic"
              name="search"
              caption="検索"
              onClick={handleSearch}
            />
            <CaptionButton
              name="reset"
              className="fetch-reset-button"
              caption="条件解除"
              onClick={handleReset}
            />
          </div>
        </div>
      </div>
      <>
        {/* セグメント確認ダイアログ */}
        <SegmentConfirmationDialog
          key={isOpenSegmentConfirmation ? 1 : 0}
          isOpen={isOpenSegmentConfirmation}
          messageOption={{
            headerLabel: {
              prefixId: 'DIALOG_TITLE',
              id: 'SegmentConfirmationDialog',
            },
          }}
          inputOption={{
            assetId: temporaryImage?.assetId ?? '',
            mode: 'searchCondition',
            segment: {
              imageProperty: {
                imageAssetId: temporaryImage?.assetId,
                imageHeight: temporaryImage?.height,
                imageWidth: temporaryImage?.width,
                thumbnailAssetId: temporaryImage?.signedUrl,
              },
              segmentPropertys: (temporarySegmentProperties ?? []).map(
                (item) => ({ ...item.segmentProperty })
              ),
            },
          }}
          onDecision={handleSaveSegment}
          onCancel={() => setOpenSegmentConfirmation(false)}
        />
      </>
      <Toast />
    </Container>
  );
};

/** セグメント名に対応するセグメントがあるかどうか */
const hasSegment = (
  segments:
    | mtechnavi.api.blueprint.ITemporarySegmentProperty[]
    | null
    | undefined,
  segmentName: BlueprintSegmentNames
): boolean => {
  return (segments ?? []).some(
    (item) => item.segmentProperty?.segmentName === segmentName
  );
};
