import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import { CaptionButton, IconButton } from '../Button';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { DateYM } from '../Dates';
import { error } from '~/shared/components/parts/Toast/Toast';
import { Amountbox } from '../Amountbox';
import {
  Amounts,
  BASE_DIGIT_THOUSAND,
  convertBaseDigitOnceTo,
  convertBaseDigitToOnce,
  convertDate,
  convertDatetime,
  convertMonetaryAmount,
  formatDate,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import './FinancialRegistrationDialog.css';
import { EditPeriodDialog } from './EditPeriodDialog';
import { Checkbox } from '../Checkbox';

export interface FinancialRegistrationProps {
  onCancel?: () => void;
  onDecision?: (v: mtechnavi.api.company.IBusinessUnitFinancial) => void;
}

export interface FinancialRegistrationDialogRef {
  open: (value: mtechnavi.api.company.IBusinessUnitFinancial) => void;
}
export const FinancialRegistrationDialog = forwardRef(
  (props: FinancialRegistrationProps, ref) => {
    const intl = useIntl();

    const [periodStartDt, setPeriodStartDt] = useState<Date | null>(new Date());
    const [periodEndDt, setPeriodEndDt] = useState<Date | null>(new Date());
    const [netSales, setNetSales] = useState<Amounts>();
    const [operatingProfit, setOperatingProfit] = useState<Amounts>();
    const [ordinaryIncome, setOrdinaryIncome] = useState<Amounts>();
    const [isOpen, setOpen] = useState(false);
    const [isEditPeriodDialogOpen, setEditPeriodDialogOpen] = useState(false);
    const [nonDisclosure, setNonDisclosure] = useState(false);
    const initialPeriodEndDt = () => {
      const nowDate = new Date();
      // 決算終了期間は現在年の3月にする
      nowDate.setMonth(2);
      return nowDate;
    };

    useImperativeHandle(
      ref,
      (): FinancialRegistrationDialogRef => ({
        open: (value: mtechnavi.api.company.IBusinessUnitFinancial) => {
          setPeriodStartDt(convertDate(value.periodStartDt ?? null));
          setPeriodEndDt(
            convertDate(value.periodEndDt ?? null) ?? initialPeriodEndDt()
          );
          setNetSales(
            convertBaseDigitOnceTo(value?.netSales, BASE_DIGIT_THOUSAND)
          );
          setOperatingProfit(
            convertBaseDigitOnceTo(value?.operatingProfit, BASE_DIGIT_THOUSAND)
          );
          setOrdinaryIncome(
            convertBaseDigitOnceTo(value?.ordinaryIncome, BASE_DIGIT_THOUSAND)
          );
          setNonDisclosure(value?.nondisclosure ?? false);
          setOpen(true);
        },
      }),
      []
    );

    const handleCancel = () => {
      setOpen(false);
      formReset();
      props.onCancel && props.onCancel();
    };

    const handleDecision = () => {
      if (isValidationError()) {
        return;
      }
      const result: mtechnavi.api.company.IBusinessUnitFinancial = {
        periodStartDt: convertDatetime(periodStartDt, 'YYYY/MM'),
        periodEndDt: convertDatetime(periodEndDt, 'YYYY/MM'),
        netSales: convertBaseDigitToOnce(
          convertMonetaryAmount(netSales),
          BASE_DIGIT_THOUSAND
        ),
        operatingProfit: convertBaseDigitToOnce(
          convertMonetaryAmount(operatingProfit),
          BASE_DIGIT_THOUSAND
        ),
        ordinaryIncome: convertBaseDigitToOnce(
          convertMonetaryAmount(ordinaryIncome),
          BASE_DIGIT_THOUSAND
        ),
        nondisclosure: nonDisclosure,
      };
      setOpen(false);
      formReset();
      props.onDecision && props.onDecision(result);
    };

    const formReset = () => {
      setPeriodStartDt(null);
      setPeriodEndDt(null);
      setNetSales(null);
      setOperatingProfit(null);
      setOrdinaryIncome(null);
      setNonDisclosure(false);
    };

    const isValidationError = () => {
      if (!periodStartDt || !periodEndDt) {
        error([GetMessageWithIntl(intl, { id: 'E0000003' })]);
        return true;
      }
      if (periodStartDt.getTime() >= periodEndDt.getTime()) {
        error([GetMessageWithIntl(intl, { id: 'E0000105' })]);
        return true;
      }
      if (
        !nonDisclosure &&
        (!netSales || !operatingProfit || !ordinaryIncome)
      ) {
        error([GetMessageWithIntl(intl, { id: 'E0000003' })]);
        return true;
      }
    };

    const setValue = (v: Date) => {
      setPeriodEndDt(v);
      const periodStart = new Date(v);
      periodStart.setMonth(periodStart.getMonth() - 11);
      setPeriodStartDt(periodStart);
    };

    const elements = (
      <div className="financial-registration-dialog">
        <div className="detail-area">
          <div className="contents-box">
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  <DateYM
                    name="fiscalYear"
                    term={20}
                    validateOption={{ required: true }}
                    onChangeState={(v) => {
                      if (!v) {
                        return;
                      }
                      if (v.toDateString() === periodEndDt?.toDateString()) {
                        return;
                      }
                      setValue(v);
                    }}
                    unselectedOption={{ isUnselected: true }}
                    columns={['fiscalYear']}
                    labelId="FinancialRegistrationDialog.fiscalYear"
                    value={periodEndDt ?? undefined}
                  ></DateYM>
                </div>
                <div className="w-66 period-area">
                  <span>
                    {GetMessageWithIntl(intl, {
                      id: 'FinancialRegistrationDialog.period',
                    })}
                    ：
                  </span>
                  <span>
                    {formatDate(periodStartDt, 'YYYY/MM')}-
                    {formatDate(periodEndDt, 'YYYY/MM')}
                  </span>
                  <IconButton
                    name={'edit'}
                    buttonType="basic"
                    iconType={'edit'}
                    onClick={() => {
                      setEditPeriodDialogOpen(true);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  <Amountbox
                    name="netSales"
                    className=""
                    labelId="FinancialRegistrationDialog.netSales"
                    value={netSales}
                    columns={['netSales']}
                    displayOption={{ isCommaFormat: true }}
                    validateOption={{ required: !nonDisclosure }}
                    onChangeState={setNetSales}
                  />
                </div>
                <div className="w-33">
                  <Amountbox
                    name="operatingProfit"
                    className=""
                    labelId="FinancialRegistrationDialog.operatingProfit"
                    value={operatingProfit}
                    columns={['operatingProfit']}
                    displayOption={{ isCommaFormat: true }}
                    validateOption={{ required: !nonDisclosure }}
                    onChangeState={setOperatingProfit}
                  />
                </div>
                <div className="w-33">
                  <Amountbox
                    name="ordinaryIncome"
                    className=""
                    labelId="FinancialRegistrationDialog.ordinaryIncome"
                    value={ordinaryIncome}
                    columns={['ordinaryIncome']}
                    displayOption={{ isCommaFormat: true }}
                    validateOption={{ required: !nonDisclosure }}
                    onChangeState={setOrdinaryIncome}
                  />
                </div>
              </div>
            </div>
            <div className="input-line non-closure">
              <div className="item-group-100">
                <div className="w-33">
                  <Checkbox
                    name="disclosure"
                    items={[
                      {
                        value: '1',
                        displayName: GetMessageWithIntl(intl, {
                          prefixId: 'FinancialRegistrationDialog',
                          id: 'nonDisclosure',
                        }),
                      },
                    ]}
                    columns={['disclosure']}
                    value={nonDisclosure ? ['1'] : []}
                    onChangeState={(v) => {
                      if (v.length > 0 && v[0] === '1') {
                        setNonDisclosure(true);
                      } else {
                        setNonDisclosure(false);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="button-area">
            <CaptionButton
              name="cancelBtn"
              buttonType="cancel"
              className="button"
              caption={GetMessageWithIntl(intl, {
                id: 'cancel',
              })}
              onClick={() => handleCancel()}
            />
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, {
                id: 'decision',
              })}
              onClick={handleDecision}
            />
          </div>
        </div>
        <EditPeriodDialog
          period={{
            periodStartDt,
            periodEndDt,
          }}
          isOpen={isEditPeriodDialogOpen}
          onCancel={() => {
            setEditPeriodDialogOpen(false);
          }}
          onDecision={(v) => {
            setPeriodStartDt(v.periodStartDt);
            setEditPeriodDialogOpen(false);
          }}
        />
      </div>
    );

    const openModalProps: ModalDialogComponentProps = {
      cancel: () => {
        handleCancel();
      },
      send: () => {},
      modalIsOpen: isOpen,
      headerLabelId: {
        id: 'FINANCIAL_REGISTRATION',
        prefixId: 'DIALOG_TITLE',
      },
      messageLabelId: {},
      elements,
    };
    return <ModalDialogComponent {...openModalProps} />;
  }
);
