import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { FilterRequest, FullMethodName_ListBlueprintRevisions } from '~/worker';
import { SectionStateProps } from '../ui/TaskListView';

import './base.css';
import { useIntl } from 'react-intl';
import {
  PageState,
  movePage,
  FullMethodName_UnreadListResources,
} from '~/shared/utils';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import {
  SURVEY_REQUEST_SENDER_CONFIRMATION_URL,
  SURVEY_REQUEST_RECEIVER_CONFIRMATION_URL,
  WORKTASK_CONFIRMATION_URL,
  ESTIMATE_REQUEST_CONFIRMATION_URL,
  ESTIMATE_RESULT_CONFIRMATION_URL,
  BLUEPRINT_CONFIRMATION_URL,
} from './utils';

type TargetResourceType =
  | 'mtechnavi.api.estimation.EstimateRequest'
  | 'mtechnavi.api.survey.SurveyRequest'
  | 'mtechnavi.api.worktask.WorkTask'
  | 'mtechnavi.api.blueprint.BlueprintRevision';

type ReferenceMode = 'all' | 'owner' | 'unowner';

// 未読の一覧を取得する
async function unreadListResources(
  typeName: TargetResourceType[],
  referenceMode: ReferenceMode | null
): Promise<mtechnavi.api.forum.UnreadListResourcesResponse> {
  const option: FilterRequest = {
    action: 'reload',
    fullMethodName: FullMethodName_UnreadListResources,
    filter: {},
    sort: [],
  };
  option.requestBody = {
    typeName: typeName,
    referenceMode: referenceMode,
  };

  try {
    return (await window.App.services.ui.worker.filter(
      option
    )) as mtechnavi.api.forum.UnreadListResourcesResponse;
  } catch (err) {
    console.error('Error unreadListResources', err);
    return (async () =>
      new mtechnavi.api.forum.UnreadListResourcesResponse())();
  }
}

export interface TaskMessageResourcesProps extends SectionStateProps {}

export const TaskMessageResources = (props: TaskMessageResourcesProps) => {
  const intl = useIntl();
  const { onChangeLoadingState } = props;

  const [targetSurveyRequestIds, setTargetSurveyRequestIds] = useState<
    string[]
  >([]);
  const [targetSurveyReceptionIds, setTargetSurveyReceptionIds] = useState<
    string[]
  >([]);
  const [targetWorkTaskIds, setTargetWorkTaskIds] = useState<string[]>([]);
  const [targetEstimateRequestIds, setTargetEstimateRequestIds] = useState<
    string[]
  >([]);
  const [targetEstimateResultIds, setTargetEstimateResultIds] = useState<
    string[]
  >([]);
  const [targetBlueprintRevisionIds, setTargetBlueprintRevisionIds] = useState<
    string[]
  >([]);

  const navi = useNavigate();
  // 遷移処理
  const handleMovePage = (
    e: React.MouseEvent,
    ids: string[],
    url: string,
    subIds?: string[]
  ) => {
    e.preventDefault();

    if (!ids.length) return;
    const state: PageState = {
      sourceViewId: 'TASK_LIST',
      ids: ids,
      subIds: subIds || [],
    };
    movePage(state, navi, url);
  };

  // 依頼送信（未読）
  const callSurveyRequestOwner = async () => {
    const { items } = await unreadListResources(
      ['mtechnavi.api.survey.SurveyRequest'],
      'owner'
    );
    if (!items.length) {
      setTargetSurveyRequestIds([]);
      return;
    }
    const resourceIds: string[] = [];
    items.map((v) => resourceIds.push(v.recordId ?? ''));
    setTargetSurveyRequestIds(resourceIds);
  };

  // 依頼受信（未読）
  const callSurveyRequestUnowner = async () => {
    const { items } = await unreadListResources(
      ['mtechnavi.api.survey.SurveyRequest'],
      'unowner'
    );
    if (!items.length) {
      setTargetSurveyReceptionIds([]);
      return;
    }
    const resourceIds: string[] = [];
    items.map((v) => resourceIds.push(v.recordId ?? ''));
    setTargetSurveyReceptionIds(resourceIds);
  };

  // 指図案件（未読）
  const callWorkTaskAll = async () => {
    const { items } = await unreadListResources(
      ['mtechnavi.api.worktask.WorkTask'],
      'all'
    );
    if (!items.length) {
      setTargetWorkTaskIds([]);
      return;
    }
    const resourceIds = items.map((v) => v.recordId ?? '');
    setTargetWorkTaskIds(resourceIds);
  };

  // 見積依頼・依頼側（未読）
  const callEstimateRequest = async () => {
    const { items } = await unreadListResources(
      ['mtechnavi.api.estimation.EstimateRequest'],
      'owner'
    );
    if (!items.length) {
      setTargetEstimateRequestIds([]);
      return;
    }
    const resourceIds: string[] = [];
    items.map((v) => resourceIds.push(v.recordId ?? ''));
    setTargetEstimateRequestIds(resourceIds);
  };

  // 見積依頼・回答側（未読）
  const callEstimateResult = async () => {
    const { items } = await unreadListResources(
      ['mtechnavi.api.estimation.EstimateRequest'],
      'unowner'
    );
    if (!items.length) {
      setTargetEstimateResultIds([]);
      return;
    }
    const resourceIds: string[] = [];
    items.map((v) => resourceIds.push(v.recordId ?? ''));
    setTargetEstimateResultIds(resourceIds);
  };

  // 図面確認（未読）
  const callBlueprintConfirmation = async () => {
    const { items } = await unreadListResources(
      ['mtechnavi.api.blueprint.BlueprintRevision'],
      'owner'
    );
    if (!items.length) {
      setTargetBlueprintRevisionIds([]);
      return;
    }
    const resourceIds: string[] = [];
    items.map((v) => resourceIds.push(v.recordId ?? ''));
    setTargetBlueprintRevisionIds(resourceIds);
  };

  useEffect(() => {
    onChangeLoadingState && onChangeLoadingState(true);
    Promise.all([
      callSurveyRequestOwner(), // 依頼送信（未読）
      callSurveyRequestUnowner(), // 依頼受信（未読）
      callWorkTaskAll(), // 指図案件（未読）
      callEstimateRequest(), // 見積依頼・依頼側（未読）
      callEstimateResult(), // 見積依頼・回答側（未読）
      callBlueprintConfirmation(), // 図面確認（未読）
    ]).finally(() => {
      onChangeLoadingState && onChangeLoadingState(false);
    });
    // 正確にローディング状況を伝えることが困難なため、依存関係に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isShowMessageArea =
    targetSurveyReceptionIds.length > 0 ||
    targetSurveyRequestIds.length > 0 ||
    targetWorkTaskIds.length > 0 ||
    targetEstimateRequestIds.length > 0 ||
    targetEstimateResultIds.length > 0 ||
    targetBlueprintRevisionIds.length > 0;

  return (
    <>
      {isShowMessageArea && (
        <div className="TaskList">
          <dl>
            <dt className="title">
              {GetMessageWithIntl(intl, { id: 'TaskList.title.unreadMessage' })}
            </dt>
            {targetSurveyReceptionIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetSurveyReceptionIds,
                      SURVEY_REQUEST_RECEIVER_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.unreadSurveyReception',
                  })}
                  {`（${targetSurveyReceptionIds.length}）`}
                </a>
              </dd>
            )}
            {targetSurveyRequestIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetSurveyRequestIds,
                      SURVEY_REQUEST_SENDER_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.unreadSurveyRequest',
                  })}
                  {`（${targetSurveyRequestIds.length}）`}
                </a>
              </dd>
            )}
            {targetWorkTaskIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetWorkTaskIds,
                      WORKTASK_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.unreadTaskList',
                  })}
                  {`（${targetWorkTaskIds.length}）`}
                </a>
              </dd>
            )}
            {targetEstimateRequestIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetEstimateRequestIds,
                      ESTIMATE_REQUEST_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.unreadEstimateRequest',
                  })}
                  {`（${targetEstimateRequestIds.length}）`}
                </a>
              </dd>
            )}
            {targetEstimateResultIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetEstimateResultIds,
                      ESTIMATE_RESULT_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.unreadEstimateResult',
                  })}
                  {`（${targetEstimateResultIds.length}）`}
                </a>
              </dd>
            )}
            {targetBlueprintRevisionIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetBlueprintRevisionIds,
                      BLUEPRINT_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.unreadBlueprintConfirmation',
                  })}
                  {`（${targetBlueprintRevisionIds.length}）`}
                </a>
              </dd>
            )}
          </dl>
        </div>
      )}
    </>
  );
};
