import { useIntl } from 'react-intl';
import {
  error,
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import { CaptionButton } from '../Button/CaptionButton';
import { useEffect, useRef, useState, useCallback } from 'react';
import {
  getProgramOptionFilterboxData,
  getProgramOptionFilterboxDataWithSystemName,
  getWorkerExceptionMessage,
  includeInputValidateError,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { DataFilterbox, DataFilterboxItem } from '../Filterbox';
import { Textbox } from '../Textbox';
import './ResponsiblePersonRegistrationDialog.css';

export type DISPLAY_TYPE = 'display' | 'edit' | 'copy';
export interface ResponsiblePersonRegistrationDialogInputOption {
  displayType: DISPLAY_TYPE;
  rankContent: mtechnavi.api.company.IBusinessUnitRankContent;
  sharedCompanyId: string; // 取引先会社ID
  isLinkedTenant: boolean; // テナント紐付け有無
}

const getCountrySystemName = (countryCode: string) => {
  return (
    window.App.services.ui.getNameOptionWithCode('A0000041', countryCode)
      .systemName ?? ''
  );
};

export interface ResponsiblePersonRegistrationDialogPorps {
  isOpen: boolean;
  inputOption: ResponsiblePersonRegistrationDialogInputOption;
  onChangeLoadingState?: (isLoading: boolean) => void;
  onDecision: (
    result: mtechnavi.api.company.IBusinessUnitRankReception
  ) => void;
  onCancel: () => void;
}

export const ResponsiblePersonRegistrationDialog = ({
  isOpen,
  inputOption,
  onChangeLoadingState,
  onDecision,
  onCancel,
}: ResponsiblePersonRegistrationDialogPorps) => {
  const businessUnitRankRequest =
    inputOption.rankContent?.businessUnitRankRequest;
  const businessUnitRankReception =
    inputOption.rankContent?.businessUnitRankReception;
  const intl = useIntl();
  const [countries, setCountries] = useState<DataFilterboxItem[]>([]);
  const [mainSubs, setMainSubs] = useState<DataFilterboxItem[]>([]);
  const [regions, setRegions] = useState<DataFilterboxItem[]>([]);
  const [isDisabledAllItem, setDisabledAllItem] = useState(false);
  const [applyKind, setApplyKind] = useState<string>('');
  const [rank, setRank] = useState<string>('');
  const [companyDisplayName, setCompanyDisplayName] = useState<string>('');
  const [factoryName, setFactoryName] = useState('');
  const [country, setCountry] = useState<DataFilterboxItem[]>([]);
  const [postCode, setPostCode] = useState('');
  const [region, setRegion] = useState<DataFilterboxItem[]>([]);
  const [addressLine, setAddressLine] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [faxNumber, setFaxNumber] = useState('');
  const [mainSubCategory, setMainSubCategory] = useState<DataFilterboxItem[]>(
    []
  );
  const [qualityManager, setQualityManager] = useState('');
  const [managerPosition, setManagerPosition] = useState('');
  const [managerEmail, setManagerEmail] = useState('');
  const [comment, setComment] = useState('');

  //入力チェック
  const requiredStaffNameArea = useRef(null);

  // 初回ダイアログオープン時にuseEffectが走らないようにする制御
  const isInitialLoading = useRef<boolean>(true);

  const onParentLoadingState = useCallback(
    (arg: boolean) => {
      onChangeLoadingState && onChangeLoadingState(arg);
    },
    [onChangeLoadingState]
  );

  // isOpen === falseの時にフォームリセットを行う
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  // 初期情報の取得、セット
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    try {
      (async () => {
        onParentLoadingState(true);
        isInitialLoading.current = true;
        setDisabledAllItem(
          inputOption.displayType === 'display' ? true : false
        );
        const countriesFilterBoxData =
          getProgramOptionFilterboxData('A0000041');
        setCountries(countriesFilterBoxData);
        setMainSubs(getProgramOptionFilterboxData('A4000012'));
        const regionsFilterBoxData =
          getProgramOptionFilterboxDataWithSystemName(
            'A0000042',
            getCountrySystemName(
              businessUnitRankReception?.businessUnitBranchProperties?.address
                ?.country?.code || ''
            )
          );
        setRegions(regionsFilterBoxData);

        // 画面アイテムセット
        // 製造メーカー（加工サイト）
        setCompanyDisplayName(businessUnitRankRequest?.displayName ?? '');
        // ランク
        setRank(businessUnitRankRequest?.rank?.code ?? '');
        // 適用品種
        setApplyKind(businessUnitRankReception?.applyKind ?? '');

        // 入力値を事前に変数に格納する
        const factoryName =
          businessUnitRankReception?.businessUnitBranchProperties
            ?.displayName ?? '';
        // 住所情報
        const address =
          businessUnitRankReception?.businessUnitBranchProperties?.address;
        // 電話番号
        const phoneNumber =
          businessUnitRankReception?.businessUnitBranchProperties
            ?.phoneNumber ?? '';
        // FAX番号
        const faxNumber =
          businessUnitRankReception?.businessUnitBranchProperties?.faxNumber ??
          '';
        setFactoryName(factoryName);
        // 国
        setCountry([
          {
            value: address?.country?.code ?? '',
            displayName: address?.country?.displayNameLang?.ja ?? '',
          },
        ]);
        // 都道府県（地域）
        setRegion([
          {
            value: address?.region?.code ?? '',
            displayName: address?.region?.displayNameLang?.ja ?? '',
          },
        ]);
        // 郵便番号
        setPostCode(address?.postalCode ?? '');
        // 市区町村以下
        const addressLines = address?.addressLines ?? [];
        setAddressLine(addressLines.length > 0 ? addressLines[0] : '');
        // 建物名、号室
        setPropertyName(addressLines.length > 1 ? addressLines[1] : '');
        // 電話番号
        setPhoneNumber(phoneNumber);
        // ファックス番号
        setFaxNumber(faxNumber);
        // 拠点情報なしの場合
        // 工場名
        setFactoryName(factoryName);
        // 入力モードの場合、保存された情報をセット
        if (inputOption.displayType !== 'display') {
          const selectedCountryCode = address?.country?.code;
          const selectedCountry = selectedCountryCode
            ? countriesFilterBoxData.filter(
                (c) => c.value === selectedCountryCode
              )
            : [];
          setCountry(selectedCountry);
          const selectedRegionCode = address?.region?.code;
          const selectedRegion = selectedRegionCode
            ? regionsFilterBoxData.filter((p) => p.value === selectedRegionCode)
            : [];
          setRegion(selectedRegion);
        } else {
          // 国
          setCountry([
            {
              value: address?.country?.code ?? '',
              displayName: address?.country?.displayNameLang?.ja ?? '',
            },
          ]);
          // 都道府県（地域）
          setRegion([
            {
              value: address?.region?.code ?? '',
              displayName: address?.region?.displayNameLang?.ja ?? '',
            },
          ]);
        }
        // 複写の時は空欄
        if (inputOption?.displayType !== 'copy') {
          // 品質管理者名
          setQualityManager(
            businessUnitRankReception?.qualityManager?.displayName ?? ''
          );
          // 正/副
          setMainSubCategory([
            {
              value: businessUnitRankReception?.category?.code ?? '',
              displayName:
                businessUnitRankReception?.category?.displayNameLang?.ja ?? '',
            },
          ]);
          // 役職
          setManagerPosition(
            businessUnitRankReception?.qualityManager?.position ?? ''
          );
          // メールアドレス
          setManagerEmail(
            businessUnitRankReception?.qualityManager?.email ?? ''
          );
          // コメント
          setComment(businessUnitRankReception?.comment ?? '');
        }
      })().then(() => {
        onParentLoadingState(false);
      });
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    }
    // 起動時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOption, intl]);

  // 国が変更された時、国に一致する地域のみ表示する
  const resetRegions = (countryValue: DataFilterboxItem[]) => {
    const systemName = getCountrySystemName(
      countryValue.length ? countryValue[0].value : ''
    );
    setRegions(
      getProgramOptionFilterboxDataWithSystemName('A0000042', systemName)
    );
    setRegion([]);
  };

  const isInputError = (): boolean => {
    const targetElm = document.querySelector(
      '.responsible-person-registration-dialog'
    );
    return includeInputValidateError(targetElm, intl, [
      {
        value: qualityManager ?? '',
        ref: requiredStaffNameArea,
      },
    ]);
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }

    const address: mtechnavi.api.company.IAddress = {};
    address.postalCode = postCode;
    address.country = window.App.services.ui.getNameOptionWithCode(
      'A0000041',
      country.length > 0 ? country[0].value : ''
    );
    address.region = window.App.services.ui.getNameOptionWithCode(
      'A0000042',
      region.length > 0 ? region[0].value : ''
    );
    address.addressLines = [addressLine, propertyName];

    const result: mtechnavi.api.company.IBusinessUnitRankReception = {
      businessUnitRankReceptionId:
        inputOption.displayType === 'edit'
          ? businessUnitRankReception?.businessUnitRankReceptionId ?? null
          : null,
      businessUnitRankRequestId:
        businessUnitRankReception?.businessUnitRankRequestId ??
        businessUnitRankRequest?.businessUnitRankRequestId,
      applyKind: applyKind,
      businessUnitBranchProperties: {
        businessUnitBranchId: null,
        displayName: factoryName,
        address,
        phoneNumber,
        faxNumber,
      },
      category:
        mainSubCategory && mainSubCategory.length > 0
          ? window.App.services.ui.getNameOptionWithCode(
              'A4000012',
              mainSubCategory.length > 0 ? mainSubCategory[0].value : ''
            )
          : null,
      qualityManager: {
        displayName: qualityManager,
        position: managerPosition,
        email: managerEmail,
      },
      comment: comment,
      createdAt:
        inputOption.displayType === 'edit'
          ? businessUnitRankReception?.createdAt
          : null,
      updatedAt:
        inputOption.displayType === 'edit'
          ? businessUnitRankReception?.updatedAt
          : null,
    };
    onDecision(result);
  };

  const handleCancel = () => {
    onCancel();
  };

  const resetForm = () => {
    setCompanyDisplayName('');
    setRank('');
    setApplyKind('');
    setFactoryName('');
    setCountry([]);
    setRegion([]);
    setPostCode('');
    setAddressLine('');
    setPropertyName('');
    setPhoneNumber('');
    setFaxNumber('');
    setMainSubCategory([]);
    setQualityManager('');
    setManagerPosition('');
    setManagerEmail('');
    setComment('');
  };

  const elements = (
    <div className="responsible-person-registration-dialog">
      <div className="detail-area">
        <div className="contents-box">
          {/* コンテンツ */}
          <div className="input-line">
            <div className="item-group-100">
              {/* 品質管理企業名 */}
              <div className="w-66">
                <Textbox
                  name="companyDisplayName"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.companyDisplayName"
                  columns={['companyDisplayName']}
                  value={companyDisplayName}
                  validateOption={{ required: true }}
                  disabled={true}
                />
              </div>
              {/* ランク */}
              <div className="w-33">
                <Textbox
                  name="rank"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.rank"
                  columns={['rank']}
                  value={rank}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                {/* 適用品種 */}
                <Textbox
                  name="applyKind"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.applyKind"
                  columns={['applyKind']}
                  value={applyKind}
                  disabled={isDisabledAllItem}
                  onChangeState={setApplyKind}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 正/副 */}
              <div className="w-33">
                <DataFilterbox
                  data={mainSubs}
                  labelId="ResponsiblePersonRegistrationDialog.mainSubCategory"
                  searchOption={{ targets: 'displayName' }}
                  name="mainSubCategory"
                  columns={['mainSubCategory']}
                  onChangeState={(v) => {
                    setMainSubCategory(v);
                  }}
                  value={mainSubCategory}
                  disabled={isDisabledAllItem}
                ></DataFilterbox>
              </div>
              {/* 品質管理者名 */}
              <div className="w-33">
                <Textbox
                  name="qualityManager"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.qualityManager"
                  value={qualityManager}
                  onChangeState={setQualityManager}
                  columns={['qualityManager']}
                  validateOption={{ required: true }}
                  disabled={isDisabledAllItem}
                />
              </div>
              {/* 役職 */}
              <div className="w-33">
                <Textbox
                  name="managerPosition"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.managerPosition"
                  value={managerPosition}
                  onChangeState={setManagerPosition}
                  columns={['managerPosition']}
                  disabled={isDisabledAllItem}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* E-mail */}
              <div className="w-66">
                <Textbox
                  name="managerEmail"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.managerEmail"
                  value={managerEmail}
                  onChangeState={setManagerEmail}
                  columns={['managerEmail']}
                  disabled={isDisabledAllItem}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 工場名 */}
              <div className="w-66">
                <Textbox
                  name="factoryName"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.factoryName"
                  columns={['factoryName']}
                  value={factoryName}
                  onChangeState={setFactoryName}
                  disabled={isDisabledAllItem}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 所在国 */}
              <div className="w-33">
                <DataFilterbox
                  data={countries}
                  labelId="ResponsiblePersonRegistrationDialog.country"
                  searchOption={{ targets: 'displayName' }}
                  name="country"
                  columns={['country']}
                  onChangeState={(v) => {
                    setCountry(v);
                    resetRegions(v);
                  }}
                  value={country}
                  disabled={isDisabledAllItem}
                ></DataFilterbox>
              </div>
              {/* 郵便番号 */}
              <div className="w-33">
                <Textbox
                  name="postCode"
                  type="text"
                  value={postCode}
                  labelId="ResponsiblePersonRegistrationDialog.postCode"
                  columns={['postCode']}
                  onChangeState={setPostCode}
                  disabled={isDisabledAllItem}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 都道府県名 */}
              <div className="w-33">
                <DataFilterbox
                  data={regions}
                  labelId="ResponsiblePersonRegistrationDialog.region"
                  searchOption={{ targets: 'displayName' }}
                  name="region"
                  columns={['region']}
                  onChangeState={(v) => {
                    setRegion(v);
                  }}
                  value={region}
                  disabled={isDisabledAllItem}
                ></DataFilterbox>
              </div>
              {/* 市区町村以下 */}
              <div className="w-66">
                <Textbox
                  name="addressLine"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.addressLine"
                  columns={['addressLine']}
                  value={addressLine}
                  onChangeState={setAddressLine}
                  disabled={isDisabledAllItem}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 建物名、号室 */}
              <div className="w-66">
                <Textbox
                  name="propertyName"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.propertyName"
                  columns={['propertyName']}
                  value={propertyName}
                  onChangeState={setPropertyName}
                  disabled={isDisabledAllItem}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* 電話番号 */}
              <div className="w-33">
                <Textbox
                  name="phoneNumber"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.phoneNumber"
                  value={phoneNumber}
                  onChangeState={setPhoneNumber}
                  columns={['phoneNumber']}
                  disabled={isDisabledAllItem}
                />
              </div>
              {/* FAX番号 */}
              <div className="w-33">
                <Textbox
                  name="faxNumber"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.faxNumber"
                  value={faxNumber}
                  onChangeState={setFaxNumber}
                  columns={['faxNumber']}
                  disabled={isDisabledAllItem}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              {/* コメント */}
              <div className="w-100">
                <Textbox
                  name="comment"
                  type="text"
                  labelId="ResponsiblePersonRegistrationDialog.comment"
                  columns={['comment']}
                  value={comment}
                  onChangeState={setComment}
                  validateOption={{ maxLength: 100 }}
                  disabled={isDisabledAllItem}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, {
              id: 'cancel',
            })}
            onClick={() => handleCancel()}
          />
          {inputOption.displayType !== 'display' && (
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, {
                id: 'save',
              })}
              onClick={() => handleDecision()}
            />
          )}
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      id:
        inputOption.displayType === 'display'
          ? 'responsiblePersonConfirmation'
          : 'responsiblePersonRegistration',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="ResponsiblePersonRegistrationDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
