import { Container, Toast } from '~/shared/components';
import { ViewId } from '~/shared/utils';
import { BlueprintSearchResultHeader } from './parts/BlueprintSearchResultHeader';
import { BlueprintSearchResultList } from './parts/BlueprintSearchResultList';
import './index.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useBlueprintSearch } from '../BlueprintSearchProvider';
import {
  BlueprintSearchFilter,
  BlueprintSearchSort,
  getBlueprintSearchState,
} from './util';
import { BlueprintSearchResultItemSize } from './parts/BlueprintSearchResultItem';

const viewId: ViewId = 'BPR_BLUEPRINT_SEARCH_LIST';
const searchViewId: ViewId = 'BPR_BLUEPRINT_SEARCH';
const PAGE_UNIT = 10; // TODO: ページングサイズを検討する
export const BlueprintBlueprintSearchResult = () => {
  const myEmail = useAuth().user?.email ?? '';
  const [showCount, setShowCount] = useState(PAGE_UNIT);

  // 検索画面と連携した条件と結果
  const { condition, result, isProcessing, search } = useBlueprintSearch();

  const [filter, setFilter] = useState<BlueprintSearchFilter>();
  const [sort, setSort] = useState<BlueprintSearchSort>();
  const [size, setSize] = useState<BlueprintSearchResultItemSize>('large');

  const list = useMemo(
    () => (result ?? []).filter((item, i) => i < showCount),
    [result, showCount]
  );

  const handleReadNext = async () => {
    setShowCount((prev) => prev + PAGE_UNIT);
  };

  /** フィルタ適用 */
  const handleApplyFilter = (filter?: BlueprintSearchFilter) => {
    setFilter(filter);
    setShowCount(PAGE_UNIT);
    search({ condition, filter, sort });
  };

  /** 並び順適用 */
  const handleApplySort = (sort?: BlueprintSearchSort) => {
    setSort(sort);
    setShowCount(PAGE_UNIT);
    search({ condition, filter, sort });
  };

  // ウィンドウリロードされた場合に LocalStorage から条件を復元して検索実行する
  useEffect(() => {
    if (condition) {
      return;
    }
    const savedCondition = getBlueprintSearchState(searchViewId, myEmail);
    if (!savedCondition) {
      return;
    }
    search({ condition: savedCondition });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, myEmail]);

  // リスト部分の高さ計算
  const headerRef = useRef<HTMLElement>(null);
  const [listHeight, setListHeight] = useState<number | string>(0);
  const resizeListArea = () => {
    if (!headerRef.current) {
      return;
    }
    const height = `calc(${
      window.innerHeight - headerRef.current.offsetHeight
    }px - 4rem)`;
    setListHeight(height);
  };
  useEffect(() => {
    const resize = () => {
      resizeListArea();
    };
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <Container viewId={viewId}>
      <div className="BlueprintBlueprintSearchResult">
        <BlueprintSearchResultHeader
          viewId={viewId}
          searchViewId={searchViewId}
          ref={headerRef}
          resultCount={!isProcessing ? result.length : null}
          condition={condition}
          size={size}
          onResize={() => setTimeout(() => resizeListArea(), 0)}
          onApplyFilter={handleApplyFilter}
          onApplySort={handleApplySort}
          onChangeListSize={setSize}
        />
        {!isProcessing && (
          <BlueprintSearchResultList
            viewId={viewId}
            data={list}
            height={listHeight}
            size={size}
            hasNextData={list.length !== result.length}
            onReadNext={handleReadNext}
          />
        )}
      </div>
      <Toast />
    </Container>
  );
};
